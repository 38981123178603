import { VideoPlayer, VideoPlayerOptions } from './Video';
import { VideoBase } from './VideoBase';
import ObjectUtils from '../../utils/ObjectUtils/ObjectUtils';
import { BreakableRefCallback } from '../../components/Video/BreakableRefCallback';

// -------------------------------------------------------------
//  Note:  Unlike our other video players, the Twitch iframe
//  will not load locally (ie. local.sprinklr.com)
//  no matter what I tried for the "parent" argument.
//
//  It only works is if it has a real domain like
//  display-dev.sprinklr.com meaning it has to be deployed
//  somewhere to see it in action.
// -------------------------------------------------------------
export class VideoTwitch extends VideoBase implements VideoPlayer {
    private static loading: Promise<any>;
    private stopped = false;
    private breakableOnPlayerReady: any;
    private breakableOnPlayerPlaying: any;
    private breakableOnPlayerEnded: any;

    constructor(muted = false) {
        super(muted);
    }

    start(container: any, options: VideoPlayerOptions, callbackStopped: () => boolean) {
        const url = this.getUrl(options.url);

        this.id = ObjectUtils.hashCode(url);
        this.container = container;
        this.options = options;

        const onPlayerReady = event => {
            if (this.player) {
                if (this.muted && this.player.setMuted) {
                    this.player.setMuted(true);
                }

                if (this.lastTime[this.id] && this.player.seek) {
                    this.player.seek(this.lastTime[this.id]);
                }
            }
        };

        const onPlayerPlaying = event => {
            const Twitch = (window as any).Twitch;

            // Hide placeholder after video is playing so that we don't see the ugly loading spinner
            if (options.hideMarkerAfterPlay) {
                this.placeholder.style.display = 'none';
            }
        };

        const onPlayerEnded = event => {
            const Twitch = (window as any).Twitch;

            // Implement manual looping since the "loop=1" parameters does not work
            // https://developers.google.com/youtube/js_api_reference#getPlayerState
            if (!callbackStopped || callbackStopped()) {
                if (this.player && this.player.play) {
                    this.player.play();

                    if (this.muted && this.player.setMuted) {
                        this.player.setMuted(true);
                    }
                }
            }
        };

        this.breakableOnPlayerReady = new BreakableRefCallback(onPlayerReady);
        this.breakableOnPlayerPlaying = new BreakableRefCallback(onPlayerPlaying);
        this.breakableOnPlayerEnded = new BreakableRefCallback(onPlayerEnded);

        this.placeholder = this.findVideoPlaceholder(container);
        if (this.placeholder) {
            if (!options.hideMarkerAfterPlay) {
                this.placeholder.style.display = 'none';
            }

            this.loadPlayer().then(() => {
                const Twitch = (window as any).Twitch;

                const placeholder = this.placeholder;
                if (placeholder) {
                    const match = url.match(/www.twitch.tv\/videos\/([0-9]+)/);
                    if (match && match[1]) {
                        this.videoId = match[1];

                        // Hide our marker div
                        if (!options.hideMarkerAfterPlay) {
                            this.placeholder.style.display = 'none';
                        }

                        // Remove any old video created
                        this.destroyVideo();

                        const target = this.injectTargetDiv();
                        const dimensions = this.getDimensions();

                        this.stopped = false;

                        this.player = new Twitch.Embed(this.videoId, {
                            width: dimensions.width.toString(),
                            height: dimensions.height.toString(),
                            layout: 'video',
                            video: this.videoId,
                            autoplay: true,
                            time: '0h0m0s',
                            parent: [window.location.hostname],
                        });

                        this.player.addEventListener(
                            Twitch.Embed.VIDEO_READY,
                            this.breakableOnPlayerReady.invoke
                        );
                        this.player.addEventListener(
                            Twitch.Embed.PLAYING,
                            this.breakableOnPlayerPlaying.invoke
                        );
                        this.player.addEventListener(
                            Twitch.Embed.ENDED,
                            this.breakableOnPlayerEnded.invoke
                        );
                    }
                }
            });
        }
    }

    // Stop and destroy the video player instance
    stop(): void {
        const Twitch = (window as any).Twitch;

        // Show our marker div again
        if (this.placeholder) {
            this.placeholder.style.display = '';
        }

        if (this.player && !this.stopped) {
            this.stopped = true;

            // Save the time so we can seek to it on the next play
            if (
                this.options.playFromLastTime &&
                this.player.getPlayerState().playback === 'Playing'
            ) {
                this.lastTime[this.id] = this.player.getCurrentTime();
            } else {
                delete this.lastTime[this.id];
            }
        }

        if (this.player) {
            // always do cleanup regardless of "stopped" status
            // try manual listener cleanup first - before player.stop()
            if (this.player.removeEventListener) {
                this.player.removeEventListener(
                    Twitch.Embed.VIDEO_READY,
                    this.breakableOnPlayerReady.invoke
                );
                this.player.removeEventListener(
                    Twitch.Embed.PLAYING,
                    this.breakableOnPlayerPlaying.invoke
                );
                this.player.removeEventListener(
                    Twitch.Embed.ENDED,
                    this.breakableOnPlayerEnded.invoke
                );
            }

            // this shuts down the player, only to be used when we're done with it
            if (this.player.pause) {
                this.player.pause();
            }

            // break callback refs allowing things to be collected even if Twitch cleanup goes awry
            this.breakableOnPlayerReady.breakRef();
            this.breakableOnPlayerPlaying.breakRef();
            this.breakableOnPlayerEnded.breakRef();

            // cleans up the iframe, but maybe not event listeners? docs are not clear
            this.player.destroy();
            this.player = null;
        }
    }

    // Pause the video
    pause(): void {
        // Show our marker div
        if (this.placeholder) {
            this.placeholder.style.display = '';
        }

        if (this.player && this.player.pause) {
            this.player.pause();
        }
    }

    // Play the video
    play(): void {
        if (this.player && this.player.play) {
            this.player.play();

            if (this.muted && this.player.setMuted) {
                this.player.setMuted(true);
            }
        }

        // Hide our marker div
        if (this.placeholder) {
            this.placeholder.style.display = 'none';
        }
    }

    private getUrl(url: string): string {
        let result: string;

        if (url.indexOf('http://') === 0) {
            url = 'https://' + url.substring(7);
        }

        return url;
    }

    // Load the YouTube player API dynamically to cut down on initial overall app size
    private loadPlayer(): Promise<any> {
        if (VideoTwitch.loading) {
            return VideoTwitch.loading;
        }

        VideoTwitch.loading = new Promise<void>((resolve, reject) => {
            function load() {
                const tag = document.createElement('script');

                tag.src = 'https://embed.twitch.tv/embed/v1.js';
                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag?.parentNode.insertBefore(tag, firstScriptTag);
            }

            var interval = setInterval(() => {
                if ((window as any).Twitch) {
                    clearInterval(interval);
                    resolve();
                }
            }, 50);

            load();
        });

        return VideoTwitch.loading;
    }

    private injectTargetDiv(): Element {
        const dimensions = this.getDimensions();

        const div = document.createElement('div');

        // Give the new div the old element's ID
        div.setAttribute('id', this.videoId);

        this.placeholder.parentNode.insertBefore(div, this.placeholder);

        return div;
    }

    private destroyVideo() {
        let video: any = document.getElementById(this.videoId);
        if (video) {
            video.parentNode.removeChild(video);
            video = null;
        }
    }
}
