import ar from 'date-fns/locale/ar';

export const MOMENT_VS_DATEFNS_FORMAT = {
    'YYYY/M/D': 'yyyy/M/d',
    'YYYY/M/D, h:mma': 'yyyy/M/d, h:mmaaa',
    'Do MMM, YYYY h:mma': 'do MMM, yyyy h:mmaaa',
    'MMMM Do, YYYY h:mma': 'MMMM do, yyyy h:mmaaa',
    'MMMM Do, YYYY h:mma Z': 'MMMM do, yyyy h:mmaaa xxx',
    'Do MMM': 'do MMM',
    'Do MMM, YYYY': 'do MMM, yyyy',
    'Do, MMMM, YYYY': 'do, MMMM, yyyy',
    'MMM Do': 'MMM do',
    'MMMM Do, YYYY': 'MMMM do, yyyy',
    'dddd, MMMM Do YYYY': 'EEEE, MMMM do yyyy',
    'MMMM, YYYY': 'MMMM, yyyy',
    '[Q]Q': "'Q'Q",
    '[Q]Q, YY': "'Q'Q, yy",
    '[Q]Q, YYYY': "'Q'Q, yyyy",
};

// TODO JT add the Sprinklr supported locales here if deemed necessary
export const LOCALKEY_VS_LOCALE = {
    ar: ar,
};
