import { VideoPlayer, VideoPlayerOptions } from './Video';
import { VideoBase } from './VideoBase';
import ObjectUtils from '../../utils/ObjectUtils/ObjectUtils';

export class VideoFacebook extends VideoBase implements VideoPlayer {
    private static loading: Promise<any>;
    private boundReady = this.onReady.bind(this);
    private callbackStopped: () => boolean;
    private started: any;
    private finished: any;

    constructor(muted = false) {
        super(muted);
    }

    start(container: any, options: VideoPlayerOptions, callbackStopped: () => boolean) {
        // options.url = "https://www.facebook.com/reelcinemasdubai/videos/1495266387224912/";
        const url = options.url;

        this.id = ObjectUtils.hashCode(url);
        this.container = container;
        this.options = options;
        this.callbackStopped = callbackStopped;

        this.placeholder = this.findVideoPlaceholder(container);
        if (this.placeholder) {
            // Hide our marker div
            if (!options.hideMarkerAfterPlay) {
                this.placeholder.style.display = 'none';
            }

            this.loadPlayer().then(() => {
                const FB = (window as any).FB;

                // Remove any old video created
                this.destroyVideo();

                this.videoId = 'facebook' + VideoBase.unique++;
                this.videoElement = this.injectTargetDiv();

                // Need to call this for each new video because it apparently
                // scans the page for all divs with class="fb-video" and loads them
                FB.init({
                    appId: '{1420417024878754}',
                    xfbml: true,
                    version: 'v2.7',
                });

                // Load embedded video player instance
                FB.Event.subscribe('xfbml.ready', this.boundReady);
            });
        }
    }

    // Stop and destroy the video player instance
    stop(): void {
        const FB = (window as any).FB;

        // Show our marker div again
        if (this.placeholder) {
            this.placeholder.style.display = '';
        }

        if (this.player) {
            // Save the time so we can seek to it on the next play
            if (
                this.options.playFromLastTime &&
                this.player.getCurrentPosition() !== this.player.getDuration()
            ) {
                this.lastTime[this.id] = this.player.getCurrentPosition();
            } else {
                delete this.lastTime[this.id];
            }

            if (this.started) {
                this.started.release();
                this.started = null;
            }

            if (this.finished) {
                this.finished.release();
                this.finished = null;
            }

            this.player = null;
        }

        if (FB) {
            FB.Event.unsubscribe('xfbml.ready', this.boundReady);
        }

        // Remove any video created
        this.destroyVideo();
    }

    // Pause the video
    pause(): void {
        // Show our marker div
        if (this.placeholder) {
            this.placeholder.style.display = '';
        }

        if (this.player) {
            this.player.pause();
        }
    }

    // Play the video
    play(): void {
        if (this.player) {
            this.player.play();
        }

        // Hide our marker div
        if (this.placeholder) {
            this.placeholder.style.display = 'none';
        }
    }

    // When Facebook says video is ready, then this is called
    private onReady(msg: any): any {
        if (msg.type === 'video' && msg.id === this.videoId) {
            this.player = msg.instance;

            if (this.started) {
                this.started.release();
            }

            this.started = this.player.subscribe('startedPlaying', event => {
                if (this.lastTime[this.id]) {
                    this.player.seek(this.lastTime[this.id]);
                }

                // HACKTOWN:  Added 500ms delay because Facebook videos, at least
                // for my test, start to actually load when this event fires.
                // You would think they would actually be playing, but not so!
                if (this.options.hideMarkerAfterPlay) {
                    setTimeout(() => {
                        this.placeholder.style.display = 'none';
                    }, 500);
                }
            });

            if (this.finished) {
                this.finished.release();
            }

            this.finished = this.player.subscribe('finishedPlaying', event => {
                if (this.player) {
                    // Implement manual looping
                    if (!this.callbackStopped || this.callbackStopped()) {
                        this.player.play();
                    }
                }
            });

            if (this.muted) {
                this.player?.mute();
            }

            if (this.options.autoplay) {
                this.player?.play();
            }
        }
    }

    // Load the YouTube player API dynamically to cut down on initial overall app size
    private loadPlayer(): Promise<any> {
        if (VideoFacebook.loading) {
            return VideoFacebook.loading;
        }

        VideoFacebook.loading = new Promise<void>((resolve, reject) => {
            function load(d: any, s: string, id: string) {
                let js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = '//connect.facebook.net/en_US/sdk.js';
                // js.src = "//connect.facebook.net/en_US/sdk/debug.js";
                fjs.parentNode.insertBefore(js, fjs);
            }

            (window as any).fbAsyncInit = function() {
                resolve();
            };

            load(document, 'script', 'facebook-jssdk');
        });

        return VideoFacebook.loading;
    }

    private injectTargetDiv(): Element {
        const dimensions = this.getDimensions();

        const div = document.createElement('div');

        // Give the new div the old element's ID
        div.setAttribute('id', this.videoId);
        div.setAttribute('class', 'fb-video');
        div.setAttribute('data-href', this.options.url);
        div.setAttribute('data-width', dimensions.width.toString());
        div.setAttribute('data-height', dimensions.height.toString());
        div.setAttribute('data-allowfullscreen', 'false');

        this.placeholder.parentNode.insertBefore(div, this.placeholder);

        return div;
    }

    private destroyVideo() {
        let video: any = document.getElementById(this.videoId);
        if (video) {
            video.parentNode.removeChild(video);
            video = null;
        }
    }
}
