import {
    AnalyticsEngine,
    AnalyticsEngines,
    AnalyticsGroupBy,
    AnalyticsProjection,
    AnalyticsRequest,
    AnalyticsSortOrder,
    AnalyticsTimePeriod,
} from '@sprinklr/stories/analytics/AnalyticsRequest';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { SourcePost, SourceProfile } from '@sprinklr/stories/post/PostsFormatRequest';
import { PostsEngine } from '@sprinklr/stories/post/PostsRequest';
import { WidgetGroupBy } from './GroupBy/WidgetGroupBy';
import i18n from 'i18next';
import Dimension from '@sprinklr/stories/analytics/Dimension';
import { WIDGET_DAYS_OF_WEEK } from 'models/Widget/constants';
import _set from 'lodash/set';
import { runInAction } from 'mobx';
import { NumberFormat } from '../../widgets/DataTableWidget/types';
import { DecimalFormat } from 'utils/Number/NumberUtils';
import { DATE_FILTER_DATA_TYPES } from 'components/Panel/Picker/PickerSLADateFilter/constants';
import { FieldDataType } from '@sprinklr/stories/reporting/types';

function filterByGivenIndex(index: number, itemIndex: number | null = null) {
    if (itemIndex != null) {
        return itemIndex === index;
    }
    return true;
}

/**
 * @param {Widget} widget widget
 * @param {string[]} dimList array of dimensionNames to check
 * @param {number=} dimIndex (optional) the index of the groupBy
 * @return boolean
 */
export const widgetHasDim = (widget: Widget, dimList: string[], dimIndex?: number): boolean => {
    return (
        widget.analyticsRequests?.[0]?.groupBys
            ?.filter((groupBy, index) => filterByGivenIndex(index, dimIndex))
            .some(groupBy => {
                return dimList.includes(groupBy.dimensionName);
            }) ?? false
    );
};

/**
 * @param {Widget} widget widget
 * @param {string[]} dimList array of dimensionNames to check
 * @param {number=} dimIndex (optional) the index of the groupBy
 * @return boolean
 */
export const widgetHasFilter = (widget: Widget, dimList: string[], dimIndex?: number): boolean => {
    return (
        widget.analyticsRequests?.[0]?.filters
            ?.filter((filter, index) => filterByGivenIndex(index, dimIndex))
            .some(filter => {
                return dimList.includes(filter.dimensionName);
            }) ?? false
    );
};

/**
 * @param {Widget} widget widget
 * @param {string[]} metricList array of metric headings to check
 * @param {number=} metricIndex (optional) the index of the metric
 * @return boolean
 */
export function widgetHasMetric(
    widget: Widget,
    metricList: string[],
    metricIndex?: number
): boolean {
    return (
        widget.analyticsRequests?.[0]?.projections
            ?.filter((projection, index) => filterByGivenIndex(index, metricIndex))
            .some(projection => {
                return metricList.includes(projection.measurementName);
            }) ?? false
    );
}

export function isDateByDataType(dataType: FieldDataType) {
    return DATE_FILTER_DATA_TYPES.includes(dataType);
}

export function isDateByType(groupType: string) {
    return ['DATE_HISTOGRAM', 'TIMESTAMP', 'DATE'].includes(groupType);
}

export const widgetDimIsDate = (widget: Widget, dimIndex = 0): boolean => {
    const groupBy = widget.analyticsRequests?.[0]?.groupBys?.[dimIndex];
    return isDateByType(groupBy?.groupType) || isDateByDataType(groupBy?.details?.dataType);
};

export const widgetDimIsEmoji = (widget: Widget, dimIndex = 0): boolean => {
    const emojiDimensions = ['SPECIFIC_THEME_TAG_Emoji', 'EMOTICONS', 'EMOJI'];
    return emojiDimensions.includes(
        widget.analyticsRequests?.[0]?.groupBys?.[dimIndex]?.dimensionName
    );
};

export function getMetricsFromWidget(widget: Widget): AnalyticsProjection[] {
    if (widget.analyticsRequests?.[0]?.projections) {
        return widget.analyticsRequests[0].projections;
    }

    if ((widget.postRequests?.[0]?.sources?.[0] as SourcePost)?.id?.projections) {
        return (widget.postRequests[0].sources[0] as SourcePost).id.projections;
    }

    return null;
}

/**
 * Checks to see if the metric is SLA.
 * TODO: Add more dataTypes if we find them matching SLA
 * @param widget {Widget} widget to check
 * @param offset {number=} (optional) zero based index of the metric
 */
export function isMetricTimeDiff(widget: Widget, offset = 0): boolean {
    const isTimeDiff =
        widget.analyticsRequests?.[0]?.projections?.[offset]?.details?.dataType ===
        'TIME_DIFFERENCE';

    return !!isTimeDiff;
}

export function getWidgetEngine(widget: Widget): AnalyticsEngine | PostsEngine | null {
    // check for analytics engine
    if (widget.analyticsRequests?.[0]?.reportingEngine) {
        return widget.analyticsRequests[0].reportingEngine;
    }

    // check for posts engine
    if (
        (widget.postRequests?.[0]?.sources?.[0] as SourcePost | SourceProfile)?.id?.reportingEngine
    ) {
        return (widget.postRequests[0].sources[0] as SourcePost | SourceProfile).id.reportingEngine;
    }

    return null;
}

export const widgetDimsLength = (widget: Widget): number => {
    return widget?.analyticsRequests?.[0]?.groupBys?.length;
};

function isWidgetGroupBy(
    groupByOrProjection: AnalyticsGroupBy | AnalyticsProjection | WidgetGroupBy
): groupByOrProjection is WidgetGroupBy {
    return (groupByOrProjection as WidgetGroupBy)?.setAlternateHeading !== undefined;
}

// Note: Combo and Dual-axis use the label "Time" if dimension is 'DATE_HISTOGRAM'
// so we account for that in our check
export function groupByOrProjectionAlternateHeading(
    groupByOrProjection: AnalyticsGroupBy | AnalyticsProjection | WidgetGroupBy
): string {
    if (!groupByOrProjection) {
        return;
    }
    const isTime =
        (groupByOrProjection as AnalyticsGroupBy)?.groupType === 'DATE_HISTOGRAM' &&
        (groupByOrProjection as AnalyticsGroupBy)?.details?.interval?.includes('h');

    let heading = groupByOrProjection?.heading;
    if (isTime) {
        heading = 'Time';
        // Don't treat 'SLA_FREQUENCY' like other dimensions with time intervals - the heading should be 'SLA_FREQUENCY' not 'SLA'
    } else if (
        (groupByOrProjection as AnalyticsGroupBy)?.details?.interval &&
        (groupByOrProjection as AnalyticsGroupBy)?.dimensionName !== 'SLA_FREQUENCY'
    ) {
        heading = groupByOrProjection?.heading.split('_')[0];
    }

    if ((groupByOrProjection as AnalyticsGroupBy)?.details?.displayName) {
        heading = (groupByOrProjection as AnalyticsGroupBy).details.displayName;
    }

    const altHeading = isWidgetGroupBy(groupByOrProjection)
        ? groupByOrProjection?.groupBy?.details?.alternateHeading
        : groupByOrProjection.details?.alternateHeading;
    return !!altHeading && altHeading !== heading ? altHeading : heading;
}

export function widgetDataSourceName(widget: Widget): string {
    // console.log('widgetDataSourceName', widget);
    const request = widget?.analyticsRequests?.length && widget?.analyticsRequests[0];
    if (request) {
        switch (request.reportingEngine) {
            case 'RDB_FIREHOSE':
                const query_filter = request.filters.find(
                    filter => filter.dimensionName === 'QUERY'
                );
                if (query_filter) {
                    return query_filter.values[0];
                }

                const id_filter = request.filters.find(
                    filter => filter.dimensionName === 'RDB_QUERY_IDS'
                );
                if (id_filter) {
                    // return id_filter.values[0]; // TODO, bulklookup query name
                    return i18n.t('Listening Explorer');
                }
                break;

            case 'PAID':
                return i18n.t('Ads Reporting');

            case 'ADVOCACY':
                return i18n.t('Advocacy');

            case 'BENCHMARKING':
                return i18n.t('Benchmarking Insights');

            case 'CAMPAIGN':
                return i18n.t('Content Marketing');

            case 'MONITORING_DASHBOARD':
                return i18n.t('Engagement Dashboard');

            case 'LISTENING':
                return i18n.t('Listening Insights');

            case 'INBOUND_MESSAGE':
                return i18n.t('Reporting Insights: Inbound Analytics');

            case 'PLATFORM':
                return i18n.t('Reporting Insights: Social Analytics');

            case 'STORY_MESSAGE':
                return i18n.t('Story Analytics');

            case 'HISTORIC_TRENDS':
                return i18n.t('X Trends');

            case 'UNIFIED_ANALYTICS_REPORTING_ENGINE':
                return i18n.t('Unified Analytics');

            case 'SPR_TASK':
                return i18n.t('Task');

            case 'CUSTOM_ENTITY':
                return i18n.t('Custom Entity');

            case 'VOICE':
                return i18n.t('Voice Analytics');

            case 'COMMUNITY':
                return i18n.t('Community');

            case 'SAM':
                return i18n.t('DAM');

            case 'AUDIENCE_ACTIVITY':
                return i18n.t('Audience Activity');

            case 'OUTBOUND_MESSAGE':
                return i18n.t('Outbound Message');

            case 'CONSUMPTION':
                return i18n.t('Consumption Analytics');

            default:
                return request.reportingEngine;
        }
    }

    if (widget.postRequests) {
    }

    return null;
}

// Remove previous period if unused by widget-AMR
export function removeUnusedPreviousPeriod(
    timePeriods: AnalyticsTimePeriod[],
    options: any,
    type: string
) {
    if (
        timePeriods.length &&
        !options.showMetricChange &&
        !options.compareMode &&
        !options.metricGroupOptions?.percentChange?.enabled &&
        type !== 'dataTableGroupedSummary' &&
        type !== 'dataTableSummary'
    ) {
        return timePeriods.filter(timePeriod => {
            return !timePeriod.previousPeriod;
        });
    }
    return timePeriods;
}

export function hasDeltaProjections(request: AnalyticsRequest) {
    return request.projections?.some(
        projection =>
            'CHANGE' === projection.aggregateFunction ||
            'PERCENTAGE_CHANGE' === projection.aggregateFunction
    );
}

/**
 * checks to see if a widget is visible based on options
 * @param {Widget} widget - the widget to check
 */
export function widgetIsVisible(widget: Widget) {
    return widget.options.opacity > 0;
}

export function additionalSort(
    dimension: Dimension,
    values: any[],
    dir: AnalyticsSortOrder = 'ASC'
) {
    if (
        ((dimension.type === 'TIMESTAMP' || dimension.type === 'DATE') &&
            (dimension.name === 'minute' ||
                dimension.name.indexOf('_1m') !== -1 ||
                dimension.name === 'hour' ||
                dimension.name.indexOf('_1h') !== -1)) ||
        dimension.name === 'Time Of Day' ||
        dimension.name === 'TIME_OF_DAY'
    ) {
        if (dir === 'ASC') {
            values.sort();
        } else {
            values.sort((a, b) => parseInt(b, 10) - parseInt(a, 10));
        }
        return;
    }

    if (dimension.name === 'Day Of Week' || dimension.name === 'DAY_OF_WEEK') {
        values.sort((a, b) => {
            if (dir === 'ASC') {
                return (
                    WIDGET_DAYS_OF_WEEK.indexOf(a.toLowerCase()) -
                    WIDGET_DAYS_OF_WEEK.indexOf(b.toLowerCase())
                );
            } else {
                return (
                    WIDGET_DAYS_OF_WEEK.indexOf(b.toLowerCase()) -
                    WIDGET_DAYS_OF_WEEK.indexOf(a.toLowerCase())
                );
            }
        });
        return;
    }
}

// this pattern gets around the extendObservable pattern
// https://alexhisen.gitbook.io/mobx-recipes/use-extendobservable-sparingly
export function updateWidgetOptions(
    widget: Widget,
    path: string,
    value: string | number | boolean | DecimalFormat | NumberFormat | Array<any>
) {
    const copyWidgetOptions = Object.assign({}, widget.options);
    _set(copyWidgetOptions, path, value);
    runInAction(() => {
        widget.options = copyWidgetOptions;
    });
}

export function canBePublishedRapidly(widget: Widget) {
    if (!widget) {
        return false;
    }

    const widgetContainsWidgetWithEngine = (widget: Widget, engine: string) => {
        if (!widget || !engine) {
            return false;
        }
        if (widget.analyticsRequests?.some(request => request.reportingEngine === engine)) {
            return true;
        }
        if (widget.postRequest?.reportingEngine === engine) {
            return true;
        }
        return false;
    };

    const widgetContainsWidgetWithAnyMatchingEngine = (widget: Widget, engines: string[]) => {
        if (engines.some(engine => widgetContainsWidgetWithEngine(widget, engine))) {
            return true;
        }
        return widget.children?.some(child =>
            widgetContainsWidgetWithAnyMatchingEngine(child, engines)
        );
    };

    const allowedEngines = [
        'PLATFORM',
        'INBOUND_MESSAGE',
        'UNIVERSAL_PROFILE',
        'VOICE',
        'SPR_TASK',
        'CUSTOM_ENTITY',
        'COMMUNITY',
        'SAM',
        'AUDIENCE_ACTIVITY',
        'OUTBOUND_MESSAGE',
        'CONSUMPTION',
    ];
    const disallowedEngines = AnalyticsEngines.filter(
        engine => allowedEngines.indexOf(engine) === -1
    );

    // Don't allow real time publishing if widget contains disallowed widgets,
    if (widgetContainsWidgetWithAnyMatchingEngine(widget, disallowedEngines)) {
        return false;
    }
    // ...but it needs to have at least one allowed widget. e.g., disallow panels with only rich text or shape widgets.
    return widgetContainsWidgetWithAnyMatchingEngine(widget, allowedEngines);
}
