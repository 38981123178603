import { VersionFields } from '../Version/Version';
import { SceneVersionFields, SceneVersionLightFields } from '../Scene/SceneVersion';
import { CommonDocFields } from '../CommonDoc/CommonDoc';
import { LayoutFields } from '../Layout/Layout';
import { VersionCapableFields } from '../VersionCapable/VersionCapable';
import { ThemeSnippet } from '../Theme/Theme';
import { SingleTimePeriodFields } from 'models/TimePeriod/SingleTimePeriod';
import { DataSourceFragment, DataSourcesSnippet } from 'models/DataSource/DataSource';
import { ContentLayerFields } from 'models/ContentLayer/ContentLayer';

export const StoryboardVersionFields = `
    ${CommonDocFields}
    ${VersionFields}
    archived
    published
    saved
    savedByUserId
    sceneDuration
    scenes {
        ${SceneVersionFields}
    }
    firstScene {
        ${SceneVersionFields}
    }
    contentLayer {
        ${ContentLayerFields}
    }
    sceneCount
    layout {
        ${LayoutFields}
    }
    timePeriod {
        ${SingleTimePeriodFields}
    }
    previousTimePeriod {
        ${SingleTimePeriodFields}
    }
    compareMode
    ${ThemeSnippet}
`;

export const StoryboardVersionLightFieldsOnlyFirstScene = `
    ${CommonDocFields}
    ${VersionCapableFields}
    ${DataSourcesSnippet}
    masterId
    treeDirty
    archived
    published
    saved
    immutable
    savedByUserId
    savedByUser {
        id
        firstName
        lastName
        emailAddress
        profileImageUrl
    }
    firstScene {
        ${SceneVersionLightFields}
    }
    timePeriod {
        ${SingleTimePeriodFields}
    }
    previousTimePeriod {
        ${SingleTimePeriodFields}
    }
    compareMode
    sceneCount
`;

/**
 * Includes link to the layout by id.
 * @type {string}
 */
export const StoryboardVersionLightFieldsLayout = `
    ${CommonDocFields}
    ${VersionCapableFields}
    ${DataSourcesSnippet}
    layout { id }
    masterId
    treeDirty
    archived
    published
    saved
    immutable
    savedByUserId
    savedByUser {
        id
        firstName
        lastName
        emailAddress
        profileImageUrl
    }
    firstScene {
        ${SceneVersionLightFields}
    }
    sceneCount
    scenes {
        ${SceneVersionLightFields}
    }
    timePeriod {
        ${SingleTimePeriodFields}
    }
`;

/**
 * Contains all fragments used by various storyboardversionlight queries
 */
export const StoryboardVersionLightFrags = `
    ${DataSourceFragment}
`;
