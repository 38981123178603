const GenerateTransitionStyles = function(
    targetClass: string,
    indexClass: string,
    itemsLength: number,
    animationLength: number,
    delay: number,
    prefix: string
) {
    let styles = '';

    // Build Transition Times
    const transitionDuration = itemsLength < 1 ? animationLength : (animationLength * 5) / 8;
    // do we need to build this if there itemsLength < 1 ?
    const transitionDelay =
        (animationLength - transitionDuration) /
        (itemsLength > 1 ? itemsLength - 1 : itemsLength ?? 1);

    prefix = prefix ? prefix + ' ' : '';

    for (let i = 0; i < itemsLength; i++) {
        // specificly check for null here  -  allows us to optionally have no index class generated
        if (indexClass === null) {
            styles += `
                ${prefix}${targetClass} {
                    transition-delay: ${transitionDelay * i}ms;
                } `;
        } else {
            styles += `
                ${prefix}${indexClass || targetClass}_index_${i + 1}${targetClass} {
                    transition-delay: ${transitionDelay * i}ms;
                } `;
        }
    }

    styles += `${prefix}${targetClass} { transition-duration: ${transitionDuration}ms; } `;

    return styles;
};

export { GenerateTransitionStyles };
