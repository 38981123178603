export const WIDGET_TYPES = {
    posts: 'posts',
    postsCollage: 'postsCollage',
    postsGrid: 'postsGrid',
    postsGridV2: 'postsGridV2',
    postsFilmStrip: 'postsFilmStrip',
    postsTypePoster: 'postsTypePoster',
    postsCinematic: 'postsCinematic',
    postsSinglePost: 'postsSinglePost',
    postsTicker: 'postsTicker',
    postsWaterfall: 'postsWaterfall',
    postCard: 'postCard',
    storyCard: 'storyCard',
    runOfShow: 'runOfShow',
    barChart: 'barChart',
    pieChart: 'pieChart',
    stackedBarChart: 'stackedBarChart',
    lineChart: 'lineChart',
    comboChart: 'comboChart',
    dualAxis: 'dualAxis',
    axisBubbleChart: 'axisBubbleChart',
    bubblePlotChart: 'bubblePlotChart',
    dynamicImage: 'dynamicImage',
    dynamicTitle: 'dynamicTitle',
    sparkline: 'sparkline',
    countUp: 'countUp',
    wordCloud: 'wordCloud',
    bubbleChart: 'bubbleChart',
    legend: 'legend',
    metric: 'metric',
    multiMetric: 'multiMetric',
    metricValue: 'metricValue',
    metricValueLockup: 'metricValueLockup',
    rankedList: 'rankedList',
    rankedTable: 'rankedTable',
    dataTablePivot: 'dataTablePivot',
    dataTableCrossTab: 'dataTableCrossTab',
    dataTableSummary: 'dataTableSummary',
    dataTableGroupedSummary: 'dataTableGroupedSummary',
    treeMap: 'treeMap',
    worldHeatMap: 'worldHeatMap',
    geoStream: 'geoStream',
    geoStreamTicker: 'geoStreamTicker',
    profile: 'profile',
    metricRollup: 'metricRollup',
    heatMapTableChart: 'heatMapTableChart',
    googleTrends: 'googleTrends',
    twitterTrends: 'twitterTrends',
    autoAlerts: 'autoAlerts',
    smallMultiplesPieChart: 'smallMultiplesPieChart',
    smartCluster: 'smartCluster',
    customContentRichText: 'customContentRichText',
    customContentImage: 'customContentImage',
    customContentVideo: 'customContentVideo',
    customContentSocialPost: 'customContentSocialPost',
    customContentIframe: 'customContentIframe',
    sunburst: 'sunburst',
    shape: 'shape',
    customTable: 'customTable',
    customTableRichText: 'customTableRichText',
    funnelChart: 'funnelChart',
    hyperlinkButton: 'hyperlinkButton',
    speechBubble: 'speechBubble',
    smartTrends: 'smartTrends',
    hyperSpaceStackedBarChart: 'hyperSpaceStackedBarChart',
    hyperSpaceBarChart: 'hyperSpaceBarChart',
    hyperSpaceColumnChart: 'hyperSpaceColumnChart',
    hyperSpaceStackedColumnChart: 'hyperSpaceStackedColumnChart',
    hyperSpaceBubblePlotChart: 'hyperSpaceBubblePlotChart',
    hyperSpaceAxisBubbleChart: 'hyperSpaceAxisBubbleChart',
    hyperSpaceLineChart: 'hyperSpaceLineChart',
    hyperSpacePieChart: 'hyperSpacePieChart',
    hyperSpaceAreaChart: 'hyperSpaceAreaChart',
    hyperSpaceDualAxisChart: 'hyperSpaceDualAxisChart',
    hyperSpaceComboChart: 'hyperSpaceComboChart',
    hyperSpaceTreeMap: 'hyperSpaceTreeMap',
    hyperSpaceAlerts: 'hyperSpaceAlerts',
    hyperSpaceQuadrantMatrix: 'hyperSpaceQuadrantMatrix',
} as const;

export const WIDGET_DAYS_OF_WEEK = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
]; // this is only used by HeatmapTableChart

export const TEXT_SHADOW_DEFAULT = {
    enabled: false,
    color: 'rgba(0,0,0,.3)',
    x: 1,
    y: 1,
    blur: 2,
};
