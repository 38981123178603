import moment from 'moment';
import dfnsFormat from 'date-fns/format';
import dateFnsTzFormat from 'date-fns-tz/format';
import { titleCase } from 'utils/StringUtils/StringUtils';
import { getEndianTime } from 'utils/Number/NumberUtils';
import { LOCALKEY_VS_LOCALE, MOMENT_VS_DATEFNS_FORMAT } from './constants';

export const getDisplayDate = (dateRange: any[], labelTimeZone: string, format: string): string => {
    let date: string;
    if (dateRange) {
        if (format === 'Pretty Time') {
            const isCustom = dateRange[2] === 'custom' || dateRange[2] === 'custom_duration';
            const isLastX = dateRange[2]?.includes('last_x_');
            const isDaysAgo = dateRange[2]?.includes('x_days_ago');
            const isCustomToDate = dateRange[2]?.includes('custom_to_date');
            const isWeeksAgo = dateRange[2]?.includes('x_weeks_ago');
            const isMonthsAgo = dateRange[2]?.includes('x_months_ago');
            const isQuartersAgo = dateRange[2]?.includes('x_quarters_ago');

            if (isCustom) {
                const start = moment(parseInt(dateRange[0], 10)).format('Do MMM, YYYY');
                const end = moment(parseInt(dateRange[1], 10)).format('Do MMM, YYYY');
                date = `${start} - ${end}`;
            } else if (isLastX) {
                const start = moment(parseInt(dateRange[0], 10));
                const end = moment(parseInt(dateRange[1], 10));

                const intervalName = dateRange[2] && dateRange[2].replace('last_x_', '');
                const intervalValue = end.diff(start, intervalName);
                const intervalPrettyName =
                    intervalValue > 1
                        ? intervalName
                        : intervalName.substring(0, intervalName.length - 1);

                date = `Last ${intervalValue} ${titleCase(intervalPrettyName)}`;
            } else if (isDaysAgo) {
                const start = moment();
                const end = moment(parseInt(dateRange[0]));

                const days = start.diff(end, 'days');
                if (days === 1) {
                    date = `${days} Day Ago`;
                } else {
                    date = `${days} Days Ago`;
                }
            } else if (isWeeksAgo) {
                const start = moment();
                const end = moment(parseInt(dateRange[0]));
                const weeks = start.diff(end, 'weeks');

                if (weeks === 1) {
                    date = `${weeks} Week Ago`;
                } else {
                    date = `${weeks} Weeks Ago`;
                }
            } else if (isMonthsAgo) {
                const start = moment();
                const end = moment(parseInt(dateRange[0]));
                const months = start.diff(end, 'months');

                if (months === 1) {
                    date = `${months} Month Ago`;
                } else {
                    date = `${months} Months Ago`;
                }
            } else if (isQuartersAgo) {
                const start = moment();
                const end = moment(parseInt(dateRange[0]));
                const quarters = start.diff(end, 'quarters');

                if (quarters === 1) {
                    date = `${quarters} Quarter Ago`;
                } else {
                    date = `${quarters} Quarters Ago`;
                }
            } else if (isCustomToDate) {
                const start = moment(parseInt(dateRange[0], 10)).format('ddd, MMMM Do');

                date = `${start} Through Today`;
            } else {
                date = dateRange[2] && dateRange[2].replace(/_/g, ' ');
            }
        } else {
            const timeZone = labelTimeZone?.length ? labelTimeZone : dateRange[3];
            const startDate = getEndianTime(parseInt(dateRange[0], 10), format, timeZone);
            const endDate = getEndianTime(parseInt(dateRange[1], 10), format, timeZone);
            date = startDate + ' - ' + endDate;
        }
    }
    return date;
};

// attempt to convert momentJS format to date-fns format
// this function seems to cover our presets, but may miss custom json formats
// helpful notes: https://github.com/date-fns/date-fns/blob/main/docs/unicodeTokens.md
// currently unused but may be useful in the conversion process - JT
export function momentJSFormatToDateFnsFormat(format: string) {
    // handle our current options
    if (MOMENT_VS_DATEFNS_FORMAT[format]) {
        return MOMENT_VS_DATEFNS_FORMAT[format];
    }

    // common replacements
    return format
        .replace(/dddd/g, 'EEEE')
        .replace(/ddd/g, 'EEE')
        .replace('[Q]Q', "'Q'Q")
        .replace(/YYYY/g, 'yyyy')
        .replace(/YY/g, 'yy')
        .replace(/Z/g, 'xxx');
}

export function getFormattedDateTime({
    format,
    date,
    localeKey,
    options: _options = {},
    timeZone,
}: {
    format: string;
    date: string | number;
    localeKey?: string;
    // these options are passed to date-fns, couldn't find an easier type
    options?: Partial<{
        locale?: Locale;
        weekStartsOn?: 0 | 3 | 1 | 2 | 6 | 4 | 5;
        firstWeekContainsDate?: number;
        useAdditionalWeekYearTokens?: boolean;
        useAdditionalDayOfYearTokens?: boolean;
    }>;
    timeZone?: string;
}) {
    const options = {
        ..._options,
        // these help custom JSON formats convert correctly
        useAdditionalDayOfYearTokens: true,
        useAdditionalWeekYearTokens: true,
    };

    if (localeKey && LOCALKEY_VS_LOCALE[localeKey]) {
        options['locale'] = LOCALKEY_VS_LOCALE[localeKey];
    }

    const resolvedDate = new Date(date);

    if (timeZone) {
        return dateFnsTzFormat(resolvedDate, format, { timeZone });
    }

    return dfnsFormat(resolvedDate, format, options);
}

// attempt to centralize the locale setting for momentJS
export function applyLocaleToMoment(dateInstance: moment.Moment, language: string): void {
    if (language === 'ar') {
        // ar-MA retains latin numerals which matches date-fns
        dateInstance.locale('ar-MA');
    }
}
