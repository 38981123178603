import { withContentRect } from 'react-measure';

export default <T>(componentClass: T): T & { wrappedComponent: T } => {
    const wrapped: any = withContentRect(['bounds', 'client'])(componentClass as any);
    wrapped.wrappedComponent = componentClass;
    // Even if the wrappedComponent has no propTypes, we need to do this to avoid react errors as withContentRect() will
    // add its own propTypes, and if we have a clash on, say, prop offset we'll get noisy errors, showed up on
    // WidgetChild which has offset as a number, and measured has it as a bool.
    wrapped.propTypes = wrapped.wrappedComponent.propTypes;

    if ((componentClass as any).displayName) {
        wrapped.displayName = (componentClass as any).displayName;
    }

    return wrapped as T & { wrappedComponent: T };
};
