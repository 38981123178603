import merge from 'deepmerge';
import { CSSProperties } from 'react';
import { toJS, isObservable } from 'mobx';
import Color from 'color';
import _last from 'lodash/last';

// types
import { Widget, Ext } from '@sprinklr/stories/widget/Widget';
import { Screen } from 'models/Screen/Screen';
import {
    Theme,
    themeDefaultValues,
    Background,
    BackgroundSize,
    builtInThemes,
} from 'models/Theme/Theme';
import { WidgetLabelInterface } from '@sprinklr/stories/widget/Widget';
import { WidgetOptions } from '@sprinklr/stories/widget/WidgetOptions';
import { Panel } from 'models/Panel/Panel';
import DataSet from '@sprinklr/stories/analytics/DataSet';
import { Layout } from 'models/Layout/Layout';
import { Measurements } from 'components/Widget/WidgetComponent/types';

// helpers
import { generateColorPalette, socialColors } from '../GenerateColors/GenerateColors';
import { GetNumberOfDataItems } from '../GetNumberOfDataItems/GetNumberOfDataItems';
import { GenerateSafeString } from 'utils/StringUtils/StringUtils';

// constants
import { BrandColors } from '../../stores/PanelTemplatesStore/PanelTemplatesTheme/constants';
import { isGoogleFont } from 'utils/Fonts/googleFonts';
import { getResolvedFamilyWeight } from 'components/_UI/FontLoader/helpers';
import { FALLBACK_FONT_STACK } from '../Fonts/constants';
import { getColorOverridesCss } from 'utils/getColorOverridesCss';
import { WidgetConfig } from '@sprinklr/display-builder/widgets/widgetFactory/types';

const shadyCss: any = require('shady-css-parser');

export type ComputedStyle = {
    selector: string;
    styles: CSSProperties;
};

export type BackgroundMap = {
    [key: string]: {
        x: number;
        y: number;
    };
};

export type PrefixConfig = {
    prefix: string;
    join?: boolean;
};

export type StyleSeriesRecipe = {
    incrementor?: string;
    specifier?: string;
    joinSpecifier?: boolean;
    property: string;
};

type Dimensions = {
    width: number;
    height: number;
};

type Offset = {
    top: number;
    left: number;
};

type FillMode = 'contain' | 'cover' | 'repeat';

type Offsets = Offset[];
type Screens = Screen[];

type PostMergedColors = {
    textColor: string;
    overlayColor: string;
    textOnlyBackgroundColor: string;
    imagePostBackgroundColor: string;
    imageBlendColor: string;
};

const scrubber = (input: any): any => {
    const output: any = {};

    for (const key in input) {
        let value = input[key];
        if (value === undefined || value === '' || value === null) {
            continue;
        }

        if (typeof value === 'object') {
            // have to use toJS to check isArray in mobX 4 and lower
            if (isObservable(value)) {
                value = toJS(value);
            }
            // keeps arrays intact
            if (!Array.isArray(value)) {
                value = scrubber(value);
            }
            if (Object.keys(value).length === 0) {
                continue;
            }
        }

        output[key] = value;
    }

    return output;
};

const fadedFontColor = fontColor => {
    return fontColor !== '' ? Color(fontColor.trim()).fade(0.75) : null;
};

const borderStyles = fontColor => {
    const colorFontFaded = fadedFontColor(fontColor);
    return colorFontFaded ? `1px solid ${colorFontFaded}` : null;
};

// all the styles for the themes
const styleComputer = (
    theme: Theme,
    layout?: Layout,
    type?: 'storyboard' | 'scene' | 'panel' | 'widget' | 'storyboard-scene'
): ComputedStyle[] => {
    // console.log('type',type);
    if (!theme || Object.keys(theme).length === 0) {
        return [];
    }

    const {
        typography: {
            color: fontColor = null,
            size: fontSize = null,
            sizeLiteral: fontSizeLiteral = null,
            primary: {
                url: pFonturl = null,
                family: pFontFamily = null,
                weight: pFontWeight = 'normal',
                style: pFontStyle = 'normal',
                textTransform: pFontCase = null,
            } = {},
            secondary: {
                url: sFonturl = null,
                family: sFontFamily = null,
                weight: sFontWeight = 'normal',
                style: sFontStyle = 'normal',
                textTransform: sFontCase = null,
            } = {},
        } = {},
        background: { url: backgroundImageUrl = null, color: backgroundColor = null } = {},
        colorPalette: { solid = null, ranked = null } = {},
    } = scrubber(theme);

    const { offsets, computedBackground } = computeBackground(theme.background, layout, type);

    let rankedColors = null;
    if (ranked && ranked.colors) {
        rankedColors = ranked.colors;
    }
    const primaryColor = rankedColors && rankedColors.length > 0 ? rankedColors[0] : solid;

    let primaryFontStyles = {
        fontFamily: pFontFamily ? `"${pFontFamily}", ${FALLBACK_FONT_STACK}` : '',
        fontWeight: pFontFamily && pFontWeight ? `${pFontWeight}` : '',
    };
    if (isGoogleFont({ family: pFontFamily })) {
        const fontFamilyWeightPairs = getResolvedFamilyWeight({
            family: pFontFamily,
            weight: undefined,
        });
        primaryFontStyles = {
            fontFamily: fontFamilyWeightPairs.family,
            fontWeight: fontFamilyWeightPairs.weight,
        };
    }

    let secondaryFontStyles = {
        fontFamily: sFontFamily ? `"${sFontFamily}", ${FALLBACK_FONT_STACK}` : '',
        fontWeight: sFontFamily && sFontWeight ? `${sFontWeight}` : '',
    };
    if (isGoogleFont({ family: sFontFamily })) {
        const fontFamilyWeightPairs = getResolvedFamilyWeight({
            family: sFontFamily,
            weight: undefined,
        });
        secondaryFontStyles = {
            fontFamily: fontFamilyWeightPairs.family,
            fontWeight: fontFamilyWeightPairs.weight,
        };
    }

    const output = [];
    output.push(
        ...[
            {
                selector: type === 'widget' || type === 'panel' ? '' : '.panel',
                styles: {
                    backgroundColor,
                    backgroundImage:
                        backgroundImageUrl && backgroundImageUrl !== 'none'
                            ? `url(${backgroundImageUrl})`
                            : '',
                    backgroundSize: computedBackground.size,
                    backgroundRepeat: computedBackground.repeat,
                    backgroundPosition: computedBackground.position,
                    color: fontColor,
                    fontSize: fontSize || fontSizeLiteral,
                },
            },
            {
                selector: '.change_negative',
                styles: {
                    color: BrandColors.red,
                },
            },
            {
                selector: '.primary_stroke',
                styles: {
                    stroke: primaryColor,
                },
            },
            {
                selector: '.primary_fill',
                styles: {
                    fill: primaryColor,
                },
            },
            {
                selector: '.primary_border',
                styles: {
                    borderColor: primaryColor,
                },
            },
            {
                selector: '.primary_background',
                styles: {
                    backgroundColor: primaryColor,
                },
            },
            {
                selector: '.primary_color',
                styles: {
                    color: primaryColor,
                },
            },
            {
                selector: 'text',
                styles: {
                    fill: fontColor,
                },
            },
            {
                selector: '.public-DraftStyleDefault-ul li > div:before',
                styles: {
                    background: fontColor,
                },
            },
            {
                selector: '.profile_metric_divider',
                styles: {
                    background: fontColor ? Color(fontColor.trim()).fade(0.2) : '',
                },
            },
            {
                selector: '.vx-line',
                styles: {
                    stroke: fontColor,
                },
            },
            !!pFonturl && {
                selector: '@font-face',
                styles: {
                    fontFamily: pFontFamily ? `"${pFontFamily}"` : '',
                    src: pFonturl ? `url('${pFonturl}')` : '',
                    fontWeight: pFontFamily && pFontWeight ? `${pFontWeight}` : '',
                    fontStyle: pFontFamily && pFontStyle ? `${pFontStyle}` : '',
                },
            },
            {
                selector: '.primary_font_family',
                styles: {
                    ...primaryFontStyles,
                    fontStyle: pFontFamily && pFontStyle ? `${pFontStyle}` : '',
                    textTransform: pFontCase ? `${pFontCase}` : '',
                },
            },
            !!sFonturl && {
                selector: '@font-face',
                styles: {
                    fontFamily: sFontFamily ? `"${sFontFamily}"` : '',
                    src: sFonturl ? `url('${sFonturl}')` : '',
                    fontWeight: sFontFamily && sFontWeight ? `${sFontWeight}` : '',
                    fontStyle: sFontFamily && sFontStyle ? `${sFontStyle}` : '',
                },
            },
            {
                selector: '.secondary_font_family',
                styles: {
                    ...secondaryFontStyles,
                    fontStyle: sFontFamily && sFontStyle ? `${sFontStyle}` : '',
                    textTransform: sFontCase ? `${sFontCase}` : '',
                },
            },
            {
                selector: '.secondary_font_family b',
                styles: {
                    ...primaryFontStyles,
                    fontStyle: pFontFamily && pFontStyle ? `${pFontStyle}` : '',
                    textTransform: pFontCase ? `${pFontCase}` : '',
                },
            },
        ].filter(Boolean)
    );

    if (offsets) {
        offsets.forEach((offset, index: number) => {
            output.push({
                selector: `.panel_screen_index_${index + 1}`,
                styles: {
                    backgroundPosition: `${offset.left}px ${offset.top}px`,
                },
            });
        });
    }

    output.forEach(entry => {
        entry.styles = scrubber(entry.styles);
    });

    return output;
};

const computeBackground = (
    background: Background,
    layout: Layout,
    type: 'storyboard' | 'scene' | 'panel' | 'widget' | 'storyboard-scene'
) => {
    let offsets: Offsets = [];
    const computedBackground = {
        size: '',
        repeat: '',
        position: '',
    };

    if (!background) {
        return { offsets, computedBackground };
    }

    if (type === 'storyboard' || type === 'scene' || type === 'storyboard-scene') {
        const screens = layout && layout.screens;
        const multiScreen = screens && screens.length > 1;

        if (multiScreen && background.url) {
            const scale = 50;
            const { width: imageWidth, height: imageHeight } = getUrlDimensions(background.url);
            const { layoutRatio, imgRatio, layoutBounds } = getRatios(screens, background.url);
            const { width, height }: Dimensions = getBackgroundSize(
                layoutRatio,
                imgRatio,
                layoutBounds,
                background.size,
                scale
            );

            offsets = getOffsets(screens, layoutBounds, width, height);
            computedBackground.size = `${width}px ${height}px`;
            computedBackground.repeat =
                background.repeat === undefined ? 'repeat' : background.repeat;
        } else if (background.url) {
            computedBackground.size = background.size;
            computedBackground.repeat =
                background.repeat === undefined ? 'repeat' : background.repeat;
            computedBackground.position = background.position || 'center center';
        } else if (type !== 'scene') {
            computedBackground.size = 'cover';
            computedBackground.repeat = 'repeat';
            computedBackground.position = 'center center';
        }
    } else if (type === 'panel' || type === 'widget') {
        if (background.url) {
            computedBackground.size = background.size;
            computedBackground.repeat =
                background.repeat === undefined ? 'repeat' : background.repeat;
            computedBackground.position = background.position || 'center center';
        }
    }

    return { offsets, computedBackground };
};

export const styleLabelComputer = (
    recipes: StyleSeriesRecipe[],
    labelColors: { [label: string]: string }
): ComputedStyle[] => {
    if (!labelColors || !recipes) {
        return;
    }

    const output = [];
    recipes.forEach(recipe =>
        Object.keys(labelColors).forEach(label => {
            const color = labelColors[label];

            const labelSanitized = GenerateSafeString(label);

            const suffix = `${recipe.joinSpecifier ? '' : ' '}${
                recipe.specifier ? recipe.specifier : ''
            }`;
            output.push({
                selector: `${recipe.incrementor}_label_${labelSanitized}${suffix}`,
                styles: {
                    [recipe.property]: color,
                },
            });
            output.push({
                selector: `${recipe.incrementor}_value_${labelSanitized}${suffix}`,
                styles: {
                    [recipe.property]: color,
                },
            });
        })
    );
    return output;
};

const styleSeriesComputer = (recipes: StyleSeriesRecipe[], values: string[]): ComputedStyle[] => {
    if (!values && !recipes) {
        return;
    }

    const output = [];
    recipes.forEach(recipe =>
        values.forEach((value, index) =>
            output.push({
                selector: `${recipe.incrementor}_index_${index + 1}${
                    recipe.joinSpecifier ? '' : ' '
                }${recipe.specifier ? recipe.specifier : ''}`,
                styles: {
                    [recipe.property]: value,
                },
            })
        )
    );
    return output;
};

const getStyleSeriesCss = (
    theme: Theme,
    mergedTheme: Theme,
    recipes: StyleSeriesRecipe[],
    seriesLength: number,
    prefix: string,
    reverse: boolean,
    gradient?: boolean
): string => {
    if (!mergedTheme || !recipes) {
        console.log('missing mergedTheme or recipe');
        return '';
    }

    const colorValues =
        seriesLength && generateColorPalette(mergedTheme, seriesLength, false, theme, gradient);
    const computedStyles =
        colorValues &&
        styleSeriesComputer(recipes, reverse ? colorValues.slice().reverse() : colorValues);

    return computedStyles && computedStylesArrayToString(computedStyles, { prefix });
};

const overwriteMerge = (destinationArray, sourceArray) => sourceArray;
const customMerge = (key: string) => {
    if (key === 'overrides') {
        return overwriteMerge;
    }
    if (key === 'colors') {
        return overwriteMerge;
    }
};
const mergeThemes = (...themes: Theme[]): Theme => {
    const scrubbedthemes = themes
        .filter(theme => {
            return !!theme;
        })
        .map((theme: Theme) => {
            return scrubber(toJS(theme));
        });

    return merge.all(scrubbedthemes, { customMerge });
};

const camelCaseToDash = (str): string => {
    return str.replace(/([a-zA-Z])(?=[A-Z])/g, '$1-').toLowerCase();
};

const computedStyleToString = (
    computedStyle: ComputedStyle,
    prefixConfig?: PrefixConfig
): string => {
    if (!Object.keys(computedStyle.styles).length) {
        return '';
    }

    const prefixValue = (prefixConfig && prefixConfig.prefix) || '';
    const prefixJoin =
        (prefixConfig && prefixConfig.join) || computedStyle.selector.includes('.widget_direction'); // widget direction is a class on the widget so we want to always "join"

    const styleMap = [];

    Object.keys(computedStyle.styles).forEach(style => {
        styleMap.push(`${camelCaseToDash(style)}: ${computedStyle.styles[style]}`);
    });

    const styles = styleMap.length && styleMap.reduce((a, b) => `${a}; ${b}`);
    const isFontFace = computedStyle.selector.includes('@font-face');

    return `${!isFontFace ? prefixValue : ''}${prefixJoin ? '' : ' '}${
        computedStyle.selector
    } { ${styles}; }`;
};

const computedStylesArrayToString = (
    computedStyles: ComputedStyle[],
    prefixConfig?: PrefixConfig
): string => {
    return computedStyles
        ? computedStyles
              .map(computedStyle => {
                  return computedStyleToString(computedStyle, prefixConfig);
              })
              .join('\n')
        : '';
};

const styler = (style: number, multiplier: number, unit: string, prefix: string) =>
    style !== null ? `${prefix} ${style * multiplier + unit}` : ``;

const imageFilterStyles = options => {
    const {
        imageFilterBlur,
        imageFilterBrightness,
        imageFilterContrast,
        imageFilterGrayscale,
        imageFilterSaturate,
        imageFilterHueRotate,
        imageFilterOpacity,
        imageFilterInvert,
        imageFilterSepia,
        imageFilterBlurValue,
        imageFilterBrightnessValue,
        imageFilterContrastValue,
        imageFilterGrayscaleValue,
        imageFilterSaturateValue,
        imageFilterHueRotateValue,
        imageFilterOpacityValue,
        imageFilterInvertValue,
        imageFilterSepiaValue,
    } = options;

    let styles = '';
    styles += imageFilterBlur ? `blur( ${imageFilterBlurValue}px) ` : '';
    styles += imageFilterBrightness ? `brightness(${imageFilterBrightnessValue}%) ` : '';
    styles += imageFilterContrast ? `contrast(${imageFilterContrastValue}%) ` : '';
    styles += imageFilterGrayscale ? `grayscale(${imageFilterGrayscaleValue}%) ` : '';
    styles += imageFilterSaturate ? `saturate(${imageFilterSaturateValue}%) ` : '';
    styles += imageFilterHueRotate ? `hue-rotate(${imageFilterHueRotateValue}deg) ` : '';
    styles += imageFilterOpacity ? `opacity(${imageFilterOpacityValue}%) ` : '';
    styles += imageFilterInvert ? `invert(${imageFilterInvertValue}%) ` : '';
    styles += imageFilterSepia ? `sepia(${imageFilterSepiaValue}%) ` : '';
    return styles;
};

const computeWidgetExtStyles = (
    ext?: Ext,
    headerHeight?: number,
    headerPadding?: number
): ComputedStyle[] => {
    let verticalPadding;
    const computedStyles: ComputedStyle[] = [];

    if (!ext) {
        if (headerHeight || headerPadding) {
            verticalPadding = headerHeight || headerPadding ? headerHeight + headerPadding * 4 : 0;
            computedStyles.push({
                selector: '',
                styles: {
                    paddingTop: styler(verticalPadding, 1, 'px', ''),
                },
            });
            return computedStyles;
        } else {
            return [];
        }
    }

    const { padding, margin } = ext;

    const resolvedPaddingTop = margin?.enabled ? margin.top : padding;
    const resolvedHeaderPadding =
        headerHeight + headerPadding * 4 + (margin?.enabled ? margin.top * 2 : 0);

    verticalPadding =
        headerHeight || headerPadding
            ? styler(resolvedHeaderPadding, 1, 'px', '')
            : styler(resolvedPaddingTop, 0.1, 'em', '');

    const resolvePadding = direction => {
        return margin?.enabled ? margin[direction] : padding;
    };

    if (padding !== null || verticalPadding !== null) {
        computedStyles.push({
            selector: '',
            styles: {
                paddingBottom: styler(resolvePadding('bottom'), 0.1, 'em', ''),
                paddingLeft: styler(resolvePadding('left'), 0.1, 'em', ''),
                paddingRight: styler(resolvePadding('right'), 0.1, 'em', ''),
                paddingTop: verticalPadding,
            },
        });
    }

    return computedStyles;
};

const computeWidgetLabelStyles = (label: WidgetLabelInterface): ComputedStyle[] => {
    if (!label) {
        return [];
    }

    const { size, textShadow } = label;
    const labelStyles: ComputedStyle[] = [];

    if (size === 0 || size !== null) {
        labelStyles.push({
            selector: '.widget_label',
            styles: {
                fontSize: styler(label.size, 0.1, 'em', ''),
            },
        });
    }

    if (textShadow?.enabled) {
        const { x, y, blur, color } = textShadow;
        labelStyles.push({
            selector: '.widget_label',
            styles: {
                textShadow: `${x}px ${y}px ${blur}px ${color}`,
            },
        });
    }

    return labelStyles;
};

const computeWidgetOptionStyles = (options: WidgetOptions, type: string): ComputedStyle[] => {
    const {
        metricPercentChangeSize,
        metricValueSize,
        metricNameSize,
        legendSize,
        secondLegend,
        opacity,
        locked,
        zIndex,
        labelSize,
        labelTimeRangeSize,
        styleMap,
        labelTextFormat,
        metricNameCase,
        themeEmailSize,
        useThemeEmail,
        metricPreviousValueSize,
    } = options;

    const computedStyles: ComputedStyle[] = [];

    const secondLegendSize = secondLegend?.legendSize;

    if (opacity !== null) {
        computedStyles.push({
            selector: '',
            styles: {
                opacity: styler(opacity, 0.01, '', ''),
            },
        });
    }

    // if hidden or locked
    if (opacity === 0 && type !== 'hyperlinkButton') {
        computedStyles.push({
            selector: '',
            styles: {
                pointerEvents: 'none',
            },
        });
    }

    if (zIndex !== null) {
        computedStyles.push({
            selector: '',
            styles: {
                zIndex: parseFloat(styler(zIndex, 1, '', '')),
            },
        });
    }

    if (labelSize === 0 || (labelSize && (!labelTextFormat || labelTextFormat !== 'fit'))) {
        computedStyles.push({
            selector: '.label_size',
            styles: {
                fontSize: styler(labelSize, 0.1, 'em', ''),
            },
        });
    }

    if (metricPercentChangeSize === 0 || metricPercentChangeSize) {
        computedStyles.push({
            selector: '.metric_ratio',
            styles: {
                fontSize: styler(metricPercentChangeSize, 0.1, 'em', ''),
            },
        });
    }

    if (metricPreviousValueSize === 0 || metricPreviousValueSize) {
        computedStyles.push({
            selector: '.metric_previous_value',
            styles: {
                fontSize: styler(metricPreviousValueSize, 0.1, 'em', ''),
            },
        });
    }

    if (metricNameSize === 0 || metricNameSize) {
        computedStyles.push({
            selector: '.metric_name_inner',
            styles: {
                fontSize: styler(metricNameSize, 0.1, 'em', ''),
            },
        });
    }

    if (metricValueSize === 0 || metricValueSize) {
        computedStyles.push({
            selector: '.metric_value_inner',
            styles: {
                fontSize: styler(metricValueSize, 0.1, 'em', ''),
            },
        });
    }

    if (legendSize === 0 || legendSize) {
        computedStyles.push(
            {
                selector: '.legend_value',
                styles: {
                    fontSize: styler(legendSize, 0.075, 'em', ''),
                },
            },
            {
                selector: '.legend_inner',
                styles: {
                    maxWidth: styler(legendSize, 1.5, '%', ''),
                },
            }
        );
    }

    if (secondLegendSize === 0 || secondLegendSize) {
        computedStyles.push(
            {
                selector: '.second_legend_value',
                styles: {
                    fontSize: styler(secondLegendSize, 0.075, 'em', ''),
                },
            },
            {
                selector: '.second_legend_inner',
                styles: {
                    maxWidth: styler(secondLegendSize, 1.5, '%', ''),
                },
            }
        );
    }

    if (labelTimeRangeSize) {
        computedStyles.push({
            selector: '.widget_label_sub',
            styles: {
                fontSize: styler(labelTimeRangeSize, 0.1, 'em', ''),
            },
        });
    }

    if (useThemeEmail) {
        computedStyles.push({
            selector: '.label_email',
            styles: {
                fontSize: styler(themeEmailSize, 0.05, 'em', ''),
            },
        });
    }

    if (metricNameCase) {
        computedStyles.push({
            selector: '.metric_name',
            styles: {
                textTransform: metricNameCase,
            },
        });
    }

    if (styleMap) {
        for (const key in styleMap) {
            if (key.indexOf('CUSTOM_FONT_FAMILY') !== -1 && styleMap[key].url) {
                const fontFamily = key.replace('CUSTOM_FONT_FAMILY_', '');
                const fontWeight = styleMap[key].fontWeight || '';
                const fontStyle = styleMap[key].fontStyle || '';

                computedStyles.push({
                    selector: `@font-face`,
                    styles: {
                        fontFamily: `"${fontFamily}"`,
                        src: `url('${styleMap[key].url}')`,
                        fontWeight: fontWeight,
                        fontStyle: fontStyle,
                    } as any,
                });
            }
        }
    }

    if (options.languageDirection === 'auto' || options.languageDirection === 'rtl') {
        computedStyles.push({
            selector: '.widget_direction_rtl',
            styles: {
                direction: 'rtl',
            },
        });
    }

    computedStyles.forEach(entry => {
        entry.styles = scrubber(entry.styles);
    });

    return computedStyles;
};

export function sanitizeCSSSelector(text: string, selectorPrefix = ''): string {
    // this case should never occur, but let's be safe anyway
    if (!text || text?.trim()?.length === 0) {
        return text;
    }

    let newText = text;

    // check if text has a comma
    if (text?.length > 0 && text.indexOf(',') > -1) {
        // split string, grab last item and trim it
        const lastItem = _last(text.split(',')).trim();
        // check if last item starts with a pseudo selector
        if (lastItem.startsWith(':') || lastItem.length === 0) {
            // this regex finds the last comma in the string and removes it
            newText = text.replace(/\,(?=[^,]*$)/g, '');
        }
    }

    const splits = newText.split(',');
    const splitsLastIndex = splits.length - 1;
    newText = splits.reduce((acc, curr, index) => {
        let currentText = curr.trim();

        // check if there is white space before a pseudo selector
        if (currentText.match(/\s:/g)) {
            // remove the whitespace before the colon
            currentText = currentText.replace(/\s(?=[^:.#]*:)/g, '');
        }

        // apply prefix and make it easier to read in the DOM
        if (currentText.length > 0) {
            const isLast = index === splitsLastIndex;
            const stringPrefix = index === 0 ? '' : '\n';
            const suffix = isLast ? '' : ',';
            acc += `${stringPrefix}${selectorPrefix}${currentText}${suffix}`;
        }
        return acc;
    }, '');

    return newText;
}

export const getCustomPanelCss = (widget: Widget, prefix: string) => {
    const parser = new shadyCss.Parser();
    const stringifier: any = new shadyCss.Stringifier();

    if (!widget || !widget.css) {
        return '';
    }

    try {
        const parsedCss = parser.parse(widget.css);

        parsedCss.rules.forEach(rule => {
            if (rule.type === 'ruleset') {
                if (
                    rule.selector.indexOf('.panel_') === 0 &&
                    rule.selector.indexOf('.panel_inner') !== 0
                ) {
                    rule.selector = sanitizeCSSSelector(rule.selector);
                } else if (
                    (rule.selector.indexOf('.panel') === 0 &&
                        rule.selector.indexOf('.panel_inner') !== 0) ||
                    rule.selector.indexOf('.template_') === 0
                ) {
                    rule.selector = sanitizeCSSSelector(rule.selector, prefix);
                } else {
                    rule.selector = sanitizeCSSSelector(rule.selector, `${prefix} `);
                }
            }
        });

        return stringifier.stringify(parsedCss);
    } catch (error) {
        console.error(error);
    }
};

export const getPanelCss = (
    panel: Panel,
    prefix: string,
    headerHeight?: number,
    headerPadding?: number
): string => {
    let panelCss = '';

    const panelThemeStyles = styleComputer(scrubber(panel.theme), null, 'panel');
    panelThemeStyles &&
        panelThemeStyles.forEach((style: ComputedStyle) => {
            panelCss += computedStyleToString(style, { prefix });
        });

    if (!panel.widget) {
        return panelCss;
    }

    const rootWidgetHeaderStyles = computeWidgetLabelStyles(panel.widget.label);
    rootWidgetHeaderStyles.forEach((style: ComputedStyle) => {
        panelCss += computedStyleToString(style, { prefix });
    });

    const rootWidgetExtStyles = computeWidgetExtStyles(
        panel.widget.theme?.ext || null,
        headerHeight,
        headerPadding
    );

    if (rootWidgetExtStyles && rootWidgetExtStyles.length > 0) {
        rootWidgetExtStyles.forEach((style: ComputedStyle) => {
            panelCss += computedStyleToString(style, { prefix });
        });
    }

    return panelCss;
};

export const getMergedChildWidgetTheme = (
    widget: Widget,
    parentTheme: Theme,
    hasChildren: boolean
): Theme => {
    const mergedInheritedTheme: Theme = mergeThemes(themeDefaultValues, parentTheme);

    if (!hasChildren) {
        // remove the inherited background image and color on widgets but preserve the values set to the widget explicitly
        mergedInheritedTheme.background.url = null;
        mergedInheritedTheme.background.color = null;
    }

    return merge(mergedInheritedTheme, scrubber(toJS(widget.theme)), { customMerge });
};

const hasSocialInDataResult = (dSet: DataSet) => {
    return (
        dSet &&
        dSet.dimensions.some(
            item =>
                item.name === 'LISTENING_MEDIA_TYPE' ||
                item.name === 'SN_TYPE' ||
                item.name === 'Source'
        )
    );
};

const hasSocialInDataRequest = (groupBys): boolean => {
    return groupBys.some(
        item => item.dimensionName === 'LISTENING_MEDIA_TYPE' || item.dimensionName === 'SN_TYPE'
    );
};

export const getChildWidgetCss = (
    widget: Widget,
    dataSet: DataSet,
    widgetConfig: WidgetConfig,
    parentTheme: Theme,
    prefix: string,
    measurements?: Measurements
): string => {
    // console.error("getChildWidgetCss");

    let widgetCss = '';

    const mergedTheme = getMergedChildWidgetTheme(
        widget,
        parentTheme,
        widget.children && widget.children.length > 0
    );
    // console.log("mergedTheme", mergedTheme);
    if (!mergedTheme.colorPalette || !mergedTheme.typography || !mergedTheme.background) {
        return widgetCss;
    }

    // console.log('widgetCss 0', widgetCss);

    // get the general styles based on options
    let widgetOptionStyles: ComputedStyle[] = computeWidgetOptionStyles(
        widget.options,
        widget.type
    );
    // console.log("widgetOptionStyles", widgetOptionStyles)

    if (!!widgetConfig.computeOptionStyles) {
        const optionStyles: ComputedStyle[] = widgetConfig.computeOptionStyles(
            widget.options,
            widget.theme,
            mergedTheme,
            measurements
        );
        scrubber(optionStyles);
        widgetOptionStyles = [...widgetOptionStyles, ...optionStyles];
    }

    const widgetLabelStyles = computeWidgetLabelStyles(widget.label);
    widgetOptionStyles = [...widgetOptionStyles, ...widgetLabelStyles];
    // console.log("widgetOptionStyles, post label", widgetOptionStyles);

    widgetOptionStyles &&
        widgetOptionStyles.forEach(entry => {
            entry.styles = scrubber(entry.styles);
        });

    const mergedThemeStyles = styleComputer(widget.theme, null, 'widget');
    widgetOptionStyles = mergedThemeStyles && [...mergedThemeStyles, ...widgetOptionStyles];

    widgetOptionStyles &&
        widgetOptionStyles.forEach((style: ComputedStyle) => {
            widgetCss += computedStyleToString(style, { prefix });
        });
    let seriesLength = dataSet && GetNumberOfDataItems(widget, dataSet);
    if (['twitterTrends', 'googleTrends'].includes(widgetConfig.id)) {
        seriesLength = widget.trendRequests?.[0]?.limit;
    }
    const recipes = widgetConfig.styleSeriesRecipe;

    const showSocialColors: boolean = widget.options.showSocialColors !== false;
    const { enabledSocialColorOverrides } = widget.options;
    const mergedIsSolidColor: boolean = mergedTheme?.colorPalette?.type === 'solid';
    const mergedHasOneRankedColor: boolean =
        mergedTheme?.colorPalette?.type === 'ranked' &&
        mergedTheme?.colorPalette?.ranked?.colors?.length === 1;
    const widgetHasOneRankedColor: boolean =
        widget.theme?.colorPalette?.type === 'ranked' &&
        widget.theme?.colorPalette?.ranked?.colors?.length === 1;
    const widgetIsRanked: boolean =
        widget.theme?.colorPalette?.type === 'ranked' && !!mergedTheme.colorPalette.solid;

    const renderSocialColors: boolean =
        (mergedIsSolidColor ||
            mergedHasOneRankedColor ||
            widgetHasOneRankedColor ||
            widgetIsRanked) &&
        hasSocialInDataResult(dataSet) &&
        showSocialColors;

    if (recipes && mergedTheme) {
        let reverse = false;
        if (widgetConfig.reverseSeries) {
            reverse = true;
        }

        widgetCss += getStyleSeriesCss(
            widget.theme,
            mergedTheme,
            recipes,
            seriesLength,
            prefix,
            reverse
        );
    }

    if (widgetConfig.getStyles) {
        widgetCss += widgetConfig.getStyles(mergedTheme, dataSet, widget, prefix, measurements);
    }

    if (renderSocialColors || enabledSocialColorOverrides) {
        widgetCss +=
            '\n' +
            computedStylesArrayToString(styleLabelComputer(recipes, socialColors), { prefix });
    }

    widgetCss +=
        '\n' +
        getColorOverridesCss({
            enforceColorOverrides: widget.options.enforceColorOverrides,
            mergedTheme,
            prefix,
            recipes,
        });

    return widgetCss;
};

const getUrlDimensions = (url: string): Dimensions => {
    const dimensions = url.split('?dimensions')[1];
    if (dimensions) {
        const result = dimensions.split('/').slice(1, 3);
        const width: number = parseInt(result[0], 10);
        const height: number = parseInt(result[1], 10);
        return { height, width };
    } else if (url.indexOf('?url=') > 0 && decodeURIComponent(url).indexOf('?dimensions/') > 0) {
        // for "secure assets", see DISPLAY-4728 etc.
        const decoded = decodeURIComponent(url);
        const dims = decoded.split('?dimensions')[1];
        const result = dims.split('/').slice(1, 3);
        const width: number = parseInt(result[0], 10);
        const height: number = parseInt(result[1], 10);
        return { height, width };
    } else {
        return { width: 0, height: 0 };
    }
};

export const getLayoutBounds = (screens: Screens): Dimensions => {
    const widthMap = screens.map(screen => screen.w + screen.x);
    const heightMap = screens.map(screen => screen.h + screen.y);
    const width = Math.max(...widthMap);
    const height = Math.max(...heightMap);
    return { width, height };
};

const getOffsets = (
    screens: Screens,
    layoutBounds: Dimensions,
    width: number,
    height: number
): Offsets => {
    const screenMap: Offsets = screens.map((screen, index) => {
        const top: number = (layoutBounds.height - height) / 2 - screen.y;
        const left: number = (layoutBounds.width - width) / 2 - screen.x;

        return { top, left };
    });
    return screenMap;
};

const getRatios = (screens: Screens, url) => {
    const imageSize: Dimensions = getUrlDimensions(url);
    const layoutBounds: Dimensions = getLayoutBounds(screens);

    const layoutRatio: number = layoutBounds.width / layoutBounds.height;
    const imgRatio: number = imageSize.width / imageSize.height;
    return { layoutRatio, imgRatio, layoutBounds, imageSize };
};

const getBackgroundSize = (
    layoutRatio: number,
    imgRatio: number,
    layoutBounds: Dimensions,
    mode: BackgroundSize,
    scale?: number
): Dimensions => {
    let height = 0;
    let width = 0;

    const minBounds: number = Math.min(layoutBounds.width, layoutBounds.height);
    const maxBounds: number = Math.min(layoutBounds.width, layoutBounds.height);
    const scaleRatio = 0.005;
    if (mode === 'cover') {
        if (imgRatio > layoutRatio) {
            height = layoutBounds.height;
            width = height * imgRatio;
        } else {
            width = layoutBounds.width;
            height = width / imgRatio;
        }
    } else if (mode === 'contain') {
        if (imgRatio > layoutRatio) {
            height = Math.floor(((minBounds - maxBounds) / imgRatio) * 2);
            width = height * imgRatio;
        } else {
            width = Math.floor(minBounds * imgRatio);
            height = width / imgRatio;
        }
    } else {
        const repeatScale = mode && parseInt(mode.replace('%', ''), 10);
        if (imgRatio > layoutRatio) {
            height = Math.floor(maxBounds * (repeatScale || 100) * scaleRatio);
            width = height * imgRatio;
        } else {
            width = Math.floor(maxBounds * (repeatScale || 100) * scaleRatio);
            height = width / imgRatio;
        }
    }

    const dimensions: Dimensions = {
        width,
        height,
    };

    return dimensions;
};

export const getMergedTextColor = (theme: Theme, inherited: Theme): string => {
    return (
        (theme && theme.typography && theme.typography.color) ||
        (inherited && inherited.typography && inherited.typography.color)
    );
};

export const getPostMergedColors = (theme: Theme, inherited: Theme): PostMergedColors => {
    const mergedTextColor =
        (theme && theme.typography && theme.typography.color) ||
        (inherited && inherited.typography && inherited.typography.color);
    const type = inherited.colorPalette && inherited.colorPalette.type;
    const overlayColor =
        (theme && theme.background && theme.background.color) ||
        (inherited && inherited.background && inherited.background.color);

    const merged: PostMergedColors = {
        textColor: mergedTextColor,
        overlayColor,
        textOnlyBackgroundColor: undefined,
        imagePostBackgroundColor: undefined,
        imageBlendColor: undefined,
    };

    // Fall back to default theme if there's no
    const inheritedPalette =
        (type && inherited.colorPalette && inherited.colorPalette[type]) ||
        builtInThemes[0].colorPalette[type];
    if (type === 'colorBlend') {
        merged.textOnlyBackgroundColor = inheritedPalette.startColor;
        merged.imagePostBackgroundColor = inheritedPalette.endColor;
        merged.imageBlendColor = inheritedPalette.endColor;
    } else if (type === 'monochrome') {
        merged.textOnlyBackgroundColor = inheritedPalette.startColor;
        const secondColor = Color(inheritedPalette.startColor.trim())
            .darken(inheritedPalette.endOffset * 0.01)
            .hex();
        merged.imagePostBackgroundColor = secondColor;
        merged.imageBlendColor = secondColor;
    } else if (type === 'solid') {
        merged.textOnlyBackgroundColor = inheritedPalette;
        merged.imagePostBackgroundColor = inheritedPalette;
        merged.imageBlendColor = inheritedPalette;
    } else if (type === 'ranked' && inheritedPalette?.colors?.length) {
        merged.textOnlyBackgroundColor = inheritedPalette.colors[0];
        merged.imagePostBackgroundColor = inheritedPalette.colors[1] || inheritedPalette.colors[0];
        merged.imageBlendColor = inheritedPalette[1] || inheritedPalette[0];
    }
    return merged;
};

export function getMergedBackgroundColor(mergedTheme): string {
    const type = mergedTheme.colorPalette.type;
    let bgColor: string;

    switch (type) {
        case 'colorBlend':
            bgColor = mergedTheme.colorPalette.colorBlend.startColor;
            break;
        case 'monochrome':
            bgColor = mergedTheme.colorPalette.monochrome.startColor;
            break;
        case 'solid':
            bgColor = mergedTheme.colorPalette.solid;
            break;
        case 'ranked':
            bgColor = mergedTheme.colorPalette.ranked && mergedTheme.colorPalette.ranked.colors[0];
            break;
        default:
            break;
    }

    return Color(bgColor)
        .darken(0.1)
        .hex();
}

export {
    scrubber,
    styleComputer,
    styleSeriesComputer,
    computedStyleToString,
    computedStylesArrayToString,
    camelCaseToDash,
    mergeThemes,
    getStyleSeriesCss,
    computeWidgetExtStyles,
    computeWidgetOptionStyles,
    computeWidgetLabelStyles,
    styler,
    imageFilterStyles,
    borderStyles,
    fadedFontColor,
    hasSocialInDataResult,
    hasSocialInDataRequest,
};
