import { StatusTag } from '@sprinklr/display-builder/widgets/utils/getStatusTag';

type LegacyColorTag = {
    value: number | null | Duration;
    comparison: '>' | '<' | '===' | '>=' | '<=';
    color: string;
};
export function convertMetricDesignOptions(metricDesignOptions: {
    [key: string]: Record<string, any> & {
        statusTags: LegacyColorTag[];
    };
}): {
    [key: string]: Record<string, any> & {
        statusTags: StatusTag[];
    };
} {
    const newDesignOptions = JSON.parse(JSON.stringify(metricDesignOptions));

    // map over each metric
    Object.keys(newDesignOptions).forEach(key => {
        let legacyColorTags: boolean;
        // if the metric has color tags
        if (newDesignOptions[key].colorTags?.length > 0) {
            // map over each color tag
            newDesignOptions[key].colorTags?.forEach(colorTag => {
                // if the color tag has no conditions, convert it to the new format (these are the legacy color tags)
                if (!colorTag.conditions || colorTag.conditions?.length === 0) {
                    colorTag.conditions = [{ ...colorTag, name: key, type: 'METRIC' }];
                    delete colorTag.comparison;
                    delete colorTag.value;
                    colorTag.type = 'color';
                    colorTag.animation = {
                        enabled: false,
                        duration: 2500,
                    };
                    legacyColorTags = true;
                    // if the color tag has no type, add type 'color' (these are the interim colorTags before conversion to status tags)
                } else if (!colorTag.type) {
                    colorTag.type = 'color';
                    colorTag.animation = {
                        enabled: false,
                        duration: 2500,
                    };
                }
            });
            // the legacy color tags need to have their order reversed to work with the new format
            const newStatusTags = legacyColorTags
                ? newDesignOptions[key].colorTags.reverse()
                : newDesignOptions[key].colorTags;
            delete newDesignOptions[key].colorTags;
            newDesignOptions[key].statusTags = newStatusTags;
        }
    });

    return newDesignOptions;
}
