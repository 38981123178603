/* tslint:disable:no-empty */
import { VideoPlayer } from './Video';
import { VideoFacebook } from './VideoFacebook';
import { VideoJs } from './VideoJs';
// import { VideoVimeo } from './VideoVimeo'
import { VideoYoutube } from './VideoYoutube';
import { VideoTwitch } from './VideoTwitch';

class VideoService {
    private muteVideos = true;

    constructor() {}

    areVideosMuted(): boolean {
        return this.muteVideos;
    }

    // This allows all video players created from the service to be muted
    setVideosMuted(muted: boolean) {
        this.muteVideos = muted;
    }

    get(type: string): VideoPlayer {
        switch (type) {
            // -------------------------------------------------------------------
            //  Facebook video support
            // -------------------------------------------------------------------
            case 'facebook':
                return new VideoFacebook(this.muteVideos);

            // -------------------------------------------------------------------
            //  Vimeo video support - Note: Sprinklr doesn't support Vimeo apparently
            // -------------------------------------------------------------------
            // case "vimeo":
            //     return new VideoVimeo();

            // -------------------------------------------------------------------
            //  Twitch video support
            // -------------------------------------------------------------------
            case 'twitch':
                return new VideoTwitch(this.muteVideos);

            // -------------------------------------------------------------------
            //  YouTube video support
            // -------------------------------------------------------------------
            case 'youtube':
                return new VideoYoutube(this.muteVideos);

            // -------------------------------------------------------------------
            //  Vine, Instagram or Twitter MP4 video support
            // -------------------------------------------------------------------
            default:
                return new VideoJs(this.muteVideos);
        }
    }
}

const singleton = new VideoService();
export default singleton;
