import { Mapper } from '../DataStore/DataStore';
import { GraphQLService } from '../GraphQLService/GraphQLService';
import {
    ClientMutationId,
    GraphQLQueries,
    ModelName,
    QueryDescriptor,
} from '../GraphQLQueries/GraphQLQueries';
import { BaseRecordService } from 'utils/BaseRecordService/BaseRecordService';
import {
    DisplayUseCase,
    Location,
    LocationAndHmacKeyFields,
    LocationFields,
    LocationLight,
    LocationLightFields,
    LocationLightFieldsIncludeAdHocStoryboards,
    LocationLightFrags,
    LocationSchedule,
    Remote,
} from 'models/Location/Location';
import { Storyboard } from 'models/Storyboard/Storyboard';
import { action } from 'mobx';
import { WidgetFragments } from 'models/Widget/Widget';
import { ThemeFragment, ThemeSnippet } from 'models/Theme/Theme';
import i18n from 'i18next';
import { HmacKey } from 'models/SharedSecretKey/SharedSecretKey';
import { DataSourceFragment } from 'models/DataSource/DataSource';
import { CommonDocFields } from 'models/CommonDoc/CommonDoc';
import { SceneLightFields } from 'models/Scene/Scene';
import { VersionCapableFields } from 'models/VersionCapable/VersionCapable';
import { SceneVersionLightFields } from 'models/Scene/SceneVersion';
import { LayoutFields } from 'models/Layout/Layout';
import { IdentifiedModel } from '@sprinklr/stories/common/IdentifiedModel';

const MODELNAME: ModelName = 'location';

/**
 * Created by dstelter on 12/13/16.
 */
export class DisplayService extends BaseRecordService<Location> {
    private queries: GraphQLQueries;

    constructor(graphQL: GraphQLService, mapper: Mapper<Location>) {
        super(graphQL, mapper);
        this.queries = new GraphQLQueries();
    }

    find(id: string): Promise<Location> {
        const queryDescriptor: QueryDescriptor = {
            queryParams: {
                query: `
                    query RetrieveLocation($id: ID!) {
                        location(id: $id) {
                            ${LocationFields}
                        }
                    }
                    ${WidgetFragments}
                    ${DataSourceFragment}
                    ${ThemeFragment}`,
                variables: { id },
            },
            extractor: result => result[MODELNAME],
        };

        return this.query(queryDescriptor);
    }

    /**
     * Note that the first parameter, location is only used to identify the location record to patch. The second param,
     * patch, contains the fields to update.
     *
     * @param location id or object
     * @param patch fields to update
     * @returns {Promise<boolean|Location>}
     */
    update(location: IdentifiedModel | string, patch: Location): Promise<Location> {
        const locationId = this.locationId(location);

        return this.mutate(
            this.queries.mutationDescriptor(
                'patchLocation',
                MODELNAME,
                // note we're supplying the layoutId outside the location object
                { id: locationId, location: patch }
            )
        );
    }

    create(location: Location): Promise<Location> {
        const layoutId = !!location.layout ? location.layout.id : null;

        // detect DisplayType based on layout screen count
        if (!location.displayType && location.layout) {
            if (location.layout.screens && location.layout.screens.length > 1) {
                location.displayType = 'DISPLAY_WALL';
            } else {
                location.displayType = 'SINGLE_DISPLAY';
            }
        }

        return this.mutate(
            {
                name: 'createLocation',
                queryParams: {
                    query: `mutation newLocation($input: CreateLocationInput!) {
                    createLocation(input: $input) {
                        location {
                            ${LocationLightFields}
                        }
                        clientMutationId
                    }
                }
                ${LocationLightFrags}`,
                    variables: { input: { location, layoutId } }, // note we're supplying the layoutId outside the location object
                },
                extractor: r => r.createLocation.location,
            },
            { rejectOnError: true }
        );
    }

    destroy(location: IdentifiedModel | string): Promise<boolean> {
        return this.mutate(
            this.queries.mutationDescriptor('deleteLocation', MODELNAME, {
                id: this.locationId(location),
            })
        ).then(() => {
            return true;
        });
    }

    restore(locationId: string): Promise<Location> {
        return this.mutate(
            {
                name: 'restoreLocation',
                queryParams: {
                    query: `mutation RestoreLocation($input: RestoreLocationInput!) {
                    restoreLocation(input: $input) {
                        location {
                            ${LocationLightFields}
                        }
                        clientMutationId
                    }
                }
                ${LocationLightFrags}`,
                    variables: { input: { id: locationId } },
                },
                extractor: r => r.restoreLocation.location,
            },
            { rejectOnError: true }
        );
    }

    private queryAllLight(query: QueryDescriptor): Promise<LocationLight[]> {
        // console.log("query got query ", query);
        return this.graphQL
            .query(query.queryParams)
            .then(result => result.client.locations)
            .then(result => {
                if (!result || typeof result !== 'object') {
                    const variables =
                        query.queryParams && query.queryParams.variables
                            ? ` with params: ${JSON.stringify(query.queryParams.variables)}`
                            : '';
                    const message = `Failed to load ${this.mapper.name}s${variables}`;
                    console.error(message);
                    // return Promise.reject(message) as any;
                }
                return result;
            });
    }

    findAllLight(): Promise<LocationLight[]> {
        const query: QueryDescriptor = {
            name: 'clientLocationsLight',
            queryParams: {
                query: this.queries.clientLocationsLightQuery,
                variables: {},
            },
            model: MODELNAME,
        };

        return this.queryAllLight(query);
    }

    findAllLightIncludeAdHocStoryboards(): Promise<LocationLight[]> {
        const query: QueryDescriptor = {
            name: 'clientLocationsLight',
            queryParams: {
                query: `
            {
                client {
                    locations {
                        ${LocationLightFieldsIncludeAdHocStoryboards}
                    }
                }
            }
            ${LocationLightFrags}
            `,
                variables: {},
            },
            model: MODELNAME,
        };

        return this.queryAllLight(query);
    }

    findAllSchedules(): Promise<LocationSchedule[]> {
        const query: QueryDescriptor = {
            name: 'clientLocationSchedules',
            queryParams: {
                query: this.queries.locationSchedulesQuery,
                variables: {},
            },
            model: MODELNAME,
        };

        // console.log("query got query ", query);
        return this.graphQL
            .query(query.queryParams)
            .then(result => result.client.locations)
            .then(result => {
                if (!result || typeof result !== 'object') {
                    const variables =
                        query.queryParams && query.queryParams.variables
                            ? ` with params: ${JSON.stringify(query.queryParams.variables)}`
                            : '';
                    const message = `Failed to load ${this.mapper.name}s${variables}`;
                    console.error(message);
                    // return Promise.reject(message) as any;
                }
                return result;
            });
    }

    findAll(query?: any): Promise<Location[]> {
        const queryDescriptor: QueryDescriptor = {
            name: 'clientLocations',
            queryParams: {
                query: this.queries.clientLocationsQuery,
                variables: query || {},
            },
            model: MODELNAME,
            extractor: result => result.client.locations,
        };

        return this.queryMany(queryDescriptor);
    }

    countAll(): Promise<{ locationCount: number }> {
        return this.graphQL
            .query({ query: '{client{locationCount}}' })
            .then(response => response.client);
    }

    rename(location: IdentifiedModel | string, name: string): Promise<Location> {
        return this.mutate(
            this.queries.mutationDescriptor('renameLocation', MODELNAME, {
                locationId: this.locationId(location),
                name,
            })
        );
    }

    setRemoteCss(location: IdentifiedModel | string, remote: Remote): Promise<Location> {
        return this.mutate(
            this.queries.mutationDescriptor('setLocationRemoteCustomCSS', MODELNAME, {
                locationId: this.locationId(location),
                remote,
            })
        );
    }

    setActiveStoryboard(
        location: IdentifiedModel | string,
        storyboard: IdentifiedModel | string,
        skipPublish?: boolean
    ): Promise<Location> {
        return this.mutate(
            this.queries.mutationDescriptor('setLocationActiveStoryboardId', MODELNAME, {
                locationId: this.locationId(location),
                storyboardId: this.storyboardId(storyboard),
                skipPublish: !!skipPublish,
            })
        );
    }

    setPausedAtIndex(
        location: IdentifiedModel,
        pausedAtIndex: number,
        startTime?: number
    ): Promise<Location> {
        const mutation = this.queries.mutationDescriptor('setLocationPausedAtIndex', MODELNAME, {
            locationId: this.locationId(location),
            pausedAtIndex,
            startTime: typeof startTime === 'number' ? startTime : 0,
        });

        return this.graphQL
            .mutate(mutation.queryParams)
            .then(mutation.extractor)
            .then(
                action(result => {
                    for (const key in result) {
                        location[key] = result[key];
                    }

                    return location;
                })
            );
    }

    addStoryboard(
        location: IdentifiedModel | string,
        storyboard: IdentifiedModel | string,
        skipPublish?: boolean
    ): Promise<Location> {
        return this.mutate({
            queryParams: {
                // TODO: this could be lightened, but the ScenePreview in the sidebar is expecting a LOT of stuff that's
                // probably possible to do without, including storyboard.activeVersion.firstScene.theme etc.
                // I think all we REALLY need for this mutation is each Storyboard's layout, first scene with panel
                // previews and a little metadata like IDs and timestamps.
                query: `
                mutation ($input: AddLocationStoryboardIdInput!) {
                    addLocationStoryboardId(input: $input) {
                        ${ClientMutationId}
                        location {
                            ${CommonDocFields}
                            layout {
                                ${LayoutFields}
                            }
                            activeStoryboardId
                            storyboards {
                                ${CommonDocFields}
                                layout {
                                    ${LayoutFields}
                                }
                                firstScene {
                                    ${SceneLightFields}
                                    ${ThemeSnippet}
                                }
                                sceneCount
                                activeVersion {
                                    ${CommonDocFields}
                                    ${VersionCapableFields}
                                    firstScene {
                                        ${SceneVersionLightFields}
                                        ${ThemeSnippet}
                                    }
                                    sceneCount
                                    ${ThemeSnippet}
                                }
                            }
                            lastUserActiveStoryboardChange
                        }
                    }
                }
                ${ThemeFragment}
                `,
                variables: {
                    input: {
                        locationId: this.locationId(location),
                        storyboardId: this.storyboardId(storyboard),
                        skipPublish: !!skipPublish,
                    },
                },
            },
            extractor: result => result.addLocationStoryboardId.location,
        });
    }

    removeStoryboard(
        location: IdentifiedModel | string,
        storyboard: IdentifiedModel | string
    ): Promise<Location> {
        return this.mutate(
            this.queries.mutationDescriptor('removeLocationStoryboardId', MODELNAME, {
                locationId: this.locationId(location),
                storyboardId: this.storyboardId(storyboard),
            })
        );
    }

    setSharedWithUserIds(
        location: IdentifiedModel | string,
        userIds: number[],
        groupIds: string[],
        restricted: boolean
    ) {
        return this.mutate(
            this.queries.mutationDescriptor('setLocationSharedWithUserIds', MODELNAME, {
                locationId: this.locationId(location),
                sharedWithUserIds: userIds,
                sharedWithGroupIds: groupIds,
                restricted,
            })
        );
    }

    enableEventMode(location: IdentifiedModel | string): Promise<Location> {
        return this.mutate({
            queryParams: {
                query: `mutation EnableLocationEventMode($input: EnableLocationEventModeInput!) {
                    enableLocationEventMode(input: $input) {
                        location {
                            ${LocationFields}
                        }
                        ${ClientMutationId}
                    }
                }
                ${WidgetFragments}
                ${DataSourceFragment}
                ${ThemeFragment}
                `,
                variables: {
                    input: {
                        locationId: this.locationId(location),
                    },
                },
            },
            extractor: result => result.enableLocationEventMode.location,
        });
    }

    extendEventMode(location: IdentifiedModel | string, endDate: number): Promise<Location> {
        return this.mutate({
            queryParams: {
                query: `mutation ExtendLocationEventMode($input: ExtendLocationEventModeInput!) {
                    extendLocationEventMode(input: $input) {
                        location {
                            ${LocationFields}
                        }
                        ${ClientMutationId}
                    }
                }
                ${WidgetFragments}
                ${DataSourceFragment}
                ${ThemeFragment}
                `,
                variables: {
                    input: {
                        locationId: this.locationId(location),
                        endDate,
                    },
                },
            },
            extractor: result => result.extendLocationEventMode.location,
        });
    }

    enableOnDemandPublishMode(location: IdentifiedModel | string): Promise<Location> {
        return this.mutate({
            queryParams: {
                query: `mutation EnableLocationOnDemandPublishMode($input: EnableLocationOnDemandPublishModeInput!) {
                    enableLocationOnDemandPublishMode(input: $input) {
                        location {
                            ${LocationFields}
                        }
                        ${ClientMutationId}
                    }
                }
                ${WidgetFragments}
                ${DataSourceFragment}
                ${ThemeFragment}
                `,
                variables: {
                    input: {
                        locationId: this.locationId(location),
                    },
                },
            },
            extractor: result => result.enableLocationOnDemandPublishMode.location,
        });
    }

    removePublishingOverrides(location: IdentifiedModel | string): Promise<Location> {
        return this.mutate({
            queryParams: {
                query: `mutation RemoveLocationPublishingOverrides($input: RemoveLocationPublishingOverridesInput!) {
                    removeLocationPublishingOverrides(input: $input) {
                        location {
                            ${LocationFields}
                        }
                        ${ClientMutationId}
                    }
                }
                ${WidgetFragments}
                ${DataSourceFragment}
                ${ThemeFragment}
                `,
                variables: {
                    input: {
                        locationId: this.locationId(location),
                    },
                },
            },
            extractor: result => result.removeLocationPublishingOverrides.location,
        });
    }

    /**
     * @param location
     * @param screenNumber
     */
    locationUrl(location: Location, screenNumber = 1) {
        const port = window.location.port;
        const host =
            window.location.protocol +
            '//' +
            window.location.hostname +
            (!port || port === '80' || port === '443' ? '' : ':' + port);
        const locationId = location.shortId ? location.shortId : location.id;

        const urlString = location.shortId
            ? `${host}/${location.clientId}/${locationId}`
            : `${host}/external/${location.clientId}/${locationId}`;

        const scrNo = screenNumber ? screenNumber : 1;

        return `${urlString}/${scrNo}`;
    }

    activateHmacKey(location: IdentifiedModel | string, hmacKeyId: string): Promise<Location> {
        return this.mutate({
            queryParams: {
                query: `mutation ActivateLocationHmacKey($input: ActivateLocationHmacKeyInput!) {
                    activateLocationHmacKey(input: $input) {
                        location {
                            ${LocationAndHmacKeyFields}
                        }
                        ${ClientMutationId}
                    }
                }
                `,
                variables: {
                    input: {
                        locationId: this.locationId(location),
                        hmacKeyId,
                    },
                },
            },
            extractor: result => result.activateLocationHmacKey.location,
        });
    }

    deactivateHmacKey(location: IdentifiedModel | string, hmacKeyId: string): Promise<Location> {
        return this.mutate({
            queryParams: {
                query: `mutation DeactivateLocationHmacKey($input: DeactivateLocationHmacKeyInput!) {
                    deactivateLocationHmacKey(input: $input) {
                        location {
                            ${LocationAndHmacKeyFields}
                        }
                        ${ClientMutationId}
                    }
                }
                `,
                variables: {
                    input: {
                        locationId: this.locationId(location),
                        hmacKeyId,
                    },
                },
            },
            extractor: result => result.deactivateLocationHmacKey.location,
        });
    }

    removeHmacKey(location: IdentifiedModel | string, hmacKeyId: string): Promise<Location> {
        return this.mutate({
            queryParams: {
                query: `mutation RemoveLocationHmacKey($input: RemoveLocationHmacKeyInput!) {
                    removeLocationHmacKey(input: $input) {
                        location {
                            ${LocationAndHmacKeyFields}
                        }
                        ${ClientMutationId}
                    }
                }
                `,
                variables: {
                    input: {
                        locationId: this.locationId(location),
                        hmacKeyId,
                    },
                },
            },
            extractor: result => result.removeLocationHmacKey.location,
        });
    }

    renameHmacKey(
        location: IdentifiedModel | string,
        hmacKeyId: string,
        name: string
    ): Promise<Location> {
        return this.mutate({
            queryParams: {
                query: `mutation RenameLocationHmacKey($input: RenameLocationHmacKeyInput!) {
                    renameLocationHmacKey(input: $input) {
                        location {
                            ${LocationAndHmacKeyFields}
                        }
                        ${ClientMutationId}
                    }
                }
                `,
                variables: {
                    input: {
                        locationId: this.locationId(location),
                        hmacKeyId,
                        name,
                    },
                },
            },
            extractor: result => result.renameLocationHmacKey.location,
        });
    }

    generateHmacKey(
        location: IdentifiedModel | string,
        key: any,
        url?: string
    ): Promise<LocationAndHmacKey> {
        const signedUrlChunk = url ? `signedUrl(url:"${url}")\n` : '';

        return this.mutateComplexResult({
            queryParams: {
                query: `mutation GenerateLocationHmacKey($input: GenerateLocationHmacKeyInput!) {
                    generateLocationHmacKey(input: $input) {
                        location {
                            ${LocationFields}
                        }
                        hmacKey {
                            id secret
                        }
                        ${signedUrlChunk}
                        ${ClientMutationId}
                    }
                }
                ${WidgetFragments}
                ${DataSourceFragment}
                ${ThemeFragment}
                `,
                variables: {
                    input: {
                        locationId: this.locationId(location),
                        sharedSecret: key,
                    },
                },
            },
            extractor: result => ({
                location: result.generateLocationHmacKey.location,
                hmacKey: result.generateLocationHmacKey.hmacKey,
                signedUrl: result.generateLocationHmacKey.signedUrl,
            }),
        });
    }

    setDisplayPassword(location: IdentifiedModel, password: string): Promise<Location> {
        return this.mutate(
            this.queries.mutationDescriptor('setLocationPassword', MODELNAME, {
                locationId: this.locationId(location),
                password,
            })
        );
    }
}

export interface LocationAndHmacKey {
    location: Location;
    hmacKey: HmacKey;
    signedUrl?: string;
}

export const getUseCaseName = (displayUseCase: DisplayUseCase): string => {
    switch (displayUseCase) {
        case 'COMMAND_CENTER':
            return i18n.t('Command Center');
        case 'RETAIL':
            return i18n.t('Retail');
        case 'LIVE_EVENT':
            return i18n.t('Live Event');
        case 'LOBBY':
            return i18n.t('Lobby');
        case 'PUBLIC_EMBED':
            return i18n.t('Public Embed');
        case 'DOOH':
            return i18n.t('DooH');
        default:
            break;
    }
};
