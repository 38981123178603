import { CommonDocFields } from '../CommonDoc/CommonDoc';
import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';
import { observable } from 'mobx';

export interface UserAsset extends CommonDoc {
    mediaType?: string;
    size?: number;
    contentUrl?: string;
    uploadUrl?: string;
    storyboardId?: string;
    panelId?: string;
}

export class UserAssetImpl implements UserAsset {
    @observable clientId?: number;
    @observable created?: number;
    @observable description?: string;
    @observable id?: string;
    @observable mediaType?: string;
    @observable name?: string;
    @observable partnerId?: number;
    @observable size?: number;
    @observable updated?: number;
    @observable userUpdated?: number;
    @observable contentUrl?: string;
    @observable uploadUrl?: string;
    @observable storyboardId?: string;
    @observable panelId?: string;
}

export const UserAssetFields = `
    ${CommonDocFields}
    mediaType
    size
    contentUrl
    uploadUrl
    storyboardId
    panelId
`;
