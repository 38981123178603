import {
    computedStylesArrayToString,
    styleLabelComputer,
} from 'utils/GenerateStyles/GenerateStyles';

export function getColorOverridesCss({ mergedTheme, recipes, prefix, enforceColorOverrides }) {
    if (recipes && mergedTheme?.colorPalette?.overrides?.length > 0) {
        const colorMap: { [label: string]: string } = mergedTheme.colorPalette.overrides
            .filter(override => override.color && override.label)
            .reduce((acc, override) => {
                acc[override.label] = `${override.color}${
                    enforceColorOverrides ? '!important' : ''
                }`;
                return acc;
            }, {});

        return computedStylesArrayToString(styleLabelComputer(recipes, colorMap), { prefix });
    } else {
        return '';
    }
}
