import * as React from 'react';
import styled, { css, ColorScheme } from 'components/_UI/designSystem';
import styledNormalize from 'styled-normalize';
import { createGlobalStyle } from 'styled-components';
import { styledAsyncSelect } from 'components/_UI/spaceweb/AsyncSelect/styles';
import { FALLBACK_FONT_STACK } from '../Fonts/constants';

export type InjectGlobalStylesProps = {
    theme?: ColorScheme;
    children?: React.ReactChild;
};

const GlobalStyle = createGlobalStyle`
    ${styledNormalize}
    ${({ theme }: InjectGlobalStylesProps) => css`
        body {
            font-family: ${FALLBACK_FONT_STACK};
        }
        .vr {
            border: 1px solid ${theme && theme.border};
        }
        .builder {
            background-color: ${theme && theme.background};
        }
        .header,
        .scene__strip {
            border-color: ${theme && theme.separator.default.border};
        }

        .highlight,
        .swatch-icon.channel--inherit-theme {
            background-color: ${theme && theme.background};
        }
        .layout_preview_screen {
            color: ${theme && theme.separator.default.border};
            background: ${theme && theme.midground};
            user-select: none;
            :not(&--borderless) {
                border: 1px solid ${theme && theme.layoutPreview.default.border};
            }
        }

        .user__switcher,
        .user__logout {
            border-top: 1px solid ${theme && theme.separator.default.border};
            background: none;
        }
        .ui__text,
        .collapse-group_header .ui__subheader,
        .collapse-group--open .collapse-group_header .ui__subheader {
            color: ${theme && theme.text.default.color};
        }

        .sidebar__item,
        .panel__history-wrapper,
        .sidebar__tool,
        .color-tile__swatch,
        .image-swatch .circle,
        .bt-1,
        .bb-1 {
            border-color: ${({ theme }: InjectGlobalStylesProps) =>
                theme && theme.sidebar.sidebarTool.default.border};
        }

        .panel__layers_item {
            z-index: 100;
            padding: 0.25em 0.5em;
            &.active {
                background-color: ${({ theme }: InjectGlobalStylesProps) =>
                    theme && theme.layerList.default.background};
            }
            &.dragging {
                background-color: ${({ theme }: InjectGlobalStylesProps) =>
                    theme && theme.layerList.active.background};
                padding: 0.25em 0.53em;
                h2 {
                    text-transform: capitalize;
                }
            }
        }
        .fonts_selector {
            color: ${theme && theme.colors.text};
            font-size: 13px;
        }

        textarea {
            font-size: 12px;
        }

        .scene-editor {
            background-color: ${theme && theme.editor.background};
        }
    `}
    .theme__swatch-wrapper,
    .color-tile__swatch,
    .image-swatch__group {
        font-size: 12px;
    }

    ${styledAsyncSelect}

`;

export default GlobalStyle;
