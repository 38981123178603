/**
 * Created by dstelter on 9/13/16.
 */
import { AxiosInstance, AxiosResponse } from 'axios';
// import { UUID } from "angular2-uuid";

export interface GraphQLQuery {
    query: string;
    variables?: any;
}

export interface GraphQLError {
    errorType: string;
    locations: Array<{ line: number; column: number }>;
    message: string;
    validationErrorType: string;
}

export interface QueryOptions {
    /**
     * unscoped = true instructs the service to use the default GraphQL endpoint without clientId scoping, eg '/graphql/'
     * rather than '/clients/123/graphql/'. This means the query will operate in the user's default scope.
     */
    unscoped?: boolean;
    rejectOnError?: boolean;
}

export class GraphQLService {
    private ax: AxiosInstance;
    private path: string;
    private defaultPath: string;
    private clientId: number = null;

    constructor(ax: AxiosInstance, path = '/graphql/') {
        this.ax = ax;
        this.path = path;
        this.defaultPath = path;
    }

    setClientId(clientId: number) {
        this.clientId = clientId;
        if (clientId === undefined || clientId == null) {
            // we have to use graphql before we have the user info, leaving that part off means the server will use the
            // user's primary client - their only client for non-partner users.
            this.path = `/graphql/`;
        } else {
            this.path = `/clients/${clientId}/graphql/`;
        }

        // console.log("GraphQLServer clientId set, path: ", this.path);
    }

    getClientId(): number {
        return this.clientId;
    }

    errorHandler(error): Promise<any> | any {
        if (error?.response?.data) {
            return Promise.reject(error.response.data);
        }

        return Promise.reject({});
    }

    successHandler(response: AxiosResponse): Promise<any> | any {
        const hasData = response?.data != null && response?.data?.data != null;
        // disable for now because some GraphQL queries return errors!
        // let hasErrors = response.data.errors && response.data.errors.length > 0;

        if (hasData) {
            return response.data.data;
        }
        return Promise.reject(response?.data ?? null);
    }

    errorRejectingSuccessHandler(response: AxiosResponse): Promise<any> | any {
        if (response.data && response.data.errors && response.data.errors.length > 0) {
            return Promise.reject(response.data);
        }

        if (response.data && response.data.data) {
            return response.data.data;
        }

        return Promise.reject(response && response.data ? response.data : null);
    }

    /**
     * @param query
     * @param options
     * @returns {Promise<any|any>}
     */
    query(query: GraphQLQuery, options?: QueryOptions): Promise<any> {
        let queryPath = this.path;

        if (options && options.unscoped) {
            queryPath = this.defaultPath;
        }

        const promise = this.ax.post(queryPath, query);

        if (options && options.rejectOnError) {
            return promise.then(this.errorRejectingSuccessHandler, this.errorHandler);
        } else {
            return promise.then(this.successHandler, this.errorHandler);
        }
    }

    mutate(query: GraphQLQuery, options?: QueryOptions): Promise<any> {
        if (query.variables == null) {
            throw 'variables cannot be null';
        }

        if (query.variables.input == null) {
            throw 'variables.input cannot be null';
        }

        if (query.variables.input.clientMutationId == null) {
            // this is for future compatability with Relay - for now any string will do
            query.variables.input.clientMutationId = 'bogus-uuid'; // UUID.UUID();
        }

        return this.query(query, options);
    }
}
