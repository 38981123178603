import { RegionType } from 'models/Geo/types';

/**
 * Resolves long-form ISO offical English country names, common country names and ISO 3166-1 alpha-2 codes to
 * ISO 3166-1 alpha-3 codes.
 * @param country
 * @returns {string}
 * @constructor
 */
const ResolveCountryToIso2 = function(country: string): string {
    if (!longToTwo[country]) {
        // console.debug(`Country '${country}' can't be resolved to ISO 3166-1 alpha-3 code.`);
    }

    return longToTwo[country];
};

const ResolveStateToIso2 = function(state: string): string {
    if (usStatesNametoTwo[state]) {
        return usStatesNametoTwo[state];
    }

    if (indiaStatesNameToId[state]) {
        return indiaStatesNameToId[state];
    }

    if (japanProvincesNameToId[state]) {
        return japanProvincesNameToId[state];
    }

    return state && state.toLowerCase(); //For Philippines - the id from space is just the region name -AMR
};

const ResolveRegionNametoCode = (regionName: string, regionType: RegionType): string => {
    if (regionType === 'state') {
        return ResolveStateToIso2(regionName);
    } else if (regionType === 'country') {
        return ResolveCountryToIso2(regionName);
    } else if (regionType === 'county' || regionType === 'province') {
        return regionName;
    }
};

const indiaStatesNameToId = {
    'Andaman and Nicobar Islands': 'IN-AN',
    'Andhra Pradesh': 'IN-AP',
    'Arunachal Pradesh': 'IN-AR',
    Assam: 'IN-AS',
    Bihar: 'IN-BR',
    Chandigarh: 'IN-CH',
    Chhattisgarh: 'IN-CT',
    'Dadra and Nagar Haveli': 'IN-DN',
    'Daman and Diu': 'IN-DD',
    Dehli: 'IN-DL',
    Delhi: 'IN-DL',
    Goa: 'IN-GA',
    Gujarat: 'IN-GJ',
    Haryana: 'IN-HR',
    'Himachal Pradesh': 'IN-HP',
    'Jammu and Kashmir': 'IN-JK',
    Jharkhand: 'IN-JH',
    Karnataka: 'IN-KA',
    Kashmir: 'IN-JK',
    Kerala: 'IN-KL',
    Ladakh: 'IN-LA',
    Lakshadweep: 'IN-LD',
    'Madhya Pradesh': 'IN-MP',
    Maharashtra: 'IN-MH',
    Manipur: 'IN-MN',
    Meghalaya: 'IN-ML',
    Mizoram: 'IN-MZ',
    Nagaland: 'IN-NL',
    Odisha: 'IN-OR',
    Orissa: 'IN-OR',
    Punjab: 'IN-PB',
    Puducherry: 'IN-PY',
    Rajasthan: 'IN-RJ',
    Sikkim: 'IN-SK',
    'Tamil Nadu': 'IN-TN',
    Telangana: 'IN-TG',
    Tripura: 'IN-TR',
    'Uttar Pradesh': 'IN-UP',
    Uttarakhand: 'IN-UT',
    'West Bengal': 'IN-WB',

    'Western Province': 'LK-1',
    Western: 'LK-1',
    'Central Province': 'LK-2',
    Central: 'LK-2',
    'Southern Province': 'LK-3',
    Southern: 'LK-3',
    'Northern Province': 'LK-4',
    Northern: 'LK-4',
    'Eastern Province': 'LK-5',
    Eastern: 'LK-5',
    'North Western Province': 'LK-6',
    'North Western': 'LK-6',
    'North Central Province': 'LK-7',
    'North Central': 'LK-7',
    'Uva Province': 'LK-8',
    Uva: 'LK-8',
    'Sabaragamuwa Province': 'LK-9',
    Sabaragamuwa: 'LK-9',
};

const japanProvincesNameToId = {
    Aichi: 'JP-23',
    Akita: 'JP-05',
    Aomori: 'JP-02',
    Chiba: 'JP-12',
    Ehime: 'JP-38',
    Fukui: 'JP-18',
    Fukuoka: 'JP-40',
    Fukushima: 'JP-07',
    Gifu: 'JP-21',
    Gunma: 'JP-10',
    Hiroshima: 'JP-34',
    Hokkaido: 'JP-01',
    Hyogo: 'JP-28',
    Ibaraki: 'JP-08',
    Ishikawa: 'JP-17',
    Iwate: 'JP-03',
    Kagawa: 'JP-37',
    Kagoshima: 'JP-46',
    Kanagawa: 'JP-14',
    Kochi: 'JP-39',
    Kumamoto: 'JP-43',
    Kyoto: 'JP-26',
    Mie: 'JP-24',
    Miyagi: 'JP-04',
    Miyazaki: 'JP-45',
    Nagano: 'JP-20',
    Nagasaki: 'JP-42',
    Nara: 'JP-29',
    Niigata: 'JP-15',
    Oita: 'JP-44',
    Okayama: 'JP-33',
    Okinawa: 'JP-47',
    Osaka: 'JP-27',
    Saga: 'JP-41',
    Saitama: 'JP-11',
    Shiga: 'JP-25',
    Shimane: 'JP-32',
    Shizuoka: 'JP-22',
    Tochigi: 'JP-09',
    Tokushima: 'JP-36',
    Tokyo: 'JP-13',
    Tottori: 'JP-31',
    Toyama: 'JP-16',
    Wakayama: 'JP-30',
    Yamagata: 'JP-06',
    Yamaguchi: 'JP-35',
    Yamanashi: 'JP-19',
};

var usStatesNametoTwo = {
    Alabama: 'AL',
    Alaska: 'AK',
    'American Samoa': 'AS',
    Arizona: 'AZ',
    Arkansas: 'AR',
    California: 'CA',
    Colorado: 'CO',
    Connecticut: 'CT',
    Delaware: 'DE',
    'District Of Columbia': 'DC',
    'Federated States Of Micronesia': 'FM',
    Florida: 'FL',
    Georgia: 'GA',
    Guam: 'GU',
    Hawaii: 'HI',
    Idaho: 'ID',
    Illinois: 'IL',
    Indiana: 'IN',
    Iowa: 'IA',
    Kansas: 'KS',
    Kentucky: 'KY',
    Louisiana: 'LA',
    Maine: 'ME',
    'Marshall Islands': 'MH',
    Maryland: 'MD',
    Massachusetts: 'MA',
    Michigan: 'MI',
    Minnesota: 'MN',
    Mississippi: 'MS',
    Missouri: 'MO',
    Montana: 'MT',
    Nebraska: 'NE',
    Nevada: 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Northern Mariana Islands': 'MP',
    Ohio: 'OH',
    Oklahoma: 'OK',
    Oregon: 'OR',
    Palau: 'PW',
    Pennsylvania: 'PA',
    'Puerto Rico': 'PR',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    Tennessee: 'TN',
    Texas: 'TX',
    Utah: 'UT',
    Vermont: 'VT',
    'Virgin Islands': 'VI',
    Virginia: 'VA',
    Washington: 'WA',
    'West Virginia': 'WV',
    Wisconsin: 'WI',
    Wyoming: 'WY',
};

//some Philippines regions come through as two separate data points -AMR
export const doublePhilippinesRegions = [
    {
        correct: 'ilocos region',
        secondary: 'ilocos',
    },
    {
        correct: 'national capital region',
        secondary: 'metro manila',
    },
    {
        correct: 'cordillera administrative region',
        secondary: 'cordillera',
    },
];

/**
 * Long names here from http://data.okfn.org/data/core/country-codes/r/country-codes.json with minor manual additions.
 * This list includes as keys common names and ISO "official" English names
 * Values are ISO 3166-1 alpha-2 codes.
 */
const longToTwo = {
    Afghanistan: 'AF',
    Albania: 'AL',
    Algeria: 'DZ',
    'American Samoa': 'AS',
    Andorra: 'AD',
    Angola: 'AO',
    Anguilla: 'AI',
    Antarctica: 'AQ',
    'Antigua & Barbuda': 'AG',
    'Antigua and Barbuda': 'AG',
    Argentina: 'AR',
    Armenia: 'AM',
    Aruba: 'AW',
    Australia: 'AU',
    Austria: 'AT',
    Azerbaijan: 'AZ',
    Bahamas: 'BS',
    Bahrain: 'BH',
    Bangladesh: 'BD',
    Barbados: 'BB',
    Belarus: 'BY',
    Belgium: 'BE',
    Belize: 'BZ',
    Benin: 'BJ',
    Bermuda: 'BM',
    Bhutan: 'BT',
    Bolivia: 'BO',
    'Bolivia (Plurinational State of)': 'BO',
    'Bonaire, Sint Eustatius and Saba': 'BQ',
    Bosnia: 'BA',
    'Bosnia and Herzegovina': 'BA',
    Botswana: 'BW',
    'Bouvet Island': 'BV',
    Brazil: 'BR',
    'British Indian Ocean Territory': 'IO',
    'British Virgin Islands': 'VG',
    Brunei: 'BN',
    'Brunei Darussalam': 'BN',
    Bulgaria: 'BG',
    'Burkina Faso': 'BF',
    Burundi: 'BI',
    'Cabo Verde': 'CV',
    Cambodia: 'KH',
    Cameroon: 'CM',
    Canada: 'CA',
    'Cape Verde': 'CV',
    'Caribbean Netherlands': 'BQ',
    'Cayman Islands': 'KY',
    'Central African Republic': 'CF',
    Chad: 'TD',
    'Channel Islands': '',
    Chile: 'CL',
    China: 'CN',
    'China, Hong Kong Special Administrative Region': 'HK',
    'China, Macao Special Administrative Region': 'MO',
    'Christmas Island': 'CX',
    'Cocos (Keeling) Islands': 'CC',
    Colombia: 'CO',
    Comoros: 'KM',
    Congo: 'CG',
    'Congo - Brazzaville': 'CG',
    'Congo - Kinshasa': 'CD',
    'Cook Islands': 'CK',
    'Costa Rica': 'CR',
    Croatia: 'HR',
    Cuba: 'CU',
    Curaçao: 'CW',
    Cyprus: 'CY',
    Czechia: 'CZ',
    "Côte d'Ivoire": 'CI',
    'Côte d’Ivoire': 'CI',
    "Democratic People's Republic of Korea": 'KP',
    'Democratic Republic of the Congo': 'CD',
    Denmark: 'DK',
    Djibouti: 'DJ',
    Dominica: 'DM',
    'Dominican Republic': 'DO',
    Ecuador: 'EC',
    Egypt: 'EG',
    'El Salvador': 'SV',
    'Equatorial Guinea': 'GQ',
    Eritrea: 'ER',
    Estonia: 'EE',
    Ethiopia: 'ET',
    'Faeroe Islands': 'FO',
    'Falkland Islands': 'FK',
    'Falkland Islands (Malvinas)': 'FK',
    'Faroe Islands': 'FO',
    Fiji: 'FJ',
    Finland: 'FI',
    France: 'FR',
    'French Guiana': 'GF',
    'French Polynesia': 'PF',
    'French Southern Territories': 'TF',
    Gabon: 'GA',
    Gambia: 'GM',
    Georgia: 'GE',
    Germany: 'DE',
    Ghana: 'GH',
    Gibraltar: 'GI',
    Greece: 'GR',
    Greenland: 'GL',
    Grenada: 'GD',
    Guadeloupe: 'GP',
    Guam: 'GU',
    Guatemala: 'GT',
    Guernsey: 'GG',
    Guinea: 'GN',
    'Guinea-Bissau': 'GW',
    Guyana: 'GY',
    Haiti: 'HT',
    'Heard & McDonald Islands': 'HM',
    'Holy See': 'VA',
    Honduras: 'HN',
    'Hong Kong': 'HK',
    Hungary: 'HU',
    Iceland: 'IS',
    India: 'IN',
    Indonesia: 'ID',
    Iran: 'IR',
    'Iran (Islamic Republic of)': 'IR',
    Iraq: 'IQ',
    Ireland: 'IE',
    'Isle of Man': 'IM',
    Israel: 'IL',
    Italy: 'IT',
    Jamaica: 'JM',
    Japan: 'JP',
    Jersey: 'JE',
    Jordan: 'JO',
    Kazakhstan: 'KZ',
    Kenya: 'KE',
    Kiribati: 'KI',
    Kuwait: 'KW',
    Kyrgyzstan: 'KG',
    "Lao People's Democratic Republic": 'LA',
    Laos: 'LA',
    Latvia: 'LV',
    Lebanon: 'LB',
    Lesotho: 'LS',
    Liberia: 'LR',
    Libya: 'LY',
    Liechtenstein: 'LI',
    Lithuania: 'LT',
    Luxembourg: 'LU',
    Macau: 'MO',
    Macedonia: 'MK',
    Madagascar: 'MG',
    Malawi: 'MW',
    Malaysia: 'MY',
    Maldives: 'MV',
    Mali: 'ML',
    Malta: 'MT',
    'Marshall Islands': 'MH',
    Martinique: 'MQ',
    Mauritania: 'MR',
    Mauritius: 'MU',
    Mayotte: 'YT',
    Mexico: 'MX',
    Micronesia: 'FM',
    'Micronesia (Federated States of)': 'FM',
    Moldova: 'MD',
    Monaco: 'MC',
    Mongolia: 'MN',
    Montenegro: 'ME',
    Montserrat: 'MS',
    Morocco: 'MA',
    Mozambique: 'MZ',
    Myanmar: 'MM',
    Namibia: 'NA',
    Nauru: 'NR',
    Nepal: 'NP',
    Netherlands: 'NL',
    'New Caledonia': 'NC',
    'New Zealand': 'NZ',
    Nicaragua: 'NI',
    Niger: 'NE',
    Nigeria: 'NG',
    Niue: 'NU',
    'Norfolk Island': 'NF',
    'North Korea': 'KP',
    'Northern Mariana Islands': 'MP',
    Norway: 'NO',
    Oman: 'OM',
    Pakistan: 'PK',
    Palau: 'PW',
    Palestine: 'PS',
    'Palestine, State of': 'PS',
    'Palestinian Territory': 'PS',
    Panama: 'PA',
    'Papua New Guinea': 'PG',
    Paraguay: 'PY',
    Peru: 'PE',
    Philippines: 'PH',
    Pitcairn: 'PN',
    'Pitcairn Islands': 'PN',
    Poland: 'PL',
    Portugal: 'PT',
    'Puerto Rico': 'PR',
    Qatar: 'QA',
    'Republic of Korea': 'KR',
    'Republic of Moldova': 'MD',
    Romania: 'RO',
    Russia: 'RU',
    'Russian Federation': 'RU',
    Rwanda: 'RW',
    Réunion: 'RE',
    'Saint Barthélemy': 'BL',
    'Saint Helena': 'SH',
    'Saint Kitts and Nevis': 'KN',
    'Saint Lucia': 'LC',
    'Saint Martin (French part)': 'MF',
    'Saint Pierre and Miquelon': 'PM',
    'Saint Vincent and the Grenadines': 'VC',
    Samoa: 'WS',
    'San Marino': 'SM',
    'Sao Tome and Principe': 'ST',
    Sark: '',
    'Saudi Arabia': 'SA',
    Senegal: 'SN',
    Serbia: 'RS',
    Seychelles: 'SC',
    'Sierra Leone': 'SL',
    Singapore: 'SG',
    'Sint Maarten': 'SX',
    'Sint Maarten (Dutch part)': 'SX',
    Slovakia: 'SK',
    Slovenia: 'SI',
    'Solomon Islands': 'SB',
    Somalia: 'SO',
    'South Africa': 'ZA',
    'South Georgia & South Sandwich Islands': 'GS',
    'South Korea': 'KR',
    'South Sudan': 'SS',
    Spain: 'ES',
    'Sri Lanka': 'LK',
    'St. Barthélemy': 'BL',
    'St. Helena': 'SH',
    'St. Kitts & Nevis': 'KN',
    'St. Lucia': 'LC',
    'St. Martin': 'MF',
    'St. Pierre & Miquelon': 'PM',
    'St. Vincent & Grenadines': 'VC',
    'State of Palestine': 'PS',
    Sudan: 'SD',
    Suriname: 'SR',
    'Svalbard & Jan Mayen': 'SJ',
    'Svalbard and Jan Mayen Islands': 'SJ',
    Swaziland: 'SZ',
    Sweden: 'SE',
    Switzerland: 'CH',
    Syria: 'SY',
    'Syrian Arab Republic': 'SY',
    'São Tomé & Príncipe': 'ST',
    Taiwan: 'TW',
    Tajikistan: 'TJ',
    Tanzania: 'TZ',
    Thailand: 'TH',
    'The former Yugoslav Republic of Macedonia': 'MK',
    'Timor-Leste': 'TL',
    Togo: 'TG',
    Tokelau: 'TK',
    Tonga: 'TO',
    'Trinidad & Tobago': 'TT',
    'Trinidad and Tobago': 'TT',
    Tunisia: 'TN',
    Turkey: 'TR',
    Turkmenistan: 'TM',
    'Turks & Caicos Islands': 'TC',
    'Turks and Caicos Islands': 'TC',
    Tuvalu: 'TV',
    'U.S. Outlying Islands': 'UM',
    'U.S. Virgin Islands': 'VI',
    UK: 'GB',
    Uganda: 'UG',
    Ukraine: 'UA',
    'United Arab Emirates': 'AE',
    'United Kingdom of Great Britain and Northern Ireland': 'GB',
    'United Republic of Tanzania': 'TZ',
    'United States Virgin Islands': 'VI',
    'United Kingdom': 'GB',
    'United States': 'US',
    'United States of America': 'US',
    Uruguay: 'UY',
    Uzbekistan: 'UZ',
    Vanuatu: 'VU',
    'Vatican City': 'VA',
    Venezuela: 'VE',
    'Venezuela (Bolivarian Republic of)': 'VE',
    'Viet Nam': 'VN',
    Vietnam: 'VN',
    'Wallis & Futuna': 'WF',
    'Wallis and Futuna Islands': 'WF',
    'Western Sahara': 'EH',
    Yemen: 'YE',
    Zambia: 'ZM',
    Zimbabwe: 'ZW',
    'Åland Islands': 'AX',
};

export { ResolveCountryToIso2, ResolveStateToIso2, ResolveRegionNametoCode, usStatesNametoTwo };
