import * as React from 'react';
import { Spinner } from 'components/spaceKit';

export const FALLBACK_SPINNER = { fallback: <Spinner /> };

// widgets loading on external shouldn't show a loader
export const FALLBACK_EMPTY = { fallback: <div /> };

export function FALLBACK_EMPTY_COMPONENT() {
    return <div />;
}
