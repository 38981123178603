import { CommonDocFields, CommonDocImpl } from '../CommonDoc/CommonDoc';
import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';
import { PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import { EmbedOptions, EmbedOptionsImpl } from './EmbedOptions';
import { Post } from '@sprinklr/stories/post/Post';
import { observable } from 'mobx';

export interface Embed extends CommonDoc {
    options: EmbedOptions;
    postRequests: PostsRequest[];
    previewImageUrl?: string;
    dataLastModified?: number;
    galleryVersion?: number;
    updated?: any;
    createdByUserId?: number;
    sharedWithUserIds?: number[];
    sharedWithGroupIds?: string[];
    restricted?: boolean;
}

export class EmbedImpl extends CommonDocImpl implements Embed {
    @observable options: EmbedOptions = new EmbedOptionsImpl();
    @observable postRequests: PostsRequest[];
    @observable posts?: Post[];
    @observable previewImageUrl?: string;
    @observable dataLastModified?: number;
    @observable createdByUserId?: number;
    @observable sharedWithUserIds?: number[];
    @observable sharedWithGroupIds?: string[];
    @observable restricted?: boolean;
}

export const EmbedFields = `
    ${CommonDocFields}
    options
    postRequests
    previewImageUrl
    galleryVersion
    createdByUserId
    sharedWithUserIds
    sharedWithGroupIds
    restricted
`;
