import { CommonDocFields, CommonDocImpl } from '../CommonDoc/CommonDoc';
import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';
import { WidgetSnippet } from '../Widget/Widget';
import { Widget } from '@sprinklr/stories/widget/Widget';
import { VersionCapable, VersionCapableFields } from '../VersionCapable/VersionCapable';
import { Theme, Themed, ThemeImpl, ThemeSnippet } from '../Theme/Theme';
import { Scene } from '../Scene/Scene';
import { Storyboard, StoryboardVersion } from '../Storyboard/Storyboard';
import { SceneVersion } from '../Scene/SceneVersion';
import { observable } from 'mobx';
import { UserAsset } from 'models/UserAsset/UserAsset';
import { PublishTierOverride } from 'models/PublishTierOverride/PublishTierOverride';

export interface Panel extends Themed, VersionCapable, CommonDoc {
    tags?: string[];
    widget?: Widget;
    shouldResetMeasurements?: boolean;
    dataUrl?: string;
    previewImageUrl?: string;
    previewImageDirty?: boolean;
    dataLastModified?: number;
    lastPublished?: number;
    nextPublish?: number;
    storyboardId?: string;
    sceneId?: string;
    theme?: Theme;
    userAssets?: UserAsset[];

    // only present in published payloads
    scene?: Scene;
    storyboard?: Storyboard;
    sceneVersion?: SceneVersion;
    storyboardVersion?: StoryboardVersion;
    screenIndex?: number;
    sceneIndex?: number;
    overrideGlobalTimePeriod?: boolean;
    publishTierOverride?: PublishTierOverride;
}

export interface PanelLight {
    id?: string;
    previewImageUrl?: string;
    userUpdated?: number;
}

export class PanelImpl extends CommonDocImpl implements Panel {
    @observable widget: Widget;
    @observable dataUrl?: string;
    @observable previewImageUrl?: string = null;
    @observable previewImageDirty?: boolean;
    @observable previewHtml?: string;
    @observable previewHtmlUrl?: string;
    @observable versionActive: boolean;
    @observable versionId?: string;
    @observable storyboardId?: string;
    @observable sceneId?: string;
    @observable theme?: ThemeImpl;
    @observable screenIndex?: number;
    @observable overrideGlobalTimePeriod?: boolean;
}

export const PanelFields = `
    ${CommonDocFields}
    ${VersionCapableFields}
    previewImageUrl
    previewImageDirty
    storyboardId
    sceneId
    ${WidgetSnippet}
    ${ThemeSnippet}
`;

export const PanelLightFields = `
    id
    previewImageUrl
    previewImageDirty
    userUpdated
`;
