import { VideoPlayerOptions } from './Video';

const aspectRatio = 9 / 16;

export class VideoBase {
    protected player: any;
    protected id: number;
    protected videoId: string;
    protected container: any;
    protected placeholder: any;
    protected videoElement: any;
    protected options: VideoPlayerOptions;
    protected lastTime = {};
    protected muted: boolean;
    protected static unique = 1;

    constructor(muted: boolean) {
        this.muted = muted;
    }

    protected findVideoPlaceholder(container: HTMLElement) {
        return container?.querySelector('.video_placeholder');
    }

    protected getDimensions(): { width: number | string; height: number | string } {
        const result = { width: this.options.width, height: this.options.height };

        if (typeof result.width === 'string' && typeof result.height === 'string') {
            return result;
        }
        if (this.options.sizeToWidth) {
            result.width = this.container.width();
            if (result.width === 0) {
                result.width = this.container.parent().width();
            }

            result.height =
                typeof result.height === 'number'
                    ? Math.floor(result.height * aspectRatio)
                    : result.height;
        } else if (!result.width) {
            result.width = 500;
            result.height = 300;
        }

        return result;
    }
}
