import TimePeriod, {
    PersistedTimePeriod,
    TimePeriodInterval,
    TimePeriodKey,
    TimePeriodWeekStart,
} from 'models/TimePeriod/TimePeriod';
import moment, { unitOfTime } from 'moment';
import 'moment-timezone';
import { AnalyticsFilter, AnalyticsTimePeriod } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { WidgetTimePeriod } from 'utils/Widget/TimePeriod/WidgetTimePeriod';
import { SingleTimePeriod } from 'models/TimePeriod/SingleTimePeriod';

export default class TimePeriodService {
    static defaultOptions: Partial<TimePeriod> = {
        wholePeriods: false,
        roundDates: null,
        timeZone: null,
        duration: 1 * 24 * 3600 * 1000, // Used for custom_duration
        startDate: null,
        endDate: null,
        rollingStart: null,
        rollingEnd: null,
        weekStart: null,
    };

    static createFromTimePeriod(
        source: TimePeriod,
        options?: Partial<TimePeriod>,
        now?: moment.Moment
    ): TimePeriod {
        now = moment(now);
        if (!now.isValid()) {
            throw new Error(now + ' is not a valid date.');
        }

        const sourceOptions = {
            wholePeriods: source.wholePeriods,
            roundDates: source.roundDates,
            timeZone: source.timeZone,
            duration: source.duration,
        };

        const opts = Object.assign({}, sourceOptions, options);

        const timePeriod: TimePeriod = new TimePeriod(
            source.timePeriod,
            now,
            source.duration,
            source.startDate,
            source.endDate,
            source.interval,
            source.xValue,
            opts.roundDates,
            opts.wholePeriods,
            opts.timeZone,
            source.rollingStart,
            source.rollingEnd,
            opts.name,
            opts.filters,
            source.weekStart
        );
        TimePeriodService.updateStartAndEndDates(timePeriod, now);
        return timePeriod;
    }

    static createFromSingleTimePeriod(
        source: SingleTimePeriod,
        options?: Partial<TimePeriod>,
        now?: moment.Moment
    ): TimePeriod {
        const sourceOptions = {
            wholePeriods: source.wholePeriods,
            roundDates: false,
            timeZone: source.timeZone,
            duration: source.duration,
        };

        const opts = Object.assign({}, sourceOptions, options);

        const timePeriod: TimePeriod = new TimePeriod(
            source.key,
            now,
            source.duration,
            null,
            null,
            null,
            source.xValue,
            opts.roundDates,
            opts.wholePeriods,
            opts.timeZone,
            moment(source.rollingStart),
            moment(source.rollingEnd),
            opts.name,
            opts.filters,
            source.weekStart
        );

        return this.createFromTimePeriod(timePeriod, options, now);
    }

    /**
     * TODO: what is this for? it doesn't seem to buse used anywhere and it
     * doesn't support wholePeriods being true in the source
     *
     * @param source
     * @param options
     * @param now
     */
    static createFromPersistedTimePeriod(
        source: PersistedTimePeriod,
        options?: Partial<TimePeriod>,
        now?: moment.Moment
    ): TimePeriod {
        now = moment(now);
        if (!now.isValid()) {
            throw new Error(now + ' is not a valid date.');
        }

        const sourceOptions = {
            wholePeriods: false,
            roundDates: source.roundDates,
            timeZone: source.timeZone,
            duration: source.duration,
        };

        const startDate = source.startDate ? moment(source.startDate) : null;
        const endDate = source.endDate ? moment(source.endDate) : null;
        const rollingStart = source.rollingStart ? moment(source.rollingStart) : null;
        const rollingEnd = source.rollingEnd ? moment(source.rollingEnd) : null;

        const opts = Object.assign({}, sourceOptions, options);

        const timePeriod: TimePeriod = new TimePeriod(
            source.timePeriod,
            now,
            source.duration,
            startDate,
            endDate,
            source.interval,
            source.xValue,
            opts.roundDates,
            opts.wholePeriods,
            opts.timeZone,
            rollingStart,
            rollingEnd,
            opts.name,
            opts.filters,
            source.weekStart
        );
        TimePeriodService.updateStartAndEndDates(timePeriod, now);
        return timePeriod;
    }

    static createFromAnalyticsTimePeriod(
        source: AnalyticsTimePeriod,
        options?: Partial<TimePeriod>,
        now?: moment.Moment
    ): TimePeriod {
        if (!source || (!source.previousPeriod && !source.key && !source.startTime)) {
            throw new Error('Must provide valid time period key or startTime and endTime');
        }
        now = moment(now);

        // This is a little odd, but it dodges a behavior with tz returning a string when moment is fed undefined:
        // moment(undefined) => Moment
        // moment(1690786800000).tz("UTC") =>  Moment
        // moment(undefined).tz("UTC") => "UTC" -- argh!
        if (source.timeZone) {
            now = now.tz(source.timeZone);
        }

        if (!now.isValid()) {
            throw new Error(now + ' is not a valid date.');
        }

        let key: TimePeriodKey = source.key ? source.key : 'custom';
        if ('custom' === source.key && !source.endTime) {
            key = 'custom_to_date';
        }

        const interval: TimePeriodInterval = TimePeriodService.intervalForKey(key);
        const xValue: number = source.xValue || null;
        const sourceOptions = {
            wholePeriods: source.wholePeriods,
            roundDates: null,
            timeZone: source.timeZone,
            duration: source.duration,
        };

        const opts = Object.assign({}, sourceOptions, options);

        let startTime = moment(source.startTime);
        let endTime = moment(source.endTime);

        if (source.timeZone) {
            startTime = startTime.tz(source.timeZone);
            endTime = endTime.tz(source.timeZone);
        }

        if (source.rollingStart) {
            const rollingStart = source.timeZone
                ? moment(source.rollingStart)
                      .tz(source.timeZone)
                      .startOf('day')
                : moment(source.rollingStart).startOf('day');

            const startDiff = now.startOf('day').diff(rollingStart, 'days');

            startTime = moment(source.startTime);
            if (source.timeZone) {
                startTime = startTime.tz(source.timeZone);
            }

            startTime = startTime.add(startDiff, 'days');
        }

        if (source.rollingEnd) {
            const rollingEnd = source.timeZone
                ? moment(source.rollingEnd)
                      .tz(source.timeZone)
                      .startOf('day')
                : moment(source.rollingEnd).startOf('day');

            const endDiff = now.startOf('day').diff(rollingEnd, 'days');

            endTime = moment(source.endTime);
            if (source.timeZone) {
                endTime = endTime.tz(source.timeZone);
            }

            endTime = endTime.add(endDiff, 'days');
        }

        const timePeriod: TimePeriod = new TimePeriod(
            key,
            now,
            opts.duration,
            startTime,
            endTime,
            interval,
            xValue,
            opts.roundDates,
            opts.wholePeriods,
            opts.timeZone,
            moment(source.rollingStart),
            moment(source.rollingEnd),
            opts.name,
            opts.filters,
            source.weekStart
        );
        TimePeriodService.updateStartAndEndDates(timePeriod, now);

        return timePeriod;
    }

    static createFromWidgetTimePeriod(
        source: WidgetTimePeriod,
        options?: Partial<TimePeriod>,
        now?: moment.Moment
    ): TimePeriod {
        let key: TimePeriodKey = source.key ? source.key : 'custom';
        if ('custom' === source.key && !source.dateStop) {
            key = 'custom_to_date';
        }

        const interval: TimePeriodInterval = TimePeriodService.intervalForKey(source.key);
        const duration: number = source.duration;
        const xValue: number = source.xValue;
        const sourceOptions = {
            wholePeriods: source.wholePeriods,
            roundDates: false,
            timeZone: source.timeZone,
            duration: source.duration,
        };

        const opts = Object.assign({}, sourceOptions, options);

        const timePeriod: TimePeriod = new TimePeriod(
            key,
            now,
            duration,
            moment(source.dateStart),
            moment(source.dateStop),
            interval,
            xValue,
            opts.roundDates,
            opts.wholePeriods,
            opts.timeZone,
            moment(source.rollingStart),
            moment(source.rollingEnd),
            opts.name,
            opts.filters,
            source.weekStart
        );
        TimePeriodService.updateStartAndEndDates(timePeriod, now);
        return timePeriod;
    }

    static createFromTimePeriodKey(
        key: TimePeriodKey,
        options?: Partial<TimePeriod>,
        now?: moment.Moment
    ): TimePeriod {
        now = moment(now);
        if (!now.isValid()) {
            throw new Error(now + ' is not a valid date.');
        }

        const interval: TimePeriodInterval = TimePeriodService.intervalForKey(key);
        const xValue = 1;
        const opts = Object.assign({}, TimePeriodService.defaultOptions, options);

        const timePeriod: TimePeriod = new TimePeriod(
            key,
            now,
            opts.duration,
            'custom' === key ? opts.startDate : null,
            'custom' === key ? opts.endDate : null,
            interval,
            xValue,
            opts.roundDates,
            opts.wholePeriods,
            opts.timeZone,
            'custom' === key ? opts.rollingStart : null,
            'custom' === key ? opts.rollingEnd : null,
            opts.name,
            opts.filters,
            opts.weekStart
        );
        TimePeriodService.updateStartAndEndDates(timePeriod, now);
        return timePeriod;
    }

    static coreKeyMapping = {
        CUSTOM: 'custom',
        LIFETIME: 'all_time',
        LAST_12_HOURS: 'last_12_hours',
        LAST_24_HOURS: 'last_24_hours',
        TODAY: 'today',
        YESTERDAY: 'yesterday',
        LAST_7_DAYS: 'last_7_days',
        NEXT_WEEK: 'next_week',
        THIS_WEEK: 'this_week',
        LAST_WEEK: 'last_week',
        LAST_28_DAYS: 'last_28_days',
        LAST_30_DAYS: 'last_30_days',
        LAST_60_DAYS: 'last_60_days',
        LAST_90_DAYS: 'last_90_days',
        LAST_120_DAYS: 'last_120_days',
        LAST_180_DAYS: 'last_180_days',
        THIS_MONTH: 'this_month',
        LAST_MONTH: 'last_month',
        LAST_365_DAYS: 'last_365_days',
        THIS_YEAR: 'this_year',
        LAST_YEAR: 'last_year',
        CURRENT_YEAR: 'this_year',
    };

    static createFromCoreTimePeriod(
        coreTimePeriod: any,
        timeZone: string,
        now?: moment.Moment
    ): TimePeriod {
        const key = TimePeriodService.coreKeyMapping[coreTimePeriod.timeRange];

        if (!key) {
            throw new Error(coreTimePeriod.timeRange + ' is not a valid time period.');
        }

        const options = {
            startDate: moment(coreTimePeriod.sinceTime),
            endDate: moment(coreTimePeriod.untilTime),
            timeZone,
        };

        return TimePeriodService.createFromTimePeriodKey(key, options, now);
    }

    static weekStartToDay(weekStart: TimePeriodWeekStart) {
        const days: TimePeriodWeekStart[] = [
            'SUNDAY',
            'MONDAY',
            'TUESDAY',
            'WEDNESDAY',
            'THURSDAY',
            'FRIDAY',
            'SATURDAY',
        ];
        return days.indexOf(weekStart);
    }

    static updateStartAndEndDates(instance: TimePeriod, referenceTime?: moment.Moment): void {
        let now: moment.Moment = referenceTime ? referenceTime.clone() : instance.now;
        if (!now) {
            now = moment();
        }

        const xValue = instance.xValue && instance.xValue >= 1 ? instance.xValue : 1;

        const weekStart = instance.weekStart;
        //'isoWeek' begins with Monday, 'week' begins with sunday. In order to set a different day as the start of the week in moment, 'week' needs to be used -AMR
        let week: unitOfTime.StartOf = 'isoWeek';
        if (weekStart) {
            moment.updateLocale('en', {
                week: {
                    dow: this.weekStartToDay(weekStart),
                },
            });
            week = 'week';
        }

        // Set timezone if available
        if (instance.timeZone) {
            now.tz(instance.timeZone);
        }

        // Correct custom to custom_to_date if end date is null
        if (instance.timePeriod === 'custom' && instance.startDate && !instance.endDate) {
            instance.timePeriod = 'custom_to_date';
        }

        switch (instance.timePeriod) {
            case 'all_time':
                instance.startDate = null;
                instance.endDate = null;
                break;
            case 'custom':
                break;
            case 'custom_duration':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(instance.duration - 1, 'milliseconds');
                    instance.endDate = now
                        .clone()
                        .startOf('day')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now
                        .clone()
                        .endOf('day')
                        .subtract(instance.duration - 1, 'milliseconds');
                    instance.endDate = now.clone().endOf('day');
                }
                break;
            case 'custom_to_date':
                instance.endDate = now.clone().endOf('day');
                break;
            case 'this_week':
                instance.startDate = now.clone().startOf(week);
                if (instance.wholePeriods) {
                    instance.endDate = now
                        .clone()
                        .startOf('day')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.endDate = now.clone().endOf(week);
                }
                break;
            case 'this_month':
                instance.startDate = now.clone().startOf('month');
                if (instance.wholePeriods) {
                    instance.endDate = now
                        .clone()
                        .startOf('day')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.endDate = now.clone().endOf('month');
                }
                break;
            case 'this_quarter':
                instance.startDate = now.clone().startOf('quarter');
                instance.endDate = now.clone().endOf('quarter');
                break;
            case 'this_year':
                instance.startDate = now.clone().startOf('year');
                if (instance.wholePeriods) {
                    instance.endDate = now
                        .clone()
                        .startOf('day')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.endDate = now.clone().endOf('year');
                }
                break;
            case 'today':
                instance.startDate = now.clone().startOf('day');
                instance.endDate = instance.startDate.clone().endOf('day');
                break;
            case 'yesterday':
                instance.startDate = now
                    .clone()
                    .subtract(1, 'days')
                    .startOf('day');
                instance.endDate = instance.startDate.clone().endOf('day');
                break;
            case 'day_before_yesterday':
                instance.startDate = now
                    .clone()
                    .subtract(2, 'days')
                    .startOf('day');
                instance.endDate = instance.startDate.clone().endOf('day');
                break;
            case 'last_week':
                instance.startDate = now
                    .clone()
                    .subtract(1, 'week')
                    .startOf(week);
                instance.endDate = instance.startDate.clone().endOf(week);
                break;
            case 'second_last_week':
                instance.startDate = now
                    .clone()
                    .subtract(2, 'week')
                    .startOf(week);
                instance.endDate = instance.startDate.clone().endOf(week);
                break;
            case 'last_month':
                instance.startDate = now
                    .clone()
                    .subtract(1, 'month')
                    .startOf('month');
                instance.endDate = instance.startDate.clone().endOf('month');
                break;
            case 'second_last_month':
                instance.startDate = now
                    .clone()
                    .subtract(2, 'month')
                    .startOf('month');
                instance.endDate = instance.startDate.clone().endOf('month');
                break;
            case 'last_quarter':
                instance.startDate = now
                    .clone()
                    .subtract(1, 'quarter')
                    .startOf('quarter');
                instance.endDate = instance.startDate.clone().endOf('quarter');
                break;
            case 'last_year':
                instance.startDate = now
                    .clone()
                    .subtract(1, 'year')
                    .startOf('year');
                instance.endDate = instance.startDate.clone().endOf('year');
                break;
            case 'second_last_year':
                instance.startDate = now
                    .clone()
                    .subtract(2, 'year')
                    .startOf('year');
                instance.endDate = instance.startDate
                    .clone()
                    .subtract(2, 'year')
                    .endOf('year');
                break;
            case 'next_week':
                instance.startDate = now
                    .clone()
                    .add(1, 'week')
                    .startOf(week);
                instance.endDate = instance.startDate.clone().endOf(week);
                break;
            case 'week_to_date':
                instance.startDate = now.clone().startOf(week);
                instance.endDate = now.clone().endOf('day');
                break;
            case 'month_to_date':
                instance.startDate = now.clone().startOf('month');
                instance.endDate = now.clone().endOf('day');
                break;
            case 'quarter_to_date':
                instance.startDate = now.clone().startOf('quarter');
                instance.endDate = now.clone().endOf('day');
                break;
            case 'year_to_date':
                instance.startDate = now.clone().startOf('year');
                instance.endDate = now.clone().endOf('day');
                break;
            case 'last_60_minutes':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('minute')
                        .subtract(60, 'minutes');
                    instance.endDate = now
                        .clone()
                        .startOf('minute')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now.clone().subtract(60, 'minutes');
                    instance.endDate = now.clone().subtract(1, 'milliseconds');
                }
                break;
            case 'last_2_to_1_hours':
                instance.startDate = now.clone().subtract(2, 'hours');
                instance.endDate = now.clone().subtract(1, 'hours');
                break;
            case 'last_12_hours':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('hour')
                        .subtract(12, 'hours');
                    instance.endDate = now
                        .clone()
                        .startOf('hour')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now.clone().subtract(12, 'hours');
                    instance.endDate = now.clone().subtract(1, 'milliseconds');
                }
                break;
            case 'last_24_to_12_hours':
                instance.startDate = now.clone().subtract(24, 'hours');
                instance.endDate = now.clone().subtract(12, 'hours');
                break;
            case 'last_24_hours':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('hour')
                        .subtract(24, 'hours');
                    instance.endDate = now
                        .clone()
                        .startOf('hour')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now.clone().subtract(24, 'hours');
                    instance.endDate = now.clone().subtract(1, 'milliseconds');
                }
                break;
            case 'last_48_to_24_hours':
                instance.startDate = now.clone().subtract(48, 'hours');
                instance.endDate = now.clone().subtract(24, 'hours');
                break;
            case 'last_7_days':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(7, 'days');
                    instance.endDate = now
                        .clone()
                        .startOf('day')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(6, 'days');
                    instance.endDate = now.clone().endOf('day');
                }
                break;
            case 'last_14_to_8_days':
                instance.startDate = now
                    .clone()
                    .startOf('day')
                    .subtract(14, 'days');
                instance.endDate = now
                    .clone()
                    .endOf('day')
                    .subtract(8, 'days');
                break;
            case 'last_28_days':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(28, 'days');
                    instance.endDate = now
                        .clone()
                        .startOf('day')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(27, 'days');
                    instance.endDate = now.clone().endOf('day');
                }
                break;
            case 'last_56_to_29_days':
                instance.startDate = now
                    .clone()
                    .startOf('day')
                    .subtract(56, 'days');
                instance.endDate = now
                    .clone()
                    .endOf('day')
                    .subtract(29, 'days');
                break;
            case 'last_30_days':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(30, 'days');
                    instance.endDate = now
                        .clone()
                        .startOf('day')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(29, 'days');
                    instance.endDate = now.clone().endOf('day');
                }
                break;
            case 'last_60_to_31_days':
                instance.startDate = now
                    .clone()
                    .startOf('day')
                    .subtract(60, 'days');
                instance.endDate = now
                    .clone()
                    .endOf('day')
                    .subtract(31, 'days');
                break;
            case 'last_60_days':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(60, 'days');
                    instance.endDate = now
                        .clone()
                        .startOf('day')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(59, 'days');
                    instance.endDate = now.clone().endOf('day');
                }
                break;
            case 'last_120_to_61_days':
                instance.startDate = now
                    .clone()
                    .startOf('day')
                    .subtract(120, 'days');
                instance.endDate = now
                    .clone()
                    .endOf('day')
                    .subtract(61, 'days');
                break;
            case 'last_90_days':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(90, 'days');
                    instance.endDate = now
                        .clone()
                        .startOf('day')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(89, 'days');
                    instance.endDate = now.clone().endOf('day');
                }
                break;
            case 'last_180_to_91_days':
                instance.startDate = now
                    .clone()
                    .startOf('day')
                    .subtract(180, 'days');
                instance.endDate = now
                    .clone()
                    .endOf('day')
                    .subtract(91, 'days');
                break;
            case 'last_120_days':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(120, 'days');
                    instance.endDate = now
                        .clone()
                        .startOf('day')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(119, 'days');
                    instance.endDate = now.clone().endOf('day');
                }
                break;
            case 'last_240_to_121_days':
                instance.startDate = now
                    .clone()
                    .startOf('day')
                    .subtract(240, 'days');
                instance.endDate = now
                    .clone()
                    .endOf('day')
                    .subtract(121, 'days');
                break;
            case 'last_180_days':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(180, 'days');
                    instance.endDate = now
                        .clone()
                        .startOf('day')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(179, 'days');
                    instance.endDate = now.clone().endOf('day');
                }
                break;
            case 'last_360_to_181_days':
                instance.startDate = now
                    .clone()
                    .startOf('day')
                    .subtract(360, 'days');
                instance.endDate = now
                    .clone()
                    .endOf('day')
                    .subtract(181, 'days');
                break;
            case 'last_365_days':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(365, 'days');
                    instance.endDate = now
                        .clone()
                        .startOf('day')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now
                        .clone()
                        .startOf('day')
                        .subtract(364, 'days');
                    instance.endDate = now.clone().endOf('day');
                }
                break;
            case 'last_730_to_366_days':
                instance.startDate = now
                    .clone()
                    .startOf('day')
                    .subtract(730, 'days');
                instance.endDate = now
                    .clone()
                    .endOf('day')
                    .subtract(366, 'days');
                break;
            case 'last_x_weeks':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf(week)
                        .subtract(xValue, 'weeks');
                    instance.endDate = now
                        .clone()
                        .startOf(week)
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now
                        .clone()
                        .add(1, 'day')
                        .startOf('day')
                        .subtract(xValue, 'weeks');
                    instance.endDate = now.clone().endOf('day');
                }
                break;
            case 'last_x_months':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('month')
                        .subtract(xValue, 'months');
                    instance.endDate = now
                        .clone()
                        .startOf('month')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now
                        .clone()
                        .add(1, 'day')
                        .startOf('day')
                        .subtract(xValue, 'months');
                    instance.endDate = now.clone().endOf('day');
                }
                break;
            case 'last_x_hours':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('hour')
                        .subtract(xValue, 'hours')
                        .subtract(1, 'hour');
                    instance.endDate = now
                        .clone()
                        .startOf('hour')
                        .subtract(1, 'milliseconds');
                } else {
                    instance.startDate = now
                        .clone()
                        .startOf('hour')
                        .subtract(xValue, 'hours');
                    instance.endDate = now.clone().endOf('hour');
                }
                break;
            case 'last_x_minutes':
                if (instance.wholePeriods) {
                    instance.startDate = now
                        .clone()
                        .startOf('minute')
                        .subtract(xValue, 'minutes')
                        .subtract(1, 'minute');
                    instance.endDate = now.clone().startOf('minute');
                } else {
                    instance.startDate = now
                        .clone()
                        .startOf('minute')
                        .subtract(xValue, 'minutes');
                    instance.endDate = now.clone().endOf('minute');
                }
                break;
            case 'x_days_ago':
                instance.startDate = now
                    .clone()
                    .startOf('day')
                    .subtract(xValue, 'days');
                instance.endDate = instance.startDate
                    .clone()
                    .endOf('day')
                    .subtract(1, 'milliseconds');
                break;
            case 'x_weeks_ago':
                instance.startDate = now
                    .clone()
                    .startOf(week)
                    .subtract(xValue, 'weeks');
                instance.endDate = instance.startDate
                    .clone()
                    .endOf(week)
                    .subtract(1, 'milliseconds');
                break;
            case 'x_months_ago':
                instance.startDate = now
                    .clone()
                    .startOf('month')
                    .subtract(xValue, 'months');
                instance.endDate = instance.startDate
                    .clone()
                    .endOf('month')
                    .subtract(1, 'milliseconds');
                break;
            case 'x_quarters_ago':
                instance.startDate = now
                    .clone()
                    .startOf('quarter')
                    .subtract(xValue, 'quarter');
                instance.endDate = instance.startDate
                    .clone()
                    .endOf('quarter')
                    .subtract(1, 'milliseconds');
                break;
            default:
                throw new Error(instance.timePeriod + ' is not a valid date range.');
        }

        // Update days
        if (instance.startDate && instance.endDate) {
            instance.days = instance.endDate.diff(instance.startDate, 'days') + 1;
        } else {
            instance.days = null;
        }
    }

    static intervalForKey(key: TimePeriodKey): TimePeriodInterval {
        switch (key) {
            case 'last_60_minutes':
            case 'last_2_to_1_hours':
            case 'last_24_to_12_hours':
            case 'last_48_to_24_hours':
            case 'last_x_minutes':
            case 'last_12_hours':
                return 'hour';

            case 'this_week':
            case 'next_week':
            case 'last_week':
            case 'second_last_week':
            case 'last_7_days':
            case 'week_to_date':
            case 'x_weeks_ago':
                return 'week';

            case 'this_month':
            case 'last_month':
            case 'second_last_month':
            case 'month_to_date':
            case 'x_months_ago':
                return 'month';

            case 'this_quarter':
            case 'last_quarter':
            case 'quarter_to_date':
            case 'x_quarters_ago':
                return 'quarter';

            case 'this_year':
            case 'last_year':
            case 'second_last_year':
            case 'year_to_date':
                return 'year';

            case 'today':
            case 'yesterday':
            case 'day_before_yesterday':
            case 'last_24_hours':
            case 'last_x_hours':
                return 'day';

            case 'last_14_to_8_days':
            case 'last_28_days':
            case 'last_56_to_29_days':
            case 'last_30_days':
            case 'last_60_to_31_days':
            case 'last_60_days':
            case 'last_120_to_61_days':
            case 'last_90_days':
            case 'last_180_to_91_days':
            case 'last_120_days':
            case 'last_240_to_121_days':
            case 'last_180_days':
            case 'last_360_to_181_days':
            case 'last_365_days':
            case 'last_730_to_366_days':
            case 'all_time':
            case 'custom':
            case 'custom_duration':
            case 'custom_to_date':
            case 'last_x_weeks':
            case 'last_x_months':
            case 'x_days_ago':
                return 'days';

            default:
                throw new Error(key + ' is not a valid date range.');
        }
    }

    static previousPeriod(instance: TimePeriod): TimePeriod {
        if (!instance.timePeriod) {
            throw new Error('timePeriod value required to get previous period.');
        }

        const options = {
            wholePeriods: instance.wholePeriods,
            timeZone: instance.timeZone,
        };

        if (instance.timePeriod === 'all_time') {
            return TimePeriodService.createFromTimePeriodKey('all_time', options);
        }

        let period: TimePeriodKey = instance.timePeriod;
        if (period === 'custom_to_date') {
            period = 'custom';
        }

        if (instance.startDate == null) {
            throw new Error('Cannot calculate previous period without a start date.');
        }

        if (instance.interval && instance.interval === 'days') {
            const startDate: moment.Moment = instance.startDate.clone();
            const endDate: moment.Moment = instance.endDate
                ? instance.endDate.clone()
                : instance.now.clone().endOf('day');
            const daysInRange: number = endDate.diff(startDate, 'days') + 1;

            const previous: TimePeriod = TimePeriodService.createFromTimePeriodKey(
                'custom',
                options
            );
            previous.startDate = startDate.subtract(daysInRange, 'days');
            previous.endDate = endDate.subtract(daysInRange, 'days');
            previous.now = instance.now.clone().subtract(daysInRange, 'days');
            return previous;
        }

        const units = instance.useXValue() ? instance.xValue : 1;
        const newNow = instance.now.clone().subtract(units, instance.interval);

        return TimePeriodService.createFromTimePeriod(instance, options, newNow);
    }
}
