import axios, { AxiosRequestConfig } from 'axios';
import config from 'src/config';

const axiosConfig: AxiosRequestConfig = {
    baseURL: config.apiRoot,
    withCredentials: !config.sandboxed, // keep axios from accessing cookies, etc.
};

if (config.sandboxed) {
    // Weird hacky way to get axios to avoid a cookie-handling error when sandboxed.
    axiosConfig.xsrfCookieName = '';
}

export const axiosInstance = axios.create(axiosConfig);
