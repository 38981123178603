import { Widget } from '@sprinklr/stories/widget/Widget';
import { WorldHeatMapWidgetOptions } from '../../widgets/WorldHeatMapWidget/options';
import DataSet from '@sprinklr/stories/analytics/DataSet';
import { SunburstWidgetOptions } from '../../widgets/SunburstWidget/options';
import { formatSunburstDataSet } from '../../widgets/SunburstWidget/helpers';
import { countWorldHeatMap } from '../../widgets/WorldHeatMapWidget/helpers';

function GetNumberOfDataItems(widget: Widget, dataSet?: DataSet) {
    const firstDimension = dataSet.getFirstDimension();
    const firstDimensionIndex = dataSet.getDimensionIndex(firstDimension);

    if (!widget) {
        return;
    }

    let totalItems: number = null;
    const { analyticsRequests, type } = widget;

    // catch special case widget types 'googleTrends' and 'twitterTrends'
    if (type && (type === 'googleTrends' || type === 'twitterTrends')) {
        totalItems = 15; // LEGZ TODO magic number - should be coming from a config instead
    } else if (type && type === 'sunburst') {
        const { maxItems, childItems } = (widget.options as SunburstWidgetOptions).sunburst;
        const data = formatSunburstDataSet(dataSet, maxItems, childItems.max, firstDimensionIndex);
        totalItems = data && data.children ? data.children.length : 0;
    } else if (type && type === 'worldHeatMap') {
        totalItems = dataSet
            ? countWorldHeatMap(dataSet, widget.options as WorldHeatMapWidgetOptions)
            : 0;
    } else if (type && type === 'heatMapTableChart') {
        totalItems = 169;
    } else if (type && type === 'pieChart' && widget.options.maxItems) {
        totalItems = widget.options.maxItems;
    } else if (dataSet?.dimensions?.length) {
        const lastDimensionIndex = dataSet.dimensions.length - 1;
        if (lastDimensionIndex !== -1) {
            totalItems =
                dataSet.dimensions &&
                dataSet.dimensions.length &&
                dataSet.dimensions[lastDimensionIndex] &&
                dataSet.groupBy(dataSet.dimensions[lastDimensionIndex]).length;
        }
    } else if (analyticsRequests) {
        const request = widget.analyticsRequests[0];
        const groupBys = request && request.groupBys;
        const filters = request && request.filters;

        // if we are grouping by topics then use the length of the filters array for our limit value
        if (
            groupBys?.length > 0 &&
            (groupBys[0].heading === 'TOPIC_NAME' || groupBys[0].heading === 'day')
        ) {
            totalItems =
                (filters && filters[1] && filters[1].values && filters[1].values.length) || null;
        } else if (type === 'barChart' && request?.projections?.length > 1) {
            totalItems = request.projections.length;
        } else {
            totalItems = request.limit < 169 ? request.limit : 169;
        }
    }

    return totalItems;
}

export { GetNumberOfDataItems };
