import i18n from '@sprinklr/display-builder/i18n';
import { TimePeriodKey } from 'models/TimePeriod/TimePeriod';

export const TIME_PERIOD_PICKER_OPTIONS: Array<{
    id: TimePeriodKey;
    name: string;
    period?: string;
    label?: string;
}> = [
    {
        id: 'last_60_minutes',
        name: i18n.t('Last 60 Minutes'),
    },
    {
        id: 'today',
        name: i18n.t('Today'),
    },
    {
        id: 'yesterday',
        name: i18n.t('Yesterday'),
    },
    {
        id: 'last_12_hours',
        name: i18n.t('Last ${hours} Hours', { hours: 12 }),
        period: i18n.t('this Hour'),
    },
    {
        id: 'last_24_hours',
        name: i18n.t('Last ${hours} Hours', { hours: 24 }),
        period: i18n.t('this Hour'),
    },
    {
        id: 'this_week',
        name: i18n.t('This Week'),
        period: i18n.t('Today'),
    },
    {
        id: 'last_week',
        name: i18n.t('Last Week'),
    },
    {
        id: 'last_7_days',
        name: i18n.t('Last ${days} Days', { days: 7 }),
        period: i18n.t('Today'),
    },
    {
        id: 'this_month',
        name: i18n.t('This Month'),
        period: i18n.t('Today'),
    },
    {
        id: 'last_month',
        name: i18n.t('Last Month'),
    },
    {
        id: 'last_28_days',
        name: i18n.t('Last ${days} Days', { days: 28 }),
        period: i18n.t('Today'),
    },
    {
        id: 'last_30_days',
        name: i18n.t('Last ${days} Days', { days: 30 }),
        period: i18n.t('Today'),
    },
    {
        id: 'last_60_days',
        name: i18n.t('Last ${days} Days', { days: 60 }),
        period: i18n.t('Today'),
    },
    {
        id: 'last_90_days',
        name: i18n.t('Last ${days} Days', { days: 90 }),
        period: i18n.t('Today'),
    },
    {
        id: 'last_120_days',
        name: i18n.t('Last ${days} Days', { days: 120 }),
        period: i18n.t('Today'),
    },
    {
        id: 'last_180_days',
        name: i18n.t('Last ${days} Days', { days: 180 }),
        period: i18n.t('Today'),
    },
    {
        id: 'year_to_date',
        name: i18n.t('Year to Date'),
    },
    {
        id: 'this_year',
        name: i18n.t('This Year'),
        period: i18n.t('Today'),
    },
    {
        id: 'last_year',
        name: i18n.t('Last Year'),
    },
    {
        id: 'next_week',
        name: i18n.t('Next Week'),
    },
    {
        id: 'last_365_days',
        name: i18n.t('Last ${days} Days', { days: 365 }),
        period: i18n.t('Today'),
    },
    {
        id: 'last_x_months',
        name: i18n.t('Last X Months'),
        period: i18n.t('This Month'),
        label: 'Months',
    },
    {
        id: 'last_x_weeks',
        name: i18n.t('Last X Weeks'),
        period: i18n.t('This Week'),
        label: 'Weeks',
    },
    {
        id: 'last_x_hours',
        name: i18n.t('Last X Hours'),
        period: i18n.t('This Hour'),
        label: 'Hours',
    },
    {
        id: 'last_x_minutes',
        name: i18n.t('Last X Minutes'),
        period: i18n.t('This Minute'),
        label: 'Minutes',
    },
    {
        id: 'custom_duration',
        name: i18n.t('Custom Days'),
        period: i18n.t('Today'),
    },
    {
        id: 'x_days_ago',
        name: i18n.t('X Days Ago'),
        label: 'Days Ago',
    },
    {
        id: 'x_weeks_ago',
        name: i18n.t('X Weeks Ago'),
        label: 'Weeks Ago',
    },
    {
        id: 'x_months_ago',
        name: i18n.t('X Months Ago'),
        label: 'Months Ago',
    },
    {
        id: 'x_quarters_ago',
        name: i18n.t('X Quarters Ago'),
        label: 'Quarters Ago',
    },
    {
        id: 'custom',
        name: i18n.t('Custom Date Range'),
    },
    {
        id: 'custom_to_date',
        name: i18n.t('Custom Date Through Today'),
    },
];

export const PREVIOUS_PERIOD_PICKER_OPTIONS: Array<{
    id: TimePeriodKey;
    name: string;
    period?: string;
    label?: string;
}> = [
    {
        id: 'yesterday',
        name: i18n.t('Yesterday'),
    },
    {
        id: 'day_before_yesterday',
        name: i18n.t('Day Before Yesterday'),
    },
    {
        id: 'last_2_to_1_hours',
        name: i18n.t('Last ${hours} to ${secondHours} Hours', { hours: 2, secondHours: 1 }),
    },
    {
        id: 'last_24_to_12_hours',
        name: i18n.t('Last ${hours} to ${secondHours} Hours', { hours: 24, secondHours: 12 }),
    },
    {
        id: 'last_48_to_24_hours',
        name: i18n.t('Last ${hours} to ${secondHours} Hours', { hours: 48, secondHours: 24 }),
    },
    {
        id: 'last_week',
        name: i18n.t('Last Week'),
    },
    {
        id: 'second_last_week',
        name: i18n.t('Second to Last Week'),
    },
    {
        id: 'last_14_to_8_days',
        name: i18n.t('Last ${days} to ${secondDays} Days', { days: 14, secondDays: 8 }),
    },
    {
        id: 'last_month',
        name: i18n.t('Last Month'),
    },
    {
        id: 'second_last_month',
        name: i18n.t('Second to Last Month'),
    },
    {
        id: 'last_56_to_29_days',
        name: i18n.t('Last ${days} to ${secondDays} Days', { days: 56, secondDays: 29 }),
    },
    {
        id: 'last_60_to_31_days',
        name: i18n.t('Last ${days} to ${secondDays} Days', { days: 60, secondDays: 31 }),
    },
    {
        id: 'last_120_to_61_days',
        name: i18n.t('Last ${days} to ${secondDays} Days', { days: 120, secondDays: 61 }),
    },
    {
        id: 'last_180_to_91_days',
        name: i18n.t('Last ${days} to ${secondDays} Days', { days: 180, secondDays: 91 }),
    },
    {
        id: 'last_240_to_121_days',
        name: i18n.t('Last ${days} to ${secondDays} Days', { days: 240, secondDays: 121 }),
    },
    {
        id: 'last_360_to_181_days',
        name: i18n.t('Last ${days} to ${secondDays} Days', { days: 360, secondDays: 181 }),
    },
    {
        id: 'last_year',
        name: i18n.t('Last Year'),
    },
    {
        id: 'second_last_year',
        name: i18n.t('Second to Last Year'),
    },
    {
        id: 'last_730_to_366_days',
        name: i18n.t('Last ${days} to ${secondDays} Days', { days: 730, secondDays: 366 }),
    },
    {
        id: 'custom',
        name: i18n.t('Custom Date Range'),
    },
];

export const DAY_IN_MS = 24 * 3600 * 1000;

export const timePeriodLabels = [...PREVIOUS_PERIOD_PICKER_OPTIONS, ...TIME_PERIOD_PICKER_OPTIONS];
