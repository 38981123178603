import { IdentifiedModelFields } from '../IdentifiedModel/IdentifiedModel';
import { observable } from 'mobx';
import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';

export const PartnerClientScopedFields = `
    clientId partnerId
`;

export const CommonDocFields = `
    ${IdentifiedModelFields} ${PartnerClientScopedFields}
    name description
    created updated userUpdated
`;

export class CommonDocImpl implements CommonDoc {
    @observable id: string;
    @observable clientId: number;
    @observable partnerId: number;
    @observable description: string;
    @observable name: string;
    @observable created: number;
    @observable updated: number;
    @observable userUpdated: number;
}
