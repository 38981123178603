// font faces
import { getResolvedFamilyWeight } from 'components/_UI/FontLoader/helpers';

export const supportedGoogleFonts = [
    'Urbanist',
    'Alice',
    'Montserrat',
    'Poppins',
    'Work Sans',
    'Secular One',
    'Jost',
    'Prata',
    'Kalam',
    'Marcellus',
    'IBM Plex Sans',
    'Crimson Text',
    'Raleway',
    'Zilla Slab',
    'Barlow Condensed',
];

export const legacyGoogleFonts = [
    'Abril Fatface',
    'Arvo',
    'Fredericka the Great',
    'Josefin Slab',
    'Libre Baskerville',
    'Lora',
    'Open Sans',
    'Ovo',
    'Questrial',
    'Sawarabi Gothic',
    'Roboto Condensed',
    'Rubik',
    'Source Sans Pro',
    'M PLUS 1p',
    'M PLUS Rounded 1c',
    'Noto Sans JP',
];

export function isGoogleFont(fontFamily: { family: string }) {
    const fontFamilyWeightPair = getResolvedFamilyWeight({
        family: fontFamily.family,
        weight: undefined,
    });
    return (
        supportedGoogleFonts.includes(fontFamilyWeightPair?.family) ||
        legacyGoogleFonts.includes(fontFamilyWeightPair?.family)
    );
}
