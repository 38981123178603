import { observable } from 'mobx';
import moment from 'moment';
import 'moment-timezone';

export type ScheduledStoryboard = {
    enabled: boolean;
    locationId: string;
    id: string;
    name: string;
    storyboardId: string;
    date?: number;
    timeZone: string;
    clientId: number;
    partnerId: number;
    recurring?: Recurring;
};

type Recurring = {
    enabled: boolean;
    frequency: 'DAY' | 'WEEK';
    time: string;
    notValidBefore: number;
    days?: {
        mon: boolean;
        tue: boolean;
        wed: boolean;
        thu: boolean;
        fri: boolean;
        sat: boolean;
        sun: boolean;
    };
};

export class ScheduledStoryboardImpl implements ScheduledStoryboard {
    @observable enabled: boolean;
    @observable locationId: string;
    @observable id: string;
    @observable name: string;
    @observable storyboardId: string;
    @observable date?: number;
    @observable timeZone: string;
    @observable recurring?: Recurring;
    @observable clientId: number;
    @observable partnerId: number;
}

export const ScheduledStoryboardFields = `
    id
    enabled
    locationId
    storyboardId
    clientId
    partnerId
    name
    date
    timeZone
    recurring {
        enabled
        frequency
        time
        notValidBefore
        days {
            mon
            tue
            wed
            thu
            fri
            sat
            sun
        }
    }
    `;

const dayAbbreviationsToIsoWeekday = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 7,
};

export class ScheduledStoryboardUtil {
    public static getHoursMinutes(schedule: ScheduledStoryboard): [number, number] {
        let [hour, minute] = schedule.recurring.time.split(':').map(f => parseInt(f, 10));

        if (hour > 23 && undefined === minute) {
            // fallback to epoch mode... just to support dev env funky data
            const zonedTimeVal = moment(hour).tz(schedule.timeZone);
            hour = parseInt(zonedTimeVal.format('HH'), 10);
            minute = parseInt(zonedTimeVal.format('mm'), 10);
        }

        return [hour, minute];
    }

    /**
     * Return the next time this schedule will fire starting at {@param fromTimestamp} inclusive.
     *
     * If the schedule is not enabled, or if it is a one-shot schedule in the past, -1 is returned.
     *
     * @param fromTimestamp
     * @param schedule
     */
    public static getNextEventTime(fromTimestamp: number, schedule: ScheduledStoryboard): number {
        if (!schedule || !schedule.enabled) {
            return -1;
        }

        if (schedule.recurring && schedule.recurring.enabled) {
            const startingPoint = Math.max(fromTimestamp, schedule.recurring.notValidBefore);
            const fromTime = moment(isNaN(startingPoint) ? fromTimestamp : startingPoint);
            const [hour, minute] = this.getHoursMinutes(schedule);
            const nextIfDaily = fromTime
                .tz(schedule.timeZone)
                .clone()
                .hour(hour)
                .minute(minute)
                .second(0)
                .millisecond(0);
            // if we backed the maybeNext value up before the fromTime, have to add a day to it.
            if (nextIfDaily.isBefore(fromTime)) {
                nextIfDaily.add(1, 'day');
            }
            // const nextIfDaily = maybeNext.isBefore(fromTime)
            //     ? maybeNext.clone().add(1, 'day')
            //     : maybeNext.clone();

            if (schedule.recurring.frequency === 'DAY') {
                return nextIfDaily.valueOf();
            }

            // WEEK mode... weeks are fun
            const eventTimes = Object.keys(schedule.recurring.days)
                .filter(day => schedule.recurring.days[day]) // day enabled?
                .map(day => dayAbbreviationsToIsoWeekday[day])
                .map(isoWeekday => nextIfDaily.clone().isoWeekday(isoWeekday))
                .map(evt => (evt.isSameOrAfter(fromTime) ? evt : evt.add(1, 'week')))
                .map(evt => evt.valueOf());

            return Math.min(...eventTimes);
        } else {
            if (!schedule.date || schedule.date < fromTimestamp) {
                return -1;
            }

            return schedule.date;
        }
    }

    public static sortScheduledStoryboards(
        effectiveNow: number,
        scheduledStoryboards: ScheduledStoryboard[]
    ): ScheduledStoryboard[] {
        if (!scheduledStoryboards || scheduledStoryboards.length < 2) {
            return scheduledStoryboards;
        }

        return scheduledStoryboards
            .map(ssb => ({ ssb, ts: ScheduledStoryboardUtil.getNextEventTime(effectiveNow, ssb) }))
            .sort((a, b) => a.ts - b.ts)
            .map(evt => evt.ssb);
    }
}
