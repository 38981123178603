import { Region, RegionType } from './types';

export function getType(region: Region): RegionType {
    let type: RegionType = 'country';

    switch (region) {
        case 'india':
        case 'japan':
        case 'philippines':
        case 'unitedStates':
            type = 'state';
            break;
    }

    return type;
}
