import { CommonDocFields, CommonDocImpl } from '../CommonDoc/CommonDoc';
import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';
import { Screen } from '../Screen/Screen';
import { observable } from 'mobx';
/**
 * Created by dstelter on 10/3/16.
 */
export interface Layout extends CommonDoc {
    screens: Screen[];
    layoutType?: string;
    layoutCustomId?: string;
}

export const LayoutFields = `
${CommonDocFields}
    screens {
        x y h w
    }
    layoutType
    layoutCustomId
`;

export class LayoutImpl extends CommonDocImpl implements Layout {
    @observable screens: Screen[];
    @observable layoutType?: string;
    @observable layoutCustomId?: string;
}

export function isLayoutPortrait(layout: Layout): boolean {
    if (!layout) {
        return;
    }
    const heightMap = layout.screens.map(screen => screen.h + screen.y);
    const widthMap = layout.screens.map(screen => screen.w + screen.x);
    const totalHeight = Math.max(...heightMap);
    const totalWidth = Math.max(...widthMap);
    return totalHeight > totalWidth;
}
