import { Version, VersionFields } from '../Version/Version';
import {
    Scene,
    SceneImpl,
    SceneTransition,
    SceneTransitionImpl,
    SceneTransitionSnippet,
} from './Scene';
import { CommonDocFields } from '../CommonDoc/CommonDoc';
import { PanelVersion, PanelVersionFields, PanelVersionLightFields } from '../Panel/PanelVersion';
import { ThemeImpl, ThemeSnippet } from '../Theme/Theme';
import { observable } from 'mobx';
import { HeaderSnippet } from 'components/storyboard/StoryboardHeaderFooter/Header/options';
import { PageNumberSnippet } from 'components/storyboard/StoryboardHeaderFooter/PageNumber/options';

export interface SceneVersion extends Scene, Version {
    sceneTransition?: SceneTransition;
}

export class SceneVersionImpl extends SceneImpl implements SceneVersion {
    @observable masterId?: string;
    @observable immutable?: boolean;
    @observable treeDirty: boolean;
    @observable savedByUserId?: number;
    @observable saved?: number;
    @observable panels: PanelVersion[];
    @observable theme?: ThemeImpl;
    @observable sceneTransition?: SceneTransitionImpl;
}

export const SceneVersionFields = `
    ${CommonDocFields}
    ${VersionFields}
    storyboardId
    panels {
        ${PanelVersionFields}
    }
    duration
    disabled
    ${ThemeSnippet}
    ${SceneTransitionSnippet}
    ${HeaderSnippet}
    ${PageNumberSnippet}
`;

export const SceneVersionLightFields = `
    id
    masterId
    panels {
        ${PanelVersionLightFields}
    }
    created
    updated
    userUpdated
    duration
    disabled
    treeDirty
    ${HeaderSnippet}
    ${PageNumberSnippet}

`;
