import { AnalyticsEngine, AnalyticsFilter } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { BulkLookupValue } from 'services/SprinklrAPIService/SprinklrAPIService';
import _capitalize from 'lodash/capitalize';

//     | 'inboundAnalytics'
//     | 'listeningTags'
//     | 'listeningTopics'
//     | 'paid'
//     | 'socialAnalytics'
//     ;
export enum DataSourceName {
    PAID = 'paid',
    BENCHMARKING = 'benchmarking',
    INBOUND_ANALYTICS = 'inboundAnalytics',
    SOCIAL_ANALYTICS = 'socialAnalytics',
    LISTENING_TAGS = 'listeningTags',
    LISTENING_TOPICS = 'listeningTopics',
    RDB_FIREHOSE = 'rdbFirehose',
    STORY_QUERY = 'storyQuery',
    STORY_TAGS = 'storyTags',
    STORY_QUERY_TAGS = 'storyQueryTags',
    LISTENING_EXPLORER = 'listeningExplorer',
    UNIVERSAL_PROFILE = 'universalProfile',
    TASK = 'task',
    CUSTOM_ENTITY = 'customEntity',
    VOICE = 'voice',
    COMMUNITY = 'community',
    DAM = 'DAM',
    AUDIENCE_ACTIVITY = 'audienceActivity',
    OUTBOUND_MESSAGE = 'outboundMessage',
    CONSUMPTION = 'consumption',
}

export interface DataSources {
    benchmarking?: DataSource;
    inboundAnalytics?: DataSource;
    listeningTags?: DataSource;
    listeningTopics?: DataSource;
    paid?: DataSource;
    socialAnalytics?: DataSource;
    storyQuery?: DataSource;
    listeningExplorer?: DataSource;
    universalProfile?: DataSource;
    task?: DataSource;
    customEntity?: DataSource;
    voice?: DataSource;
    community?: DataSource;
    DAM?: DataSource;
    audienceActivity?: DataSource;
    outboundMessage?: DataSource;
    consumption?: DataSource;
}

export interface DataSourceNames {
    benchmarking?: string;
    inboundAnalytics?: string;
    listeningTags?: string;
    listeningTopics?: string;
    paid?: string;
    socialAnalytics?: string;
    storyQuery?: string;
    listeningExplorer?: string;
    universalProfile?: string;
    task?: string;
    customEntity?: string;
    voice?: string;
    community?: string;
    DAM?: string;
    audienceActivity?: string;
    outboundMessage?: string;
    consumption?: string;
}

// Override default time period
export enum DataSourceDefaultTimePeriod {
    RDB_FIREHOSE = 'last_24_hours',
}

export class DataSources {
    static findDataSourceName(
        dataSources: DataSources,
        engine: AnalyticsEngine,
        sourceGroup: string
    ): DataSourceName {
        if (!dataSources || !engine) {
            console.warn('dataSources and engine not provided');
            return null;
        }
        return Object.values(DataSourceName).find(dsn => {
            if (!dataSources?.[dsn]) {
                return false;
            }
            const dataSource: DataSource = dataSources?.[dsn];
            if (dataSource?.reportingEngine === engine) {
                // check for datasource with defined sourceGroup, then check for match.
                if (dataSource.sourceGroup) {
                    return dataSource.sourceGroup === sourceGroup;
                } else {
                    return true;
                }
            } else {
                return false;
            }
        });
    }
}

export interface DataSource {
    report?: string;
    reportingEngine?: AnalyticsEngine;
    sourceGroup?: string;
    filters?: DataSourceFilter[];
    ancillaryFilters?: DataSourceFilter[];
}

export class DataSource implements DataSource {
    report?: string;
    reportingEngine?: AnalyticsEngine;
    sourceGroup?: string;
    filters?: DataSourceFilter[];
    ancillaryFilters?: DataSourceFilter[];

    constructor(src: any = {}) {
        this.report = src.report;
        this.reportingEngine = src.reportingEngine;
        this.sourceGroup = src.sourceGroup;
        this.filters = src.filters?.length ? src.filters.map(f => new DataSourceFilter(f)) : [];
        this.ancillaryFilters = src.ancillaryFilters?.length
            ? src.ancillaryFilters.map(f => new DataSourceFilter(f))
            : [];
    }
}

export interface DataSourceFilter {
    dimensionLabel: string;
    filter: SimpleFilter;
    filterValueLabels?: FilterValueLabel[];
}

/**
 * We've got data we're getting back from metaInfoService that's typed as BulkLookupValue but at
 * runtime often has extra fields. Added ones we care about are added to the type here:
 */
export type FilterValueLabel = {
    displayName?: string;
} & BulkLookupValue;

export type SimpleFilter = Partial<AnalyticsFilter> &
    Pick<AnalyticsFilter, 'dimensionName' | 'filterType' | 'values' | 'allValues'>;

export class DataSourceFilter implements DataSourceFilter {
    dimensionLabel: string;
    filter: SimpleFilter;
    filterValueLabels?: FilterValueLabel[];

    constructor(src: any) {
        if (src) {
            const { filter, dimensionLabel, filterValueLabels, allValues } = src;

            this.filter = {
                dimensionName: filter?.dimensionName,
                filterType: filter?.filterType,
                details: filter?.details,
                values: filter?.values?.length ? filter.values.slice() : [],
                allValues: !!(allValues || filter?.details?.allValues),
            };

            this.filterValueLabels = this.filter.allValues ? null : filterValueLabels;

            if (dimensionLabel) {
                this.dimensionLabel = dimensionLabel;
            } else {
                // This is a fallback for something I'm fighting with Paid dim accountIds, it's special and doesn't get
                // loaded the same as the other 11 dims. Doesn't show up in widget/panel add flows either...
                if (this.filter.dimensionName) {
                    // should be a string - but cast it in case, strip Ids suffix & capitalize
                    // targeting "accountIds" => "Account" but should be reasonable for other stuff?
                    // would be good to handle underscores & more in general case, but this is a stopgap.
                    // TODO: clean up once this is no longer needed.
                    const dimNamePrefix = `${this.filter.dimensionName}`.replace(/ids?$/i, '');
                    this.dimensionLabel = _capitalize(dimNamePrefix);
                }
            }
        }
    }
}

export const DataSourcesSnippet = `
    dataSources {
        benchmarking {
            ...dataSource
        }
        inboundAnalytics {
            ...dataSource
        }
        listeningTags {
            ...dataSource
        }
        listeningTopics {
            ...dataSource
        }
        socialAnalytics {
            ...dataSource
        }
        paid {
            ...dataSource
        }
        storyQuery {
            ...dataSource
        }
        storyTags {
            ...dataSource
        }
        storyQueryTags {
            ...dataSource
        }
        listeningExplorer {
            ...dataSource
        }
    }
`;

export const DataSourceFragment = `
fragment dataSource on DataSource {
  report
  reportingEngine
  sourceGroup
  filters {
    dimensionLabel
    filter {
      dimensionName
      filterType
      values
      allValues
    }
    filterValueLabels {
      id name
      displayName
    }
  }
  ancillaryFilters {
    dimensionLabel
    filter {
      dimensionName
      filterType
      values
      allValues
      details
    }
    filterValueLabels {
      id name
      displayName
    }

  }
}
`;
