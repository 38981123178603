import { FontCase } from 'models/Theme/Theme';
import _upperCase from 'lodash/upperCase';
import moment from 'moment';
import i18n from '../../i18n';
import { FieldType } from '@sprinklr/stories/reporting/types';
import { applyLocaleToMoment } from '../Date/DateUtils';

export type DecimalFormat = '1k' | '1.2k' | '1.23k' | '1,234';

export interface PrettyNumber {
    value: number;
    suffix: string;
    remainder: number;
    remainderString: string;
    remainderSuffix: string;
}

const numberIntervals = [
    { interval: 'thousand', suffix: 'k', value: Math.pow(10, 3) },
    { interval: 'million', suffix: 'm', value: Math.pow(10, 6) },
    { interval: 'billion', suffix: 'b', value: Math.pow(10, 9) },
    { interval: 'trillion', suffix: 't', value: Math.pow(10, 12) },
    { interval: 'quadrillion', suffix: 'q', value: Math.pow(10, 15) },
    { interval: 'quintillion', suffix: 'Q', value: Math.pow(10, 18) },
];

const timeIntervals = [
    { interval: 'second', suffix: 'sec', value: 1000 },
    { interval: 'minute', suffix: 'min', value: 60000 },
    { interval: 'hour', suffix: 'hr', value: 3600000 },
    { interval: 'day', suffix: 'day', value: 86400000 },
    { interval: 'week', suffix: 'wk', value: 604800017 },
    { interval: 'month', suffix: 'mo', value: 2629800000 },
    { interval: 'year', suffix: 'yr', value: 31557600000 },
];

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const getTimeSince = (timestamp: number): number => {
    return Date.now() - timestamp;
};

export const getTimeTill = (timestamp: number): number => {
    return timestamp - Date.now();
};

export const getPrettyNumber = (
    raw: number,
    decimalPlaces: number,
    isPercentage: boolean,
    valueCase?: FontCase
): PrettyNumber => {
    if (raw === null || raw === undefined) {
        return {
            value: null,
            suffix: '',
            remainder: null,
            remainderSuffix: '',
            remainderString: '',
        };
    }

    const negativeNumber = raw <= 0 ? true : false;
    raw = round(Math.abs(raw), decimalPlaces);
    const number = formatNumber(raw, numberIntervals, decimalPlaces);

    const zeroPadLength =
        decimalPlaces > ('' + number.remainder).length
            ? decimalPlaces - ('' + number.remainder).length
            : 0;

    const suffix = isPercentage ? number.suffix + '%' : number.suffix;

    return {
        value: negativeNumber ? number.value * -1 : number.value,
        suffix: valueCase === 'uppercase' && !isPercentage ? _upperCase(suffix) : suffix,
        remainder: number.remainder,
        remainderString:
            decimalPlaces > 0 && number.remainder
                ? '.' + '0'.repeat(zeroPadLength) + number.remainder
                : '',
        remainderSuffix: number.remainderSuffix,
    };
};

export const getPrettyTimeInterval = (
    time: number,
    showTimeRemainder: boolean,
    roundSeconds?: boolean
): PrettyNumber => {
    // Get initial greatest whole time interval and any remaining time.
    const result = formatNumber(time, timeIntervals, 0, showTimeRemainder);

    const valueSecondsCheck = result.suffix === 'sec';
    const remainderSecondsCheck = result.suffix === 'min' && !!result.remainder;

    if ((valueSecondsCheck || remainderSecondsCheck) && roundSeconds) {
        // If remaining time is between 1 second and 59 seconds and roundSeconds, round value.
        const valueOverHalf = valueSecondsCheck && result.value >= 30;
        const remainderOverHalf = remainderSecondsCheck && result.remainder >= 30000;

        if (remainderOverHalf) {
            result.value++;
        } else if (valueOverHalf) {
            result.value = 1;
        }
        if (valueSecondsCheck && valueOverHalf) {
            result.suffix = 'min';
        }
        result.remainder = 0;
        result.remainderSuffix = '';
        return result;
    } else if (result.remainder && result.remainder >= 1000) {
        // If remaining time is 1 second or greater, find the greatest whole time interval of remaining time.
        const extra = formatNumber(result.remainder, timeIntervals, 1, showTimeRemainder);
        result.remainder = extra.value;
        result.remainderSuffix = extra.suffix;
        return result;
    } else {
        // If remaining time is less than second, disregard it.
        result.remainder = 0;
        result.remainderSuffix = '';
        return result;
    }
};

export const getPrettyMetric = ({
    value,
    showTimeRemainder,
    roundSeconds,
    decimals,
    type,
    isPercentage,
}: {
    type: FieldType;
    showTimeRemainder: boolean;
    roundSeconds: boolean;
    value: number;
    decimals: number;
    isPercentage: boolean;
}): Partial<PrettyNumber> => {
    return type === 'TIME_INTERVAL'
        ? getPrettyTimeInterval(value, showTimeRemainder, roundSeconds)
        : getPrettyNumber(value, decimals, isPercentage);
};

export const formatPrettyTimeInterval = (interval: PrettyNumber): string => {
    if (!interval.value) {
        return '0ms';
    }
    const prefix = `${interval.value}${interval.suffix}`;
    const suffix: string = interval.remainder
        ? ` ${Math.round(interval.remainder)}${interval.remainderSuffix}`
        : '';
    return `${prefix}${suffix}`;
};

export const getDecimalsByFormat = (
    format: DecimalFormat,
    isCurrency?: boolean,
    value?: number
): number => {
    // check to see if this value is between -1 and 1 and if so always format with 2 decimal places
    if (value !== undefined && value < 1 && value > -1) {
        return 2;
    }
    let decimals = 0;
    switch (format) {
        default:
        case '1k':
            decimals = 0;
            break;
        case '1.2k':
            decimals = 1;
            break;
        case '1.23k':
            decimals = 2;
            break;
        case '1,234':
            decimals = isCurrency ? 2 : 0;
            break;
    }
    return decimals;
};

export const getEndianTime = (
    timestamp: number,
    endianTime: string,
    timeZone?: string,
    language?: string
) => {
    const dateInstance = timeZone ? moment.tz(timestamp, timeZone) : moment(timestamp);
    applyLocaleToMoment(dateInstance, language);

    return dateInstance.format(endianTime);
};

export const getPrettyTimeAgo = (timestamp: number, ago = true) => {
    const agoText = i18n.t('ago');
    const minute = 1000 * 60;
    const hour = minute * 60;
    const day = hour * 24;
    const diff = Date.now() - Number(timestamp);
    const days = ` ${i18n.t('day', { count: Math.floor(diff / day) })} ${ago ? agoText : ''}`;

    if (diff < minute) {
        return i18n.t('Now');
    } else if (diff < hour) {
        return Math.floor(diff / minute) + `m ${ago ? agoText : ''}`;
    } else if (diff < day) {
        return Math.floor(diff / hour) + `h ${ago ? agoText : ''}`;
    } else if (diff < day * 8) {
        return Math.floor(diff / day) + days;
    } else {
        const then = new Date(timestamp);
        return months[then.getMonth()] + ' ' + then.getDate();
    }
};

export const getPrettyTimestamp = (timestamp: number, language?: string): string => {
    const index = timeIntervals;
    const diff = Date.now() - Number(timestamp);
    const min = index.findIndex(x => x.interval === 'minute');
    const max = index.findIndex(x => x.interval === 'day');

    if (diff === index[min].value) {
        return i18n.t('Now');
    } else if (diff >= index[max].value || diff <= index[max].value) {
        const dateInstance = moment(timestamp);
        applyLocaleToMoment(dateInstance, language);

        return dateInstance.format('MMM D');
    } else {
        const number = formatNumber(diff, timeIntervals, 0);
        return number.value + ' ' + number.suffix;
    }
};

const formatNumber = (
    number: number,
    index: any,
    decimalPlaces: number,
    roundDown?: boolean
): PrettyNumber => {
    // Go through each number or time interval to find the greatest whole interval.
    for (const i of index) {
        if (number < i.value) {
            const current = index.indexOf(i);
            if (current > 0) {
                const previous = current - 1;
                const val = round(number / index[previous].value, decimalPlaces, roundDown);
                const suf = index[previous].suffix;
                return {
                    value: val,
                    suffix: suf,
                    remainder: number % index[previous].value,
                    remainderSuffix: '',
                    remainderString: '',
                };
            } else {
                return {
                    value: number,
                    suffix: '',
                    remainder: null,
                    remainderSuffix: '',
                    remainderString: '',
                };
            }
        }
    }

    const prev = index.length - 1;
    const value = round(number / index[prev].value, decimalPlaces);
    const suffix = index[prev].suffix;
    return {
        value,
        suffix,
        remainder: number % index[prev].value,
        remainderSuffix: '',
        remainderString: '',
    };
};

export const round = (number: number, decimalPlaces?: number, roundDown?: boolean): number => {
    if (number === null || number === undefined) {
        return null;
    }

    if (!decimalPlaces) {
        return roundDown ? Math.floor(number) : Math.round(number);
    }

    const multiplier = Math.pow(10, decimalPlaces);
    return roundDown
        ? Math.floor(number * multiplier) / multiplier
        : Math.round(number * multiplier) / multiplier;
};

export const difference = (a: number, b: number): number => Math.abs(a - b);

/**
 * @param {string | number} percentage the pecentage
 * @param {number} parentSize size in pixels of the container
 * @param {boolean} useDivisor (optional) divides by 100 for percetange: 30%
 */
export const percentToPixel = (
    percentage: string | number,
    parentSize: number,
    useDivisor = true
) => {
    const parsedPercent: number = isNaN(percentage as number)
        ? stringToNum(percentage as string)
        : (percentage as number);
    const divisor = useDivisor ? 100 : 1;
    const result = (parsedPercent * parentSize) / divisor;
    return Math.round(result);
};

// match all numbers and start with - sign
const digitsRegex = /^[-]?[\d]+/g;

/**
 * transforms a string into a number i.e. 50% to 50
 * @param string string to transform
 */
export const stringToNum = (value: number | string): number => {
    const string = value + '';
    if (digitsRegex.test(string)) {
        const parsed = string.match(digitsRegex);
        return parseInt(parsed[0], 10);
    } else {
        return 0;
    }
};

/**
 * returns pixels in number from widget position
 * @param childPos child position key ie. widget.position.top
 * @param parentPos parent position width or height corresponding to childPos ie. parentNode.offsetHeight
 * @param paddingPixels panel/root widget padding
 * @param additionalOffset pixels to from tap or left like header
 */
export const getPxPosition = (
    childPos: number | string = '0',
    parentPos: number | string,
    paddingPixels = 0,
    additionalOffset = 0
): number => {
    if ((childPos as any)?.includes('%')) {
        const percentage = stringToNum(childPos) * 0.01;
        const padded = additionalOffset > 0 ? paddingPixels : paddingPixels * 2;
        const offset = additionalOffset === 0 ? 0 : padded - additionalOffset;

        return (
            percentToPixel(childPos, stringToNum(parentPos) - padded - additionalOffset) -
            (percentage * padded - offset) -
            (offset + percentage * additionalOffset)
        );
    }
    return stringToNum(childPos);
};

export function isNumBetween(x, min, max): boolean {
    return x >= min && x <= max;
}

export const arraySum = (data: number[]): number => {
    return data.reduce((prev, current) => prev + current, 0);
};

/**
 * Returns fallback if value is NaN. If fallback is ALSO NaN, null is returned.
 *
 * @param value returned unless isNaN(value) is true
 * @param fallback to use if value is NaN
 */
export function fallbackIfNaN(
    value: number,
    fallback: number | null | undefined
): number | null | undefined {
    return isNaN(value) ? (isNaN(fallback) ? null : fallback) : value;
}

export const getRandomNumber = (min: number, max: number, remainder = 0): number => {
    return round(Math.random() * (max - min) + min, remainder);
};

export const getRoundNumber = (number: number, decimalPlaces = 1, roundDown?: boolean): number => {
    const multiplier = Math.pow(10, decimalPlaces);
    return roundDown
        ? Math.floor(number * multiplier) / multiplier
        : Math.round(number * multiplier) / multiplier;
};

export const getPrettyNumberString = (
    rawNumber: number,
    numberFormat: DecimalFormat,
    valueCase?: FontCase
): string => {
    let numberString = '';
    let scale = '';

    if (numberFormat === '1,234') {
        // add commas to number and convert to string
        return rawNumber.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }

    if (isNaN(rawNumber) || !isFinite(rawNumber)) {
        numberString = 'N/A';
    } else {
        let absVal = Math.abs(rawNumber);
        if (absVal < 1000) {
            scale = '';
        } else if (absVal < 1000000) {
            scale = 'k';
            absVal = absVal / 1000;
        } else if (absVal < 1000000000) {
            scale = 'm';
            absVal = absVal / 1000000;
        } else if (absVal < 1000000000000) {
            scale = 'b';
            absVal = absVal / 1000000000;
        } else if (absVal < Math.pow(10, 15)) {
            scale = 't';
            absVal = absVal / Math.pow(10, 12);
        } else if (absVal < Math.pow(10, 18)) {
            scale = 'q';
            absVal = absVal / Math.pow(10, 15);
        } else if (absVal < Math.pow(10, 21)) {
            scale = 'Q';
            absVal = absVal / Math.pow(10, 18);
        }

        let decimals = 0;

        if (numberFormat === '1.2k') {
            decimals = 1;
        } else if (numberFormat === '1.23k') {
            decimals = 2;
        }

        if (valueCase === 'uppercase') {
            scale = _upperCase(scale);
        }

        const dropTrailingZero: number = parseFloat(absVal.toFixed(decimals));
        numberString = dropTrailingZero + scale;
    }

    // preserve negative number
    return rawNumber < 0 ? `-${numberString}` : numberString;
};

export function zeroIfNegative(value: string | number): number {
    const numberValue = Number(value);
    return numberValue < 0 ? 0 : numberValue;
}

export default getPrettyNumber;
