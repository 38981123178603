import { SingleTimePeriodFields } from 'models/TimePeriod/SingleTimePeriod';
import { action, observable } from 'mobx';
import {
    ContentLayer,
    ContentLayerWidget,
    MarqueeSlotOptions,
    SlotOptions,
} from 'components/contentLayer/types';
import { BackgroundImpl } from 'models/Theme/Theme';
import { BorderImpl } from '@sprinklr/display-builder/widgets/DataTableWidget/options';
import { scrubber } from 'utils/GenerateStyles/GenerateStyles';
import _merge from 'lodash/merge';

export const OverlayLayoutValueFields = `
    units
    value
`;

export const PostRequestFields = `
    source
    columnType
    columnId
    sorts {
        order
        heading
        isDimension
        details
    }

    timePeriod {
        ${SingleTimePeriodFields}
    }
    ignoreTimePeriod

    page
    pageSize
    mergeSources
`;

export const ContentLayerWidgetFields = `
    postRequests {
        ${PostRequestFields}
    }
    type
    enabled
    layout {
        height {
            ${OverlayLayoutValueFields}
        }
        width {
            ${OverlayLayoutValueFields}
        }
    }
    options
`;

export const ContentLayerFields = `
    enabled
    postRequests {
        ${PostRequestFields}
    }
    slots {
        interrupt {
            ${ContentLayerWidgetFields}
        }
        continuous {
            ${ContentLayerWidgetFields}
        }
    }
`;

export const COMMON_SLOT_OPTIONS = {
    curtain: {
        enabled: true,
        background: new BackgroundImpl(),
    },
    radius: 25,
    shadow: 'rgb(0, 0, 0, 0.4)',
    border: new BorderImpl(),
    demo: {
        enabled: false,
    },
};

export class ContentLayerImpl implements ContentLayer {
    @observable enabled = false;
    @observable postRequests = [
        {
            source: null,
            columnType: null,
            columnId: null,
        },
    ];
    @observable slots = {
        interrupt: {
            enabled: true,
            type: 'popup',
            layout: {
                height: {
                    units: '%',
                    value: 50,
                },
                width: {
                    units: '%',
                    value: 65,
                },
            },
            options: COMMON_SLOT_OPTIONS,
        } as ContentLayerWidget<'popup', SlotOptions>,
        continuous: {
            enabled: true,
            type: 'marquee',
            layout: {
                height: {
                    units: '%',
                    value: 4,
                },
                width: {
                    units: '%',
                    value: 100,
                },
            },
            options: {
                ...COMMON_SLOT_OPTIONS,
                vAlign: 'bottom',
                slideSpeed: 60,
                fontSizeMultiplier: 50,
                clock: {
                    enabled: true,
                },
                placeholder: {
                    enabled: false,
                    hideWhenData: false,
                    title: '',
                    message: '',
                },
            } as MarqueeSlotOptions,
        } as ContentLayerWidget<'marquee', MarqueeSlotOptions>,
    };

    @action
    setValues(opts?: any): ContentLayer {
        if (opts) {
            _merge(this, scrubber({ ...opts }));
        }
        return this;
    }
}
