export const GET_USER_AND_PARTNER = `{
    user {
        userId
        clientId
        partnerId
        groupIds
        userType
        visibleId
        firstName
        lastName
        emailAddress
        profileImageUrl
        locale
        permissions {
            DISPLAY_CREATE
            DISPLAY_DELETE
            DISPLAY_EDIT
            DISPLAY_PUBLISH
            DISPLAY_EDIT_URL_SECURITY
            PANEL_DELETE
            PANEL_EDIT_CSS_TAB
            PANEL_EDIT_JAVASCRIPT_TAB
            PANEL_EDIT_JSON_TAB
            STORYBOARD_CREATE
            STORYBOARD_DELETE
            STORYBOARD_DELETE_SCENE
            STORYBOARD_EDIT
            STORYBOARD_VIEW
            PRESENTATIONS_EDIT
            PRESENTATIONS_VIEW
            PRESENTATIONS_CREATE
            PRESENTATIONS_DELETE
            RESET_STYLES
            CREATE_WIDGET
            SHARE_URL
            PUBLISH
            DELETE_SLIDE
            EXPORT
            LOCK_WIDGET
            STYLE_KIT_CREATE
            STYLE_KIT_EDIT
            STYLE_KIT_APPLY
            GALLERY_MANAGER_DELETE
            GALLERY_MANAGER_EDIT
            GALLERY_MANAGER_CREATE
            GALLERY_MANAGER_VIEW
            GALLERY_EDITOR_SETTINGS
            GALLERY_EDITOR_DATA
            GALLERY_EDITOR_EDIT_JSON_TAB
            GALLERY_EDITOR_EXPORT
            GALLERY_EDITOR_EDIT
            GALLERY_EDITOR_JAVASCRIPT_TAB
            GALLERY_EDITOR_DESIGN
            GALLERY_EDITOR_CSS_TAB
        }
    }
    partner {
        id
        name
        domain
        enabledEngines
        measurementFilterEngines
        clients {
            clientId
            partnerId
            clientName
        }
        limit {
            partnerId
            wall
            single
            gallery
            custom
            sharingEnabled
            watermarkRequired
            watermarkDefault
            externalSsoUrlSecurity
            presentationsEnabled
            displayEnabled
            featuresEnabled
        }
    }
}`;

export const GET_CLIENT_USERS_AND_GROUPS = `{
    client {
        id
        clientUsers {
            userId
            firstName
            lastName
            emailAddress
            profileImageUrl
            visibleId
        }
        userGroups {
            id
            groupName
            description
            assetGroupType
            containedIds
            assetType
            clientId
            deleted
            ownerUserId
        }
    }
}`;
