import { FieldDataType } from '@sprinklr/stories/reporting/types';

export const getCurrencyString = (dataType?: FieldDataType): string => {
    const code = dataType?.includes('CURRENCY_') && dataType.replace('CURRENCY_', '');
    const result = code && currencies[code]?.symbol_native;
    return !!result ? result : '';
};

export const currencies = {
    AED: {
        symbol: 'AED',
        symbol_native: 'د.إ.‏',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x62f&#x2e&#x625',
        code: 'AED',
        prefix: false,
        name: 'United Arab Emirates Dirham',
    },
    AFN: {
        symbol: 'AFN',
        symbol_native: '؋',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x60b',
        code: 'AFN',
        prefix: false,
        name: 'Argentine Peso',
    },
    ALL: {
        symbol: 'ALL',
        symbol_native: 'Lek',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x4c&#x65&#x6b',
        code: 'ALL',
        prefix: true,
        name: 'Albanian Lek',
    },
    AMD: {
        symbol: 'AMD',
        symbol_native: 'դր.',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x58F',
        code: 'AMD',
        prefix: true,
        name: 'Armenian Dram',
    },
    AOA: {
        symbol: 'AOA',
        symbol_native: 'Kz',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x4B&#x7A',
        code: 'AOA',
        prefix: true,
        name: 'Angolan Kwanza',
    },
    ARS: {
        symbol: 'ARS',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'ARS',
        prefix: true,
        name: 'Argentine Peso',
    },
    AUD: {
        symbol: 'AU$',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'AUD',
        prefix: true,
        name: 'Australian Dollar',
    },
    AWG: {
        symbol: 'AWG',
        symbol_native: 'ƒ',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x192',
        code: 'AWG',
        prefix: true,
        name: 'Aruban Florin',
    },
    AZN: {
        symbol: 'AZN',
        symbol_native: 'ман',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x43c&#x430&#x43d',
        code: 'AZN',
        prefix: true,
        name: 'Azerbaijani Manat',
    },
    BAM: {
        symbol: 'BAM',
        symbol_native: 'KM',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x4b&#x4d',
        code: 'BAM',
        prefix: true,
        name: 'Bosnian Convertible Marka',
    },
    BBD: {
        symbol: 'BBD',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'BBD',
        prefix: true,
        name: 'Barbadian Dollar',
    },
    BDT: {
        symbol: 'BDT',
        symbol_native: 'Tk',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x9f3',
        code: 'BDT',
        prefix: true,
        name: 'Bangladeshi Taka',
    },
    BGN: {
        symbol: 'BGN',
        symbol_native: 'лв',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x43b&#x432',
        code: 'BGN',
        prefix: true,
        name: 'Bulgarian Lev',
    },
    BHD: {
        symbol: 'BHD',
        symbol_native: 'BD‏',
        decimal_digits: 3,
        rounding: 0,
        hex_code: '&#x42&#x44',
        code: 'BHD',
        prefix: true,
        name: 'Bahraini Dinar',
    },
    BIF: {
        symbol: 'BIF',
        symbol_native: 'FBu',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x46&#x42&#x75',
        code: 'BIF',
        prefix: true,
        name: 'Burundian Franc',
    },
    BMD: {
        symbol: 'BMD',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'BMD',
        prefix: true,
        name: 'Bermudian Dollar',
    },
    BND: {
        symbol: 'BND',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'BND',
        prefix: true,
        name: 'Brunei Dollar',
    },
    BOB: {
        symbol: 'BOB',
        symbol_native: '$b',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24&#x62',
        code: 'BOB',
        prefix: true,
        name: 'Bolivian Boliviano',
    },
    BRL: {
        symbol: 'R$',
        symbol_native: 'R$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x52&#x24',
        code: 'BRL',
        prefix: true,
        name: 'Brazilian Real',
    },
    BWP: {
        symbol: 'BWP',
        symbol_native: 'P',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x50',
        code: 'BWP',
        prefix: true,
        name: 'Botswana Pula',
    },
    BYR: {
        symbol: 'BYR',
        symbol_native: 'p.',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x70&#x2e',
        code: 'BYR',
        prefix: true,
        name: 'Belarusian Ruble',
    },
    BZD: {
        symbol: 'BZD',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x42&#x5a&#x24',
        code: 'BZD',
        prefix: true,
        name: 'Belize Dollar',
    },
    CAD: {
        symbol: 'CA$',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'CAD',
        prefix: true,
        name: 'Canadian Dollar',
    },
    CDF: {
        symbol: 'CDF',
        symbol_native: 'FC',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x46&#x43',
        code: 'CDF',
        prefix: true,
        name: 'Congolese Franc',
    },
    CHF: {
        symbol: 'CHF',
        symbol_native: 'CHF',
        decimal_digits: 2,
        rounding: 0.05,
        hex_code: '&#x43&#x48&#x46',
        code: 'CHF',
        prefix: true,
        name: 'Swiss Franc',
    },
    CLP: {
        symbol: 'CLP',
        symbol_native: '$',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x24',
        code: 'CLP',
        prefix: true,
        name: 'Chilean Peso',
    },
    CNY: {
        symbol: 'CNY',
        symbol_native: '¥',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#xa5',
        code: 'CNY',
        prefix: true,
        name: 'Chinese Yuan Renminbi',
    },
    COP: {
        symbol: 'COP',
        symbol_native: '$',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x24',
        code: 'COP',
        prefix: true,
        name: 'Colombian Peso',
    },
    CRC: {
        symbol: 'CRC',
        symbol_native: '₡',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x20a1',
        code: 'CRC',
        prefix: true,
        name: 'Costa Rican Colón',
    },
    CVE: {
        symbol: 'CVE',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'CVE',
        prefix: true,
        name: 'Cape Verdean Escudo',
    },
    CZK: {
        symbol: 'CZK',
        symbol_native: 'Kč',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x4b&#x10d',
        code: 'CZK',
        prefix: true,
        name: 'Czech Koruna',
    },
    DJF: {
        symbol: 'DJF',
        symbol_native: 'Fdj',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x46&#x64&#x6a',
        code: 'DJF',
        prefix: true,
        name: 'Djiboutian Franc',
    },
    DKK: {
        symbol: 'DKK',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x6b&#x72',
        code: 'DKK',
        prefix: true,
        name: 'Danish Krone',
    },
    DOP: {
        symbol: 'DOP',
        symbol_native: 'RD$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x52&#x44&#x24',
        code: 'DOP',
        prefix: true,
        name: 'Dominican Peso',
    },
    DZD: {
        symbol: 'DZD',
        symbol_native: 'DA‏',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x44&#x41',
        code: 'DZD',
        prefix: true,
        name: 'Algerian Dinar',
    },
    EGP: {
        symbol: 'EGP',
        symbol_native: '£',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#xa3',
        code: 'EGP',
        prefix: true,
        name: 'Egyptian Pound',
    },
    ERN: {
        symbol: 'ERN',
        symbol_native: 'Nfk',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x4e&#x66&#x6b',
        code: 'ERN',
        prefix: true,
        name: 'Eritrean Nakfa',
    },
    ETB: {
        symbol: 'ETB',
        symbol_native: 'Br',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x42&#x72',
        code: 'ETB',
        prefix: true,
        name: 'Ethiopian Birr',
    },
    EUR: {
        symbol: '€',
        symbol_native: '€',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x20ac',
        code: 'EUR',
        prefix: true,
        name: 'Euro',
    },
    GBP: {
        symbol: '£',
        symbol_native: '£',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#xa3',
        code: 'GBP',
        prefix: true,
        name: 'British Pound',
    },
    GEL: {
        symbol: 'GEL',
        symbol_native: 'ლ',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x10DA',
        code: 'GEL',
        prefix: true,
        name: 'Georgian Lari',
    },
    GHS: {
        symbol: 'GHS',
        symbol_native: 'GH¢',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x47&#x48&#x20b5',
        code: 'GHS',
        prefix: true,
        name: 'Ghanaian Cedi',
    },
    GNF: {
        symbol: 'GNF',
        symbol_native: 'FG',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x46&#x47',
        code: 'GNF',
        prefix: true,
        name: 'Guinean Franc',
    },
    GTQ: {
        symbol: 'GTQ',
        symbol_native: 'Q',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x51',
        code: 'GTQ',
        prefix: true,
        name: 'Guatemalan Quetzal',
    },
    GYD: {
        symbol: 'GYD',
        symbol_native: '$',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x24',
        code: 'GYD',
        prefix: true,
        name: 'Guyanese Dollar',
    },
    HKD: {
        symbol: 'HK$',
        symbol_native: 'HK$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'HKD',
        prefix: true,
        name: 'Hong Kong Dollar',
    },
    HNL: {
        symbol: 'HNL',
        symbol_native: 'L',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x4c',
        code: 'HNL',
        prefix: true,
        name: 'Honduran Lempira',
    },
    HRK: {
        symbol: 'HRK',
        symbol_native: 'kn',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x6b&#x6e',
        code: 'HRK',
        prefix: true,
        name: 'Croatian Kuna',
    },
    HUF: {
        symbol: 'HUF',
        symbol_native: 'Ft',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x46&#x74',
        code: 'HUF',
        prefix: true,
        name: 'Hungarian Forint',
    },
    IDR: {
        symbol: 'IDR',
        symbol_native: 'Rp',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x52&#x70',
        code: 'IDR',
        prefix: true,
        name: 'Indonesian Rupiah',
    },
    ILS: {
        symbol: '₪',
        symbol_native: '₪',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x20aa',
        code: 'ILS',
        prefix: true,
        name: 'Israeli New Shekel',
    },
    INR: {
        symbol: '₹',
        symbol_native: '₹',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x20B9',
        code: 'INR',
        prefix: true,
        name: 'Indian Rupee',
    },
    IQD: {
        symbol: 'IQD',
        symbol_native: 'د.ع.‏',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x49&#x51&#x44',
        code: 'IQD',
        prefix: false,
        name: 'Iraqi Dinar',
    },
    IRR: {
        symbol: 'IRR',
        symbol_native: '﷼',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#xfdfc',
        code: 'IRR',
        prefix: false,
        name: 'Iranian Rial',
    },
    ISK: {
        symbol: 'ISK',
        symbol_native: 'kr',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x6b&#x72',
        code: 'ISK',
        prefix: true,
        name: 'Icelandic Króna',
    },
    JMD: {
        symbol: 'JMD',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x4a&#x24',
        code: 'JMD',
        prefix: true,
        name: 'Jamaican Dollar',
    },
    JOD: {
        symbol: 'JOD',
        symbol_native: 'د.أ.‏',
        decimal_digits: 3,
        rounding: 0,
        hex_code: '&#x4a&#x4f&#x44',
        code: 'JOD',
        prefix: false,
        name: 'Jordanian Dinar',
    },
    JPY: {
        symbol: '¥',
        symbol_native: '￥',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#xa5',
        code: 'JPY',
        prefix: true,
        name: 'Japanese Yen',
    },
    KES: {
        symbol: 'KES',
        symbol_native: 'KSh',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x4B&#x53&#x68',
        code: 'KES',
        prefix: true,
        name: 'Kenyan Shilling',
    },
    KHR: {
        symbol: 'KHR',
        symbol_native: '៛',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x17db',
        code: 'KHR',
        prefix: true,
        name: 'Cambodian Riel',
    },
    KMF: {
        symbol: 'KMF',
        symbol_native: 'CF',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x43&#x46',
        code: 'KMF',
        prefix: true,
        name: 'Comorian Franc',
    },
    KRW: {
        symbol: '₩',
        symbol_native: '₩',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x20a9',
        code: 'KRW',
        prefix: true,
        name: 'South Korean Won',
    },
    KWD: {
        symbol: 'KWD',
        symbol_native: 'د.ك.‏',
        decimal_digits: 3,
        rounding: 0,
        hex_code: '&#x20&#x4b&#x2e&#x44&#x2e',
        code: 'KWD',
        prefix: false,
        name: 'Kuwaiti Dinar',
    },
    KZT: {
        symbol: 'KZT',
        symbol_native: 'лв₸',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x43b&#x432',
        code: 'KZT',
        prefix: true,
        name: 'Kazakhstani Tenge',
    },
    LBP: {
        symbol: 'LBP',
        symbol_native: 'ل.ل.‏',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#xa3',
        code: 'LBP',
        prefix: false,
        name: 'Lebanese Pound',
    },
    LKR: {
        symbol: 'LKR',
        symbol_native: 'රු.',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x20a8',
        code: 'LKR',
        prefix: true,
        name: 'Sri Lankan Rupee',
    },
    LRD: {
        symbol: 'LRD',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'LRD',
        prefix: true,
        name: 'Liberian Dollar',
    },
    LTL: {
        symbol: 'LTL',
        symbol_native: 'Lt',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x4c&#x74',
        code: 'LTL',
        prefix: true,
        name: 'Lithuanian Litas',
    },
    LVL: {
        symbol: 'LVL',
        symbol_native: 'Ls',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x4c&#x73',
        code: 'LVL',
        prefix: true,
        name: 'Latvian Lats',
    },
    LYD: {
        symbol: 'LYD',
        symbol_native: 'LD‏',
        decimal_digits: 3,
        rounding: 0,
        hex_code: '&#x4c&#x44',
        code: 'LYD',
        prefix: true,
        name: 'Libyan Dinar',
    },
    MAD: {
        symbol: 'MAD',
        symbol_native: 'DH‏',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x2e&#x62f&#x2e&#x645',
        code: 'MAD',
        prefix: true,
        name: 'Moroccan Dirham',
    },
    MDL: {
        symbol: 'MDL',
        symbol_native: 'MDL',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x4d&#x44&#x4c',
        code: 'MDL',
        prefix: true,
        name: 'Moldovan Leu',
    },
    MGA: {
        symbol: 'MGA',
        symbol_native: 'Ar',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x41&#x72',
        code: 'MGA',
        prefix: true,
        name: 'Malagasy Ariary',
    },
    MKD: {
        symbol: 'MKD',
        symbol_native: 'ден',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x434&#x435&#x43d',
        code: 'MKD',
        prefix: true,
        name: 'Macedonian Denar',
    },
    MMK: {
        symbol: 'MMK',
        symbol_native: 'K',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x4b',
        code: 'MMK',
        prefix: true,
        name: 'Burmese Kyat',
    },
    MOP: {
        symbol: 'MOP',
        symbol_native: 'MOP$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x4d&#x4f&#x50&#x24',
        code: 'MOP',
        prefix: true,
        name: 'Macanese Pataca',
    },
    MUR: {
        symbol: 'MUR',
        symbol_native: 'Rs',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x20a8',
        code: 'MUR',
        prefix: true,
        name: 'Mauritian Rupee',
    },
    MXN: {
        symbol: 'MX$',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'MXN',
        prefix: true,
        name: 'Mexican Peso',
    },
    MYR: {
        symbol: 'MYR',
        symbol_native: 'RM',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x52&#x4d',
        code: 'MYR',
        prefix: true,
        name: 'Malaysian Ringgit',
    },
    MZN: {
        symbol: 'MZN',
        symbol_native: 'MTn',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x4d&#x54',
        code: 'MZN',
        prefix: true,
        name: 'Mozambican Metical',
    },
    NAD: {
        symbol: 'NAD',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'NAD',
        prefix: true,
        name: 'Namibian Dollar',
    },
    NGN: {
        symbol: 'NGN',
        symbol_native: '₦',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x20a6',
        code: 'NGN',
        prefix: true,
        name: 'Nigerian Naira',
    },
    NIO: {
        symbol: 'NIO',
        symbol_native: 'C$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x43&#x24',
        code: 'NIO',
        prefix: true,
        name: 'Nicaraguan Córdoba',
    },
    NOK: {
        symbol: 'NOK',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x6b&#x72',
        code: 'NOK',
        prefix: true,
        name: 'Norwegian Krone',
    },
    NPR: {
        symbol: 'NPR',
        symbol_native: 'Rs',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x20a8',
        code: 'NPR',
        prefix: true,
        name: 'Nepalese Rupee',
    },
    NZD: {
        symbol: 'NZ$',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'NZD',
        prefix: true,
        name: 'New Zealand Dollar',
    },
    OMR: {
        symbol: 'OMR',
        symbol_native: 'ر.ع.‏',
        decimal_digits: 3,
        rounding: 0,
        hex_code: '&#xfdfc',
        code: 'OMR',
        prefix: false,
        name: 'Omani Rial',
    },
    PAB: {
        symbol: 'PAB',
        symbol_native: 'B/.',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x42&#x2f&#x2e',
        code: 'PAB',
        prefix: true,
        name: 'Panamanian Balboa',
    },
    PEN: {
        symbol: 'PEN',
        symbol_native: 'S/.',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x53&#x2f&#x2e',
        code: 'PEN',
        prefix: true,
        name: 'Peruvian Nuevo Sol',
    },
    PHP: {
        symbol: 'PHP',
        symbol_native: '₱',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x20b1',
        code: 'PHP',
        prefix: true,
        name: 'Philippine Peso',
    },
    PKR: {
        symbol: 'PKR',
        symbol_native: 'Rs',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x20a8',
        code: 'PKR',
        prefix: true,
        name: 'Pakistani Rupee',
    },
    PLN: {
        symbol: 'PLN',
        symbol_native: 'zł',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x7a&#x142',
        code: 'PLN',
        prefix: true,
        name: 'Polish Złoty',
    },
    PYG: {
        symbol: 'PYG',
        symbol_native: '₲',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x47&#x73',
        code: 'PYG',
        prefix: true,
        name: 'Paraguayan Guaraní',
    },
    QAR: {
        symbol: 'QAR',
        symbol_native: 'ر.ق.‏',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#xfdfc',
        code: 'QAR',
        prefix: false,
        name: 'Qatari Riyal',
    },
    RON: {
        symbol: 'RON',
        symbol_native: 'lei',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x6c&#x65&#x69',
        code: 'RON',
        prefix: true,
        name: 'Romanian Leu',
    },
    RSD: {
        symbol: 'RSD',
        symbol_native: 'дин.',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x414&#x438&#x43d&#x2e',
        code: 'RSD',
        prefix: true,
        name: 'Serbian Dinar',
    },
    RUB: {
        symbol: 'RUB',
        symbol_native: 'руб',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x440&#x443&#x431',
        code: 'RUB',
        prefix: true,
        name: 'Russian Ruble',
    },
    RWF: {
        symbol: 'RWF',
        symbol_native: 'FR',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x46&#x52',
        code: 'RWF',
        prefix: true,
        name: 'Rwandan Franc',
    },
    SAR: {
        symbol: 'SAR',
        symbol_native: 'ر.س.‏',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#xfdfc',
        code: 'SAR',
        prefix: false,
        name: 'Saudi Riyal',
    },
    SDG: {
        symbol: 'SDG',
        symbol_native: 'ج.س.',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x53&#x44&#x47',
        code: 'SDG',
        prefix: true,
        name: 'Sudanese Pound',
    },
    SEK: {
        symbol: 'SEK',
        symbol_native: 'kr',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x6b&#x72',
        code: 'SEK',
        prefix: true,
        name: 'Swedish Krona',
    },
    SGD: {
        symbol: 'SGD',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'SGD',
        prefix: true,
        name: 'Singapore Dollar',
    },
    SOS: {
        symbol: 'SOS',
        symbol_native: 'So. Sh.',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x53',
        code: 'SOS',
        prefix: true,
        name: 'Somali Shilling',
    },
    STD: {
        symbol: 'STD',
        symbol_native: 'Db',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x44&#x62',
        code: 'STD',
        prefix: true,
        name: 'São Tomé and Príncipe Dobra',
    },
    SYP: {
        symbol: 'SYP',
        symbol_native: '£‏',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#xa3',
        code: 'SYP',
        prefix: true,
        name: 'Syrian Pound',
    },
    THB: {
        symbol: '฿',
        symbol_native: '฿',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#xe3f',
        code: 'THB',
        prefix: true,
        name: 'Thai Baht',
    },
    TND: {
        symbol: 'TND',
        symbol_native: 'DT‏',
        decimal_digits: 3,
        rounding: 0,
        hex_code: '&#x44&#x54',
        code: 'TND',
        prefix: true,
        name: 'Tunisian Dinar',
    },
    TOP: {
        symbol: 'TOP',
        symbol_native: 'T$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x54&#x24',
        code: 'TOP',
        prefix: true,
        name: 'Tongan Paʻanga',
    },
    TRY: {
        symbol: 'TRY',
        symbol_native: 'TL',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x54&#x4c',
        code: 'TRY',
        prefix: true,
        name: 'Turkish Lira',
    },
    TTD: {
        symbol: 'TTD',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x54&#x54&#x24',
        code: 'TTD',
        prefix: true,
        name: 'Trinidad and Tobago Dollar',
    },
    TWD: {
        symbol: 'NT$',
        symbol_native: 'NT$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x4e&#x54&#x24',
        code: 'TWD',
        prefix: true,
        name: 'New Taiwan Dollar',
    },
    TZS: {
        symbol: 'TZS',
        symbol_native: 'TSh',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x54&#x53&#x68',
        code: 'TZS',
        prefix: true,
        name: 'Tanzanian shilling',
    },
    UAH: {
        symbol: 'UAH',
        symbol_native: '₴',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x20b4',
        code: 'UAH',
        prefix: true,
        name: 'Ukrainian Hryvnia',
    },
    UGX: {
        symbol: 'UGX',
        symbol_native: 'USh',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x55&#x53&#x68',
        code: 'UGX',
        prefix: true,
        name: 'Ugandan Shilling',
    },
    USD: {
        symbol: '$',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24',
        code: 'USD',
        prefix: true,
        name: 'United States Dollar',
    },
    UYU: {
        symbol: 'UYU',
        symbol_native: '$',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x24&#x55',
        code: 'UYU',
        prefix: true,
        name: 'Uruguayan Peso',
    },
    UZS: {
        symbol: 'UZS',
        symbol_native: 'лв',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x43b&#x432',
        code: 'UZS',
        prefix: true,
        name: 'Uzbekistani Som',
    },
    VEF: {
        symbol: 'VEF',
        symbol_native: 'Bs.',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x42&#x73',
        code: 'VEF',
        prefix: true,
        name: 'Venezuelan Bolívar',
    },
    VND: {
        symbol: '₫',
        symbol_native: '₫',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x20ab',
        code: 'VND',
        prefix: true,
        name: 'Vietnamese Dồng',
    },
    XAF: {
        symbol: 'FCFA',
        symbol_native: 'FCFA',
        decimal_digits: 0,
        rounding: 0,
        code: 'XAF',
        hex_code: '&#x46&#x43&#x46&#x41',
        prefix: true,
        name: 'Central African CFA Franc',
    },
    XOF: {
        symbol: 'CFA',
        symbol_native: 'CFA',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x43&#x46&#x41',
        code: 'XOF',
        prefix: true,
        name: 'West African CFA Franc',
    },
    YER: {
        symbol: 'YER',
        symbol_native: 'ر.ي.‏',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#xfdfc',
        code: 'YER',
        prefix: false,
        name: 'Yemeni Rial',
    },
    ZAR: {
        symbol: 'ZAR',
        symbol_native: 'R',
        decimal_digits: 2,
        rounding: 0,
        hex_code: '&#x52',
        code: 'ZAR',
        prefix: true,
        name: 'South African Rand',
    },
    ZMK: {
        symbol: 'ZMK',
        symbol_native: 'ZK',
        decimal_digits: 0,
        rounding: 0,
        hex_code: '&#x5a&#x4b',
        code: 'ZMK',
        prefix: true,
        name: 'Zambian Kwacha',
    },
};
