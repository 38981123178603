import { action, computed, observable } from 'mobx';
import Axios, { AxiosInstance } from 'axios';
import { GraphQLService } from 'services/GraphQLService/GraphQLService';
import { PartnerLimit } from 'models/PartnerLimit/PartnerLimit';
import jsonp from 'jsonp';
import { ApplicationMode, EnvironmentConfig } from '../../config';
import { WatermarkImpl } from 'models/Watermark/WatermarkImpl';
import { Watermark } from 'models/Watermark/Watermark';
import { Template } from 'utils/resolveAvailableTemplates/resolveAvailableTemplates';
import { GET_CLIENT_USERS_AND_GROUPS, GET_USER_AND_PARTNER } from 'services/Auth/queries';
import { ShareableComponent } from 'models/AccessControl/AccessControl';
import config from '@sprinklr/display-builder/config';
import { apolloClient } from 'utils/apollo';
import { HttpLink } from '@apollo/client';
import omitDeep from 'omit-deep';
import { authService } from '@sprinklr/display-builder/serviceContext/authService';

export type UserType = 'PRTUSER' | 'PRTADMN' | 'CLIADMN' | 'CLIUSER' | 'SUPUSER' | 'SYNDUSER';

const MULTI_CLIENT_USER_TYPES: UserType[] = ['PRTUSER', 'PRTADMN', 'SUPUSER', 'CLIADMN'];

/**
 * From Sprinklr Core https://sprinklr.atlassian.net/browse/SPR-39169
 * DISPLAY_DISPLAY(CREATE, EDIT, DELETE, EDIT_URL_SECURITY)
 * DISPLAY_STORYBOARD(CREATE, EDIT, DELETE, DELETE_SCENE)
 * DISPLAY_PANEL(DELETE, EDIT_CSS_TAB, EDIT_JSON_TAB, EDIT_JAVASCRIPT_TAB)
 */
export type DisplayPermission =
    | 'DISPLAY_CREATE'
    | 'DISPLAY_EDIT'
    | 'DISPLAY_PUBLISH'
    | 'DISPLAY_DELETE'
    | 'DISPLAY_EDIT_URL_SECURITY'
    | 'STORYBOARD_CREATE'
    | 'STORYBOARD_VIEW'
    | 'STORYBOARD_EDIT'
    | 'STORYBOARD_DELETE'
    | 'STORYBOARD_DELETE_SCENE'
    | 'PANEL_DELETE'
    | 'PANEL_EDIT_CSS_TAB'
    | 'PANEL_EDIT_JSON_TAB'
    | 'PANEL_EDIT_JAVASCRIPT_TAB'
    | 'PRESENTATIONS_EDIT'
    | 'PRESENTATIONS_VIEW'
    | 'PRESENTATIONS_CREATE'
    | 'PRESENTATIONS_DELETE'
    | 'RESET_STYLES'
    | 'CREATE_WIDGET'
    | 'SHARE_URL'
    | 'PUBLISH'
    | 'DELETE_SLIDE'
    | 'EXPORT'
    | 'LOCK_WIDGET'
    | 'STYLE_KIT_APPLY'
    | 'STYLE_KIT_CREATE'
    | 'STYLE_KIT_EDIT'
    | 'PUBLIC_EMBED_DISPLAY_TYPE'
    | 'GALLERY_MANAGER_DELETE'
    | 'GALLERY_MANAGER_EDIT'
    | 'GALLERY_MANAGER_CREATE'
    | 'GALLERY_MANAGER_VIEW'
    | 'GALLERY_EDITOR_SETTINGS'
    | 'GALLERY_EDITOR_DATA'
    | 'GALLERY_EDITOR_EDIT_JSON_TAB'
    | 'GALLERY_EDITOR_EXPORT'
    | 'GALLERY_EDITOR_EDIT'
    | 'GALLERY_EDITOR_JAVASCRIPT_TAB'
    | 'GALLERY_EDITOR_DESIGN'
    | 'GALLERY_EDITOR_CSS_TAB';

const DISPLAY_APP_PERMISSIONS: DisplayPermission[] = [
    'DISPLAY_CREATE',
    'DISPLAY_EDIT',
    'DISPLAY_PUBLISH',
    'DISPLAY_DELETE',
    'DISPLAY_EDIT_URL_SECURITY',
    'STORYBOARD_CREATE',
    'STORYBOARD_VIEW',
    'STORYBOARD_EDIT',
    'STORYBOARD_DELETE',
    'STORYBOARD_DELETE_SCENE',
    'PANEL_DELETE',
    'PANEL_EDIT_CSS_TAB',
    'PANEL_EDIT_JSON_TAB',
    'PANEL_EDIT_JAVASCRIPT_TAB',
];

const PRESENTATIONS_APP_PERMISSIONS: DisplayPermission[] = [
    'PRESENTATIONS_EDIT',
    'PRESENTATIONS_VIEW',
    'PRESENTATIONS_CREATE',
    'PRESENTATIONS_DELETE',
    'RESET_STYLES',
    'CREATE_WIDGET',
    'SHARE_URL',
    'PUBLISH',
    'DELETE_SLIDE',
    'EXPORT',
    'LOCK_WIDGET',
    'STYLE_KIT_APPLY',
    'STYLE_KIT_CREATE',
    'STYLE_KIT_EDIT',
];

export interface UserPermissions {
    DISPLAY_CREATE: boolean;
    DISPLAY_DELETE: boolean;
    DISPLAY_EDIT: boolean;
    DISPLAY_PUBLISH: boolean;
    DISPLAY_EDIT_URL_SECURITY: boolean;
    PANEL_DELETE: boolean;
    PANEL_EDIT_CSS_TAB: boolean;
    PANEL_EDIT_JAVASCRIPT_TAB: boolean;
    PANEL_EDIT_JSON_TAB: boolean;
    STORYBOARD_CREATE: boolean;
    STORYBOARD_DELETE: boolean;
    STORYBOARD_DELETE_SCENE: boolean;
    STORYBOARD_EDIT: boolean;
    PRESENTATIONS_EDIT: boolean;
    PRESENTATIONS_VIEW: boolean;
    PRESENTATIONS_CREATE: boolean;
    PRESENTATIONS_DELETE: boolean;
    RESET_STYLES: boolean;
    CREATE_WIDGET: boolean;
    SHARE_URL: boolean;
    PUBLISH: boolean;
    DELETE_SLIDE: boolean;
    EXPORT: boolean;
    LOCK_WIDGET: boolean;
    STYLE_KIT_APPLY: boolean;
    STYLE_KIT_CREATE: boolean;
    STYLE_KIT_EDIT: boolean;
    GALLERY_MANAGER_DELETE: boolean;
    GALLERY_MANAGER_EDIT: boolean;
    GALLERY_MANAGER_CREATE: boolean;
    GALLERY_MANAGER_VIEW: boolean;
    GALLERY_EDITOR_SETTINGS: boolean;
    GALLERY_EDITOR_DATA: boolean;
    GALLERY_EDITOR_EDIT_JSON_TAB: boolean;
    GALLERY_EDITOR_EXPORT: boolean;
    GALLERY_EDITOR_EDIT: boolean;
    GALLERY_EDITOR_JAVASCRIPT_TAB: boolean;
    GALLERY_EDITOR_DESIGN: boolean;
    GALLERY_EDITOR_CSS_TAB: boolean;
}

export type UserInfo = {
    userId: number;
    userType: UserType;
    clientId: number;
    groupIds: string[];
    partnerId: number;
    createdTime: string;
    modifiedTime: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    designation: string;
    department: string;
    properties: string;
    isDeleted: boolean;
    currentPartnerId: number;
    passwordSetTime: string;
    loginRestrictedIPs: any[];
    profileImageUrl: string;
    passwordLoginDisabled: boolean;
    locale: string;
    visibleId: string;
    permissions: UserPermissions;
};

export type ClientUser = Omit<UserInfo, 'permissions' | 'groupIds'>;

export interface ClientInfo {
    clientId: number;
    partnerId: number;
    clientName: string;
}

export interface GroupInfo {
    id: string;
    groupName: string;
    description: string;
    assetGroupType: string;
    containedIds: string[];
    assetType: string;
    clientId: number;
    deleted: boolean;
    ownerUserId: number;
}

export interface SessionContext {
    user: UserInfo;
    partner: {
        id: number;
        enabledEngines: { string: boolean };
        measurementFilterEngines: { string: boolean };
        clients: ClientInfo[];
        limit: PartnerLimit;
        domain?: string;
        name?: string;
    };
}

export enum FEATURE_FLAG {
    DASHBOARD_WIDGET_IMPORT = 'dashboard-widget-import',
    DISPLAY_URL_SECURITY_HMAC = 'display-url-security-hmac',
    DISPLAY_URL_SECURITY_OPEN_ID_CONNECT = 'display-url-security-open-id-connect',
    GLOBAL_DATA_SETTINGS = 'global-data-settings',
    FEATURE_REMOTE_CUSTOM_CSS = 'remote-custom-css',
    FEATURE_TOUCHSCREEN = 'touchscreen',
    FEATURE_TOUCHSCREEN_MOUSE_SUPPORT = 'touchscreen-mouse-support',
    FEATURE_MAP_STATE_COUNTY = 'map-state-county',
    GALLERY_ANALYTICS_ENABLED = 'gallery-analytics-enabled',
    GALLERY_V2_ENABLED = 'gallery-v2-enabled',
    SHOPPABLE_PRODUCT_TAGS_ENABLED = 'shoppable-product-tags-enabled', // New integration with Space product tagging
    REAL_TIME_REFRESH = 'real-time-refresh',
    STORYBOARD_ALERTS = 'storyboard-alerts',
    VOICIFY_SCRIPT = 'voicify-script',
    REMOTE_LENS = 'remote-lens',
}

export type ClientChangeHandler = (clientId: number) => void;

export default class AuthService {
    @observable token: string;

    @observable isLoggedIn: boolean = null;

    @observable userInfo: UserInfo = null;

    // this will generally be the same as userInfo.clientId, except for partner users when they've switched.
    @observable activeClientId: number = null;

    @observable sessionContext: SessionContext = null;
    @observable clientUsers: ClientUser[];
    @observable clientUserGroups: GroupInfo[];

    @computed get externalSsoUrlSecurity(): boolean {
        return (
            this.sessionContext &&
            this.sessionContext.partner &&
            this.sessionContext.partner.limit[0] &&
            this.sessionContext.partner.limit[0].externalSsoUrlSecurity
        );
    }

    presentationsModuleEnabled = false;
    displayModuleEnabled = false;
    allowAllPartners = false;
    featuresEnabled: string[] = null;

    axios: AxiosInstance;
    graphQLService: GraphQLService;
    sprinklrRoot: string;

    badSessionListeners: Array<{ () }> = [];
    clientChangeListeners: ClientChangeHandler[] = [];

    partnerSkuLimitDisplay: Record<string, any> = null;
    partnerSkuLimitEmbed: number;

    engineBlacklist: string[] = [];
    engineMeasurementFilter: { [engine: string]: boolean } = {};

    errorMessage: string = null;

    static noPartnerLimitValue = 0;
    static defaultPartnerSharingValue = false;
    static unlimitedPartnerLimitValue = 500;

    static noPartnerMsg = 'No valid partner found!';

    static unlimitedPartnerLimitObject = {
        SINGLE_DISPLAY: AuthService.unlimitedPartnerLimitValue,
        DISPLAY_WALL: AuthService.unlimitedPartnerLimitValue,
        LOGO_REQUIRED: false,
        WATERMARK_REQUIRED: true,
        WATERMARK_DEFAULT: true,
    };

    static limitedAvailabilityConfig = {
        geoStream: [349, 1108, 1436], // need to add partner ID for starTV
    };

    private env: EnvironmentConfig;

    @computed
    get isPresentationsMode(): boolean {
        return !this.userInfo ? false : this.env.applicationMode === 'PRESENTATIONS';
    }

    @computed
    get isDisplayMode(): boolean {
        return !this.userInfo ? false : this.env.applicationMode === 'DISPLAY';
    }

    @computed
    get isEmbedMode(): boolean {
        return !this.userInfo ? false : this.env.applicationMode === 'EMBED';
    }

    isFeatureEnabled(feature: FEATURE_FLAG): boolean {
        if (this.env.developmentMode) {
            return true;
        }

        if (!this.featuresEnabled) {
            return false;
        }
        return this.featuresEnabled.indexOf(feature) !== -1;
    }

    constructor(axios: AxiosInstance, graphQLService: GraphQLService, env: EnvironmentConfig) {
        this.axios = axios;
        this.graphQLService = graphQLService;
        this.sprinklrRoot = env.sprinklrRoot;
        this.env = env;

        if (config.useSkuLimits === false) {
            console.info('sku limits are disabled.');
            this.allowAllPartners = true;
        }

        const onBadSession = action(() => {
            console.error('Bad session token');
            this.isLoggedIn = false;
            this.userInfo = null;
            this.activeClientId = null;

            this.notifyBadSession();
        });

        // Add a response interceptor
        if (!env.sandboxed)
            axios.interceptors.response.use(
                function(response) {
                    if (
                        response &&
                        response.data &&
                        response.data.errors &&
                        response.data.errors.length > 0
                    ) {
                        const errors = response.data.errors;

                        // look for 401 error in GraphQL errors
                        errors.forEach(error => {
                            if (error.exception && error.exception.message) {
                                if (error.exception.message.indexOf('401 ') !== -1) {
                                    this.errorMessage = error.exception.message;
                                    onBadSession();
                                }
                            }
                        });
                    }

                    // Do something with response data
                    return response;
                },
                (error: any) => {
                    if (error.response) {
                        // The request was made, but the server responded with a status code
                        // that falls out of the range of 2xx
                        // console.log('Error response', error.response.status, error.response);

                        if (error.response.status === 401) {
                            this.errorMessage =
                                error.response.data && error.response.data.message
                                    ? error.response.data.message
                                    : 'Error response ' + error.response.status;
                            onBadSession();
                        }
                    } else {
                        if (!Axios.isCancel(error)) {
                            // no need to log cancelled promises
                            // Something happened in setting up the request that triggered an Error
                            console.log('Error', error.message || error);
                        }
                    }

                    // Do something with response error
                    return Promise.reject(error);
                }
            );
    }

    notifyBadSession() {
        // console.log('notifyBadSession...', this.accessDeniedListeners);

        this.badSessionListeners.forEach(callback => {
            if (typeof callback === 'function') {
                callback();
            }
        });
    }

    onBadSession(callback: { () }) {
        this.badSessionListeners.push(callback);
    }

    offBadSession(callback: { () }) {
        const index = this.badSessionListeners.indexOf(callback);
        if (index !== -1) {
            this.badSessionListeners.splice(index, 1);
        }
    }

    onClientChange(callback: ClientChangeHandler) {
        this.clientChangeListeners.push(callback);
    }

    offClientChange(callback: ClientChangeHandler) {
        const index = this.clientChangeListeners.indexOf(callback);
        if (index !== -1) {
            this.clientChangeListeners.splice(index, 1);
        }
    }

    notifyClientChanged(clientId: number) {
        this.clientChangeListeners.forEach(callback => {
            if (typeof callback === 'function') {
                callback(clientId);
            }
        });
    }

    hasModule(module: ApplicationMode) {
        if (!this.userInfo) {
            return false;
        }

        if (this.allowAllPartners) {
            return true;
        }
        if (module === 'DISPLAY') {
            return this.isDisplayEnabled;
        }

        if (module === 'EMBED') {
            return this.isEmbedEnabled;
        }

        if (module === 'PRESENTATIONS') {
            return this.isPresentationsEnabled;
        }
        return true; // shouldn't happen, but don't break anything if it does
    }

    @computed
    get isAllPartnersAllowed(): boolean {
        return this.allowAllPartners;
    }

    @computed
    get isPresentationsAllowed() {
        return this.isAllPartnersAllowed ? true : this.isPresentationsEnabled;
    }

    @computed
    get isDisplayAllowed() {
        return this.isAllPartnersAllowed ? true : this.isDisplayEnabled;
    }

    @computed
    get isEmbedEnabled(): boolean {
        return true; // no sku enforcement yet
    }

    isPartnerAllowedDisplay(): boolean {
        if (this.allowAllPartners) {
            return true;
        }
        return this.partnerSkuLimitDisplay && !!this.partnerSkuLimitDisplay['DISPLAY_ENABLED'];
    }

    @computed
    get isDisplayEnabled(): boolean {
        return this.displayModuleEnabled && this.isDisplayMode;
    }

    @computed
    get isPresentationsEnabled(): boolean {
        return this.presentationsModuleEnabled && this.isPresentationsMode;
    }

    getDisplayCount(displayType: string) {
        // console.log("getDisplayCount()", " displayType ", displayType);
        let displayCount;
        const skuLimit = this.getPartnerDisplayLimit;
        // console.log("partnerSkuLimitDisplay", skuLimit);

        if (typeof skuLimit[displayType] == 'undefined') {
            displayCount = 0;
        } else {
            displayCount = skuLimit[displayType];
            // console.log("displayCount", displayCount, "displayType", displayType);
        }

        return displayCount;
    }

    @computed
    get getPartnerEmbedLimit(): number {
        if (this.allowAllPartners) {
            console.log('allow all partners');
            // no whitelist means no SKU list either...
            return AuthService.unlimitedPartnerLimitValue;
        }

        if (this.isEmbedEnabled) {
            console.log('partner is allowed');
            return this.partnerSkuLimitEmbed;
        } else {
            console.log('partner is NOT allowed');
            console.error(AuthService.noPartnerMsg);
            return AuthService.noPartnerLimitValue;
        }
    }

    @computed
    get getPartnerDisplayLimit():
        | PartnerLimit
        | {
              SINGLE_DISPLAY: number;
              DISPLAY_WALL: number;
              LOGO_REQUIRED: boolean;
              WATERMARK_REQUIRED: boolean;
              WATERMARK_DEFAULT: boolean;
          } {
        // console.log("getPartnerDisplayLimit()", " allowAll ", this.allowAllPartners, "partnerAllowed ", this.isDisplayEnabled());

        if (this.isAllPartnersAllowed) {
            // console.log("allow all partners");
            // no whitelist means no SKU list either...
            return AuthService.unlimitedPartnerLimitObject;
        }

        if (this.isDisplayAllowed) {
            console.log('partner is allowed');

            return this.partnerSkuLimitDisplay;
        } else {
            console.log('partner is NOT allowed');
            console.error(AuthService.noPartnerMsg);
        }
    }

    isSharingEnabled(): boolean {
        return this.partnerSkuLimitDisplay && this.partnerSkuLimitDisplay['SHARING_ENABLED'];
    }

    public isWatermarkRequired(): boolean {
        return this.partnerSkuLimitDisplay && this.partnerSkuLimitDisplay['WATERMARK_REQUIRED'];
    }

    public getWatermarkDefault(): Watermark {
        const watermarkRendered = this.isWatermarkRequired()
            ? true
            : this.partnerSkuLimitDisplay['WATERMARK_DEFAULT'];
        return new WatermarkImpl({ watermarkRendered });
    }

    public resolveLimitedAvailableTemplates(templates: Template[]) {
        return templates.filter(
            template =>
                !Object.keys(AuthService.limitedAvailabilityConfig).some(
                    key =>
                        key === template.id &&
                        !AuthService.limitedAvailabilityConfig[key].includes(
                            this.userInfo.partnerId
                        )
                )
        );
    }

    hasPermission(permission: DisplayPermission): boolean {
        if (this.env.developmentMode) {
            return true;
        }

        // if (this.isEmbedMode) {
        //     // EMBED aka Gallery has no permissions checks
        //     return true;
        // }

        if (!this.userInfo) {
            // console.warn(`Checking permission ${permission} before we have userInfo, forced to say "false"`);
            return false;
        }

        if (permission === 'PUBLIC_EMBED_DISPLAY_TYPE') {
            // "HPE (on AWS)", "HPI (on AWS)", and "Amazon" - not sure which acct for Re:Invent 2020.
            return !!~[
                294, // Twitter
                349, // Display
                188, // "HPE (on AWS)"
                485, // AT&T
                580, // Papa John's
                642, // "HPI (on AWS)"
                674, // "Amazon"
                1089, // Honda
                1438, // "Pokemon Company"
                150018, // "Siemens" on prod3
            ].indexOf(this.userInfo.partnerId);
        }

        return this.userInfo.permissions[permission] || false;
    }

    isComponentOwnedByUser(component: ShareableComponent): boolean {
        const userId = this.userInfo ? this.userInfo.userId : null;

        if (null === userId || null === component.createdByUserId) {
            return false;
        }

        return component.createdByUserId === userId;
    }

    isComponentSharedWithUser(component: ShareableComponent) {
        const userId = this.userInfo?.userId;
        if (!component || !userId) {
            return false;
        }

        if (!component.restricted || component.createdByUserId == userId) {
            return true;
        }

        if (component.sharedWithUserIds && component.sharedWithUserIds.includes(userId)) {
            return true;
        }

        return (
            component.sharedWithGroupIds &&
            this.userInfo.groupIds &&
            this.userInfo.groupIds.some(groupId => component.sharedWithGroupIds.includes(groupId))
        );
    }

    // Special-case detection for Kerala Project at the moment
    getSpecialPartner(): string {
        if (this.sprinklrRoot.indexOf('prod4') != -1 && this.userInfo.partnerId === 200025) {
            return 'kerala';
        }

        return null;
    }

    sessionContextPromise: Promise<SessionContext | void>;

    async getSessionContext(
        options: {
            clientId?: number;
            authToken?: string;
        } = {}
    ): Promise<SessionContext | void> {
        if (this.sessionContextPromise) {
            return this.sessionContextPromise;
        }

        const onError = action(error => {
            console.error('Unable to get session information', JSON.stringify(error));
            this.sessionContextPromise = null;
            this.isLoggedIn = false;
        });

        const doFetch = () =>
            this.graphQLService.query({ query: GET_USER_AND_PARTNER }, { unscoped: true }).then(
                action('AuthService.getSessionContext', (sessionContext: SessionContext) => {
                    if (!sessionContext?.user) {
                        throw new Error('getSessionContext request failed');
                    }

                    if (sessionContext.partner) {
                        // Creates the list of source engines that are not avaialble for this partner
                        if (sessionContext.partner.enabledEngines) {
                            this.engineBlacklist = this.getEngineBlacklist(
                                sessionContext.partner.enabledEngines
                            );
                        }

                        // Creates the map of engines that support the metric filters feature
                        if (sessionContext.partner.measurementFilterEngines) {
                            this.engineMeasurementFilter =
                                sessionContext.partner.measurementFilterEngines;
                        }

                        if (sessionContext.partner.limit[0]) {
                            // DISPLAY_ENABLED leveraging {partner{limit{partnerId}}} is a quick-fix hack for a perms issue
                            // which will otherwise allow partners with no SKUs assigned access to Display. This check ensures
                            // that the limit record came from the database instead of being constructed by an object builder
                            // on a specific back-end codepath.
                            const limit = sessionContext.partner.limit[0];
                            const limitHasPartnerId =
                                limit.partnerId !== null && limit.partnerId !== undefined;

                            this.partnerSkuLimitDisplay = {
                                DISPLAY_ENABLED: limitHasPartnerId,
                                SINGLE_DISPLAY: sessionContext.partner.limit[0].single,
                                DISPLAY_WALL: sessionContext.partner.limit[0].wall,
                                WATERMARK_REQUIRED:
                                    sessionContext.partner.limit[0].watermarkRequired,
                                WATERMARK_DEFAULT: sessionContext.partner.limit[0].watermarkDefault,
                                SHARING_ENABLED: sessionContext.partner.limit[0].sharingEnabled,
                            };

                            this.partnerSkuLimitEmbed = limit.gallery;
                            this.presentationsModuleEnabled = this.allowAllPartners
                                ? true
                                : limit.presentationsEnabled;
                            this.displayModuleEnabled = this.allowAllPartners
                                ? true
                                : limit.displayEnabled;
                            this.featuresEnabled = limit.featuresEnabled;
                        }
                    }

                    let activeClientId = sessionContext.user.clientId;
                    if (options.clientId) {
                        const found = sessionContext.partner.clients.some(
                            client => client.clientId === options.clientId
                        );
                        if (found) {
                            activeClientId = options.clientId;
                        } else {
                            location.replace('/');
                        }
                    }
                    this.activeClientId = activeClientId;
                    this.graphQLService.setClientId(activeClientId);

                    const customFetch: WindowOrWorkerGlobalScope['fetch'] = (uri, opts) => {
                        const { operationName } = JSON.parse(opts.body as any);
                        if (opts.method === 'POST') {
                            // this clears __typename from the variables that are passed to our endpoints
                            // to prevent the keys from being saved
                            // https://github.com/apollographql/apollo-feature-requests/issues/6
                            opts.body = JSON.stringify(
                                omitDeep(JSON.parse(opts.body as any), '__typename')
                            );
                        }
                        return fetch(
                            `${config.apiRoot}clients/${this.activeClientId}/graphql?opname=${operationName}`,
                            { ...opts, credentials: 'include' }
                        );
                    };
                    apolloClient.setLink(new HttpLink({ fetch: customFetch }));

                    this.sessionContext = sessionContext;
                    this.userInfo = sessionContext.user;
                    this.isLoggedIn = true;

                    return sessionContext;
                }),
                onError
            );

        if (options.authToken) {
            console.debug('Starting session with auth token');
            return (this.sessionContextPromise = this.loginWithJWT(options.authToken).then(
                doFetch,
                onError
            ));
        }

        return (this.sessionContextPromise = doFetch());
    }

    clientUsersAndGroupsPromise: Promise<{
        id: number;
        clientUsers: ClientUser[];
        userGroups: GroupInfo[];
    }>;

    getClientUsersAndGroups(): Promise<{
        id: number;
        clientUsers: ClientUser[];
        userGroups: GroupInfo[];
    }> {
        if (this.clientUsersAndGroupsPromise) {
            return this.clientUsersAndGroupsPromise;
        }

        this.clientUsers = undefined;
        this.clientUserGroups = undefined;

        // Now we must fetch the client info in a separate request, as we're including the clientId param which
        // if it's wrong for our user will result in a 403 from the GraphQL API, so first we verify the authed
        // user should be allowed to use the requested client - note this isn't actually the access control
        // mechanism as it's enforced on the API side, this is ensuring we don't trip over the ensuing error and
        // wind up in redirect-loop hell. TODO: simpler solution seems possible
        return (this.clientUsersAndGroupsPromise = this.graphQLService
            .query(
                { query: GET_CLIENT_USERS_AND_GROUPS },
                {
                    unscoped: !this.isUserAuthorizedForClient(this.graphQLService.getClientId()),
                }
            )
            .then(
                action(
                    'AuthService.setClientInfo',
                    (clientData: {
                        client: {
                            id: number;
                            clientUsers: ClientUser[];
                            userGroups: GroupInfo[];
                        };
                    }) => {
                        this.clientUsers = clientData.client.clientUsers;
                        this.clientUserGroups = clientData.client.userGroups;

                        return clientData.client;
                    }
                ),
                error => {
                    console.error(`Error loading users and groups`, JSON.stringify(error));
                    throw error;
                }
            ));
    }

    private isUserAuthorizedForClient(clientId: number): boolean {
        if (clientId === null || clientId === undefined || !this.userInfo) {
            return false;
        }

        // simple case - user's primary client (with caveat that sometimes Sprinklr API changes clientId for multi-client)
        if (this.userInfo.clientId === clientId) {
            return true;
        }

        if (this.isMultiClientUser()) {
            return this.isClientIdContainedInPartner(clientId);
        } else {
            return false;
        }
    }

    private isMultiClientUser(): boolean {
        if (!this.userInfo) {
            return false;
        }

        return MULTI_CLIENT_USER_TYPES.some(type => type === this.userInfo.userType);
    }

    private isClientIdContainedInPartner(clientId: number): boolean {
        if (
            !this.sessionContext ||
            !this.sessionContext.partner ||
            !this.sessionContext.partner.clients
        ) {
            return false;
        }

        clientId = Number(clientId);
        return this.sessionContext.partner.clients.some((client: ClientInfo) => {
            return clientId === client.clientId;
        });
    }

    private getEngineBlacklist(engines: { string: boolean }): string[] {
        const blacklist = [];

        for (const engine in engines) {
            if (!engines[engine]) {
                blacklist.push(engine);
            }
        }

        return blacklist;
    }

    alreadyGoingToLoginPage = false;

    getDefaultLoginUrl(redirectTo?: string) {
        redirectTo = encodeURIComponent(this.getRedirectUrl(redirectTo));

        // service=spr required in prod2 at least.
        return `${this.sprinklrRoot}ui/service/login?service=spr&returnTo=${redirectTo}`;
    }

    getJWTLoginUrl(authToken: string): string {
        return `${this.axios.defaults.baseURL}login?auth=${authToken}`;
    }

    async loginWithJWT(authToken: string) {
        let url = this.getJWTLoginUrl(authToken);
        url += '&redirect_to=' + encodeURIComponent('/auth');
        return this.axios.get(url);
    }

    goToJWTLoginPage(authToken: string, redirectTo?: string) {
        if (this.alreadyGoingToLoginPage) {
            return;
        }
        this.alreadyGoingToLoginPage = true;

        let url = this.getJWTLoginUrl(authToken);

        url += '&redirect_to=' + encodeURIComponent(redirectTo);

        this.setHref(url);
    }

    getRedirectUrl(redirectTo?: string) {
        if (redirectTo) {
            return redirectTo;
        }

        // fix for IE
        if (!window.location.origin) {
            (window.location as any).origin =
                window.location.protocol +
                '//' +
                window.location.hostname +
                (window.location.port ? ':' + window.location.port : '');
        }

        // let path = window.location.pathname;
        // if (window.location.search) {
        //     const parsed = queryString.parse(window.location.search);
        //     if (parsed.auth) {
        //         delete parsed.auth;
        //     }
        //     if (Object.keys(parsed).length > 0) {
        //         path += `?${queryString.stringify(parsed)}`;
        //     }
        // }

        return window.location.origin + window.location.pathname;
    }

    goToLoginPage(redirectTo?: string) {
        if (this.alreadyGoingToLoginPage) {
            return;
        }
        this.alreadyGoingToLoginPage = true;

        const url = this.getDefaultLoginUrl(redirectTo);
        this.setHref(url);
    }

    /**
     * Change client by talking to Core and getting a new JWT with a different clientId on it.
     * Involves lots of redirects.
     * @param clientId to switch to
     */
    changeClient(clientId: number) {
        const origin =
            window.location.origin || // obnoxious workaround for IE
            `${window.location.protocol}//${window.location.hostname +
                (window.location.port ? ':' + window.location.port : '')}`;

        const path = this.env.applicationMode === 'PRESENTATIONS' ? 'presentations' : 'storyboards';

        const innerReturnTo = encodeURIComponent(`${origin}/clients/${clientId}/${path}`);
        const returnTo = encodeURIComponent(
            `${this.axios.defaults.baseURL}redirect/?redirect_to=${innerReturnTo}`
        );

        this.setHref(
            `${this.partnerRoot}ui/switch-client?switch=${clientId}&service=spr&returnTo=${returnTo}`
        );
    }

    setHref(url: string) {
        window.location.href = url;
    }

    /**
     * Old-style client switching, don't use it.
     * @deprecated
     * @param clientId to switch to
     */
    changeClientLegacy(clientId: number) {
        // fix for IE
        if (!window.location.origin) {
            (window.location as any).origin =
                window.location.protocol +
                '//' +
                window.location.hostname +
                (window.location.port ? ':' + window.location.port : '');
        }

        // we want a total reload - clears datastores, re-queries for everything using the new client scoping.
        (window as any).location = this.setCurrentClient(window.location.pathname, clientId);
    }

    /**
     * Partner-specific domain, used for SSO login/logout. If this is unset, fall back to default domain for the env.
     */
    get partnerRoot() {
        const partnerDomain = this.sessionContext.partner.domain;
        return partnerDomain ? `https://${partnerDomain}/` : this.env.sprinklrRoot;
    }

    logoutOfCore(): Promise<any> {
        // using jsonp because https://app.sprinklr.com/ui/logout is not cross origin friendly
        const partnerLogoutUrl = this.partnerRoot + 'ui/logout';
        const defaultLogoutUrl = this.sprinklrRoot + 'ui/logout';

        return new Promise((resolve, reject) => {
            const logout = () => {
                try {
                    jsonp(partnerLogoutUrl, null, resolve);
                } catch (e) {
                    console.error('error on logout', e);
                    reject(e);
                }
            };

            // If the partner-specific endpoint doesn't match the default endpoint, the user may have a session with
            // both domains, so to be thorough we log them out of both.
            if (partnerLogoutUrl !== defaultLogoutUrl) {
                try {
                    jsonp(defaultLogoutUrl, null, logout);
                } catch (e) {
                    console.error('error on logout', e);
                    logout(); // one is better than none!
                }
            } else {
                logout();
            }
        });
    }

    logout() {
        const then = action('AuthService.logout', () => {
            this.isLoggedIn = false;
            this.userInfo = null;
            this.activeClientId = null;

            this.notifyBadSession();
            this.goToLoginPage();
        });

        const doLogout: any = this.axios.get('logout').then(then, then);
        this.logoutOfCore().then(doLogout, doLogout);
    }

    getCurrentClientId(): number | string {
        if (!this.sessionContext?.partner?.clients) {
            return null;
        }

        const result = this.sessionContext.partner.clients.some((client: ClientInfo) => {
            return client.clientId === this.activeClientId;
        });

        return result ? this.activeClientId : null;
    }

    getCurrentClient(): ClientInfo {
        return this.sessionContext.partner.clients.find((client: ClientInfo) => {
            return client.clientId === this.activeClientId;
        });
    }

    // Validate the current route to ensure this client id is valid for this user.
    // If it's not, it will return a new route path, otherwise if so, null
    @action
    setCurrentClient(pathname: string, clientId: number | string): string {
        const oldClientId = this.activeClientId;

        const clientIdNum: number = +clientId as number;

        this.activeClientId = clientIdNum;

        this.notifyClientChanged(this.activeClientId);

        return pathname.replace(`/clients/${oldClientId}`, `/clients/${clientId}`);
    }

    getRoute(route: string, compact?: boolean): string {
        return compact
            ? `/${this.activeClientId}` + route
            : `/clients/${this.activeClientId}` + route;
    }

    // Validate the current route to ensure this client id is valid for this user.
    // If it's not, it will return a new route path, otherwise if so, null
    @action
    validateRoute(pathname: string, clientId: number | string, compact?: boolean): string {
        if (!this.sessionContext.partner || !this.sessionContext.partner.clients) {
            return null;
        }

        const clientIdNum: number = +clientId as number;

        const result: ClientInfo = this.sessionContext.partner.clients.find(
            (client: ClientInfo) => {
                return client.clientId === clientIdNum;
            }
        );

        if (!result) {
            return compact
                ? pathname.replace(`/${clientIdNum}`, `/${this.activeClientId}`)
                : pathname.replace(`/clients/${clientIdNum}`, `/clients/${this.activeClientId}`);
        } else {
            // Change our current to the one on the url
            if (clientIdNum !== this.activeClientId) {
                this.activeClientId = clientIdNum;
            }

            return null;
        }
    }

    /**
     * This is currently handled by checking that the user has ALL permissions for the relevant application mode as
     * there is no distinct permission for the audit trail as yet.
     */
    @computed get hasAuditTrailPermission(): boolean {
        if (!this.userInfo || !this.userInfo.permissions) {
            return false;
        }

        const { permissions } = this.userInfo;

        if (this.isDisplayMode) {
            return DISPLAY_APP_PERMISSIONS.every(perm => permissions[perm]);
        }

        if (this.isPresentationsMode) {
            return PRESENTATIONS_APP_PERMISSIONS.every(perm => permissions[perm]);
        }

        return false;
    }

    @computed get hasDisplayEditPermission(): boolean {
        return this.isDisplayEnabled && this.hasPermission('DISPLAY_EDIT');
    }

    @computed get hasDisplayCreatePermission(): boolean {
        return this.isDisplayEnabled && this.hasPermission('DISPLAY_CREATE');
    }

    @computed get hasBoardRenamePermission(): boolean {
        return (
            (this.isDisplayEnabled && this.hasPermission('STORYBOARD_EDIT')) ||
            (this.isPresentationsEnabled && this.hasPermission('PRESENTATIONS_EDIT'))
        );
    }

    @computed get hasBoardEditPermission(): boolean {
        return (
            (this.isDisplayEnabled && this.hasPermission('STORYBOARD_EDIT')) ||
            (this.isPresentationsEnabled && this.hasPermission('PRESENTATIONS_EDIT'))
        );
    }

    @computed get hasBoardDeletePermission(): boolean {
        return (
            (this.isDisplayEnabled && this.hasPermission('STORYBOARD_DELETE')) ||
            (this.isPresentationsEnabled && this.hasPermission('PRESENTATIONS_DELETE'))
        );
    }

    @computed get hasBoardCreatePermission(): boolean {
        return (
            (this.isDisplayEnabled && this.hasPermission('STORYBOARD_CREATE')) ||
            (this.isPresentationsEnabled && this.hasPermission('PRESENTATIONS_CREATE'))
        );
    }

    @computed get hasBoardDeleteScenePermission(): boolean {
        return (
            (this.isDisplayEnabled && this.hasPermission('STORYBOARD_EDIT')) ||
            (this.isPresentationsEnabled && this.hasPermission('DELETE_SLIDE'))
        );
    }

    @computed get hasBoardViewPermission(): boolean {
        return (
            (this.isDisplayEnabled && this.hasPermission('STORYBOARD_VIEW')) ||
            (this.isPresentationsEnabled && this.hasPermission('PRESENTATIONS_VIEW'))
        );
    }

    @computed get hasShareUrlPermission(): boolean {
        return (
            this.isDisplayEnabled || // presentations only
            (this.isPresentationsEnabled && this.hasPermission('SHARE_URL'))
        );
    }

    @computed get hasBoardPublishPermission(): boolean {
        return (
            (this.isDisplayEnabled && this.hasPermission('DISPLAY_PUBLISH')) ||
            (this.isPresentationsEnabled && this.hasPermission('PUBLISH'))
        );
    }

    @computed get hasBoardExportPermission(): boolean {
        return (
            this.isDisplayEnabled || // presentations only
            (this.isPresentationsEnabled && this.hasPermission('EXPORT'))
        );
    }

    @computed get hasStyleEditPermission(): boolean {
        return (
            (this.isDisplayEnabled && this.hasBoardEditPermission) ||
            (this.isPresentationsEnabled && this.hasPermission('STYLE_KIT_EDIT'))
        );
    }

    @computed get hasStyleApplyPermission(): boolean {
        return (
            (this.isDisplayEnabled && this.hasBoardEditPermission) ||
            (this.isPresentationsEnabled && this.hasPermission('STYLE_KIT_APPLY'))
        );
    }

    @computed get hasStyleCreatePermission(): boolean {
        return (
            (this.isDisplayEnabled && this.hasBoardEditPermission) ||
            (this.isPresentationsEnabled && this.hasPermission('STYLE_KIT_CREATE'))
        );
    }

    @computed get hasStyleDeletePermission(): boolean {
        return (
            (this.isDisplayEnabled && this.hasBoardEditPermission) ||
            (this.isPresentationsEnabled && this.hasPermission('STYLE_KIT_EDIT'))
        ); // same as create
    }

    @computed get hasLockWidgetPermission(): boolean {
        return (
            this.isDisplayEnabled || // presentations only
            (this.isPresentationsEnabled && this.hasPermission('LOCK_WIDGET'))
        );
    }

    @computed get hasCreateWidgetPermission(): boolean {
        return (
            this.isDisplayEnabled || // presentations only
            (this.isPresentationsEnabled && this.hasPermission('CREATE_WIDGET'))
        );
    }
}
