import { AnalyticsRequest } from '@sprinklr/stories/analytics/AnalyticsRequest';
import { AnalyticsResult } from '@sprinklr/stories/analytics/AnalyticsResult';
import { PostsFormatRequest } from '@sprinklr/stories/post/PostsFormatRequest';
import { PostsRequest } from '@sprinklr/stories/post/PostsRequest';
import { ProfileRequest } from '@sprinklr/stories/profile/ProfileRequest';
import {
    MergedLegacyTheme,
    Widget,
    WidgetLabelInterface,
    WidgetPosition,
} from '@sprinklr/stories/widget/Widget';
import { observable } from 'mobx';
import { DataSourceName, DataSourceNames } from 'models/DataSource/DataSource';
import { TrendRequest } from 'services/TrendService/types';
import { ThemeFields } from '../Theme/Theme';
import { WidgetFontImpl } from './font/WidgetFontImpl';
import { WidgetTypeId } from './types';

export type WidgetDataRequest =
    | AnalyticsRequest
    | ProfileRequest
    | PostsRequest
    | PostsFormatRequest
    | TrendRequest;

export class WidgetImpl<OptionsType = any> implements Widget {
    @observable id: string;
    @observable name: string;
    @observable type: WidgetTypeId;
    @observable userAssetId?: string;
    @observable userAssetContentUrl?: string;
    @observable classes: string;
    @observable theme: MergedLegacyTheme;
    @observable position: WidgetPosition;
    @observable label: WidgetLabelInterface;
    @observable css: string;
    @observable js: string;
    @observable postRequests: PostsFormatRequest[];
    @observable postsRequest: PostsRequest;
    @observable analyticsRequests: AnalyticsRequest[];
    @observable analyticsResults: AnalyticsResult[];
    @observable profileRequests: ProfileRequest[];
    @observable trendRequests: TrendRequest[];
    @observable children: Widget[];
    @observable useGlobalTimePeriod?: boolean;
    @observable useGlobalPreviousTimePeriod?: boolean;
    @observable useGlobalTranslate?: boolean;
    @observable translationLanguage?: string = null;
    @observable useGlobalDataSources?: boolean;
    @observable globalDataSourceName?: DataSourceName;
    @observable dataSourceNames?: DataSourceNames;
    @observable options: OptionsType;

    static readonly graphQLFields: string = ``;
}

export const WidgetSnippet = `
    widget {
        ...widgetRootNodeType
    }
`;

export const WidgetFragments = `
    fragment widgetRootNodeType on Widget {
            children {
                ...widgetNodeType1
            }
            ...widgetLeafType
    }
    fragment widgetNodeType1 on Widget {
            children {
                ...widgetNodeType2
            }
            ...widgetLeafType
    }
    fragment widgetNodeType2 on Widget {
            children {
                ...widgetNodeType3
            }
            ...widgetLeafType
    }
    fragment widgetNodeType3 on Widget {
            children {
                ...widgetNodeType4
            }
            ...widgetLeafType
    }
    fragment widgetNodeType4 on Widget {
            children {
                ...widgetLeafType
            }
            ...widgetLeafType
    }
    fragment widgetLeafType on Widget {
            id name type css js
            userAssetId
            userAssetContentUrl
            classes
            useGlobalTimePeriod
            useGlobalPreviousTimePeriod
            useGlobalDataSources
            globalDataSourceName
            useGlobalTranslate
            translationLanguage
            options
            theme {
                ...legacyThemeType
            }
            position {
                ...positionType
            }
            analyticsRequests {
                ...analyticsRequestType
            }
            profileRequests {
                ...profileRequestType
            }
            postRequests
            trendRequests {
                ...trendRequestType
            }
            label {
                ...widgetLabelType
            }
    }
    fragment analyticsRequestType on analyticsRequest {
        report
        reportingEngine
        limit
        sorts { heading order }
        includeTotal
        timeField
        timePeriods {
            duration
            startTime
            endTime
            timeZone
            key
            previousPeriod
            wholePeriods
            xValue
            rollingStart
            rollingEnd
            weekStart
            name
            filters {
                dimensionName filterType values details
            }
        }
        groupBys {
            heading
            limit
            limitType
            dimensionName
            groupType
            details
            namedFilters {
                name
                filters {
                    dimensionName filterType values details
                }
            }
        }
        filters {
            dimensionName filterType values details
        }
        projections {
            heading measurementName aggregateFunction details
        }
        viralTrends
        additional {
            TARGET_LANG_CODE
            Currency
            slaConfig {
                id
                name
                assetClass
                assetId
                slaIntervals {
                    from
                    to
                }
                slaObjective
                complianceTarget
                tzOffset
                country
                timezone
                workingHours {
                    MONDAY
                    TUESDAY
                    WEDNESDAY
                    THURSDAY
                    FRIDAY
                    SATURDAY
                    SUNDAY
                }
                inactiveDays {
                    name
                    inactiveHours
                }
                applyWorkingHours
                deleted
                locked
            }
        }
    }
    fragment profileRequestType on profileRequest {
        reportingEngine
        report
        page
        pageSize
        groupBys {
            heading dimensionName groupType details
        }
        sorts {
            heading
            order
        }
        timePeriod {
            duration
            startTime
            endTime
            timeZone
            key
            previousPeriod
            wholePeriods
            xValue
        }
        filters {
            dimensionName
            filterType
            values
            details
        }
    }
    fragment trendRequestType on trendRequest {
        source
        woeid
        limit
        country
    }
    fragment positionType on WidgetPosition {
        left
        right
        top
        bottom
        width
        height
        portrait { left top width height }
        landscape { left top width height }
        backgroundColor
        display
        maxWidth
        maxHeight
        grid {
            enabled
            columns { start end }
            rows { start end }
        }
    }
    fragment legacyThemeType on WidgetTheme {
        colorPrimary
        colorSecondary
        colorBackground
        colorNegative
        colorPostive
        colorFont
        fontFamilyPrimary
        fontFamilySecondary
        fontSizePrimary
        fontSizeSecondary
        ext
        backgroundImage {
            url previewUrl samId name description
            backgroundRepeat backgroundSize
        }
        fontPrimary {
            ${WidgetFontImpl.graphQLFields}
        }
        fontSecondary {
            ${WidgetFontImpl.graphQLFields}
        }
        ${ThemeFields}
    }
    fragment widgetLabelType on WidgetLabel {
        titleText
        subTitleText
        classes
        overlap
        layout
        size
        enabled
        icon
        textShadow { enabled x y blur color }
    }`;
