import { CommonDocFields } from '../CommonDoc/CommonDoc';
import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';
import { Scene, SceneFields, SceneLight, SceneLightFields } from '../Scene/Scene';
import { Layout, LayoutFields } from '../Layout/Layout';
import { Location } from '../Location/Location';
import { LayoutConstrained } from '../LayoutConstrained/LayoutConstrained';
import { VersionCapable, VersionCapableFields } from '../VersionCapable/VersionCapable';
import {
    StoryboardVersionFields,
    StoryboardVersionLightFieldsLayout,
    StoryboardVersionLightFieldsOnlyFirstScene,
} from './StoryboardVersion';
import {
    AccessControlImpl,
    AccessControlledComponent,
    AccessControlledFields,
    ShareableComponent,
} from 'models/AccessControl/AccessControl';
import { FontFields, Theme, Themed, ThemeImpl, ThemeSnippet } from '../Theme/Theme';
import { observable } from 'mobx';
import { User } from '../User/User';
import { Version } from '../Version/Version';
import { SceneVersion } from '../Scene/SceneVersion';
import { Watermark } from '../Watermark/Watermark';
import { PageNumber } from '../../components/storyboard/StoryboardHeaderFooter/PageNumber/types';
import { SingleTimePeriod, SingleTimePeriodFields } from 'models/TimePeriod/SingleTimePeriod';
import { DataSources, DataSourcesSnippet } from 'models/DataSource/DataSource';
import { HeaderModel } from 'components/storyboard/StoryboardHeaderFooter/Header/types';
import { HeaderSnippet } from 'components/storyboard/StoryboardHeaderFooter/Header/options';
import { PageNumberSnippet } from 'components/storyboard/StoryboardHeaderFooter/PageNumber/options';
import { ContentLayer } from 'components/contentLayer/types';
import { ContentLayerFields } from 'models/ContentLayer/ContentLayer';

/**
 * Created by dstelter on 10/3/16.
 */

export interface Storyboard
    extends Themed,
        VersionCapable,
        CommonDoc,
        LayoutConstrained,
        ShareableComponent,
        AccessControlledComponent {
    layout?: Layout;
    scenes?: Scene[];
    firstScene?: Scene;
    featuredScene?: Scene;
    sceneCount?: number;
    enabledSceneCount?: number;
    sceneDuration?: number;
    activeVersion?: StoryboardVersion;
    previousVersion?: StoryboardVersion;
    versions?: StoryboardVersion[];
    locations?: Location[];
    publishedVersionId?: string;
    lastPublished?: number;
    theme?: Theme;
    boardType?: string;
    tags?: string[];
    internalTags?: string[];
    watermark?: Watermark;
    pageNumber?: PageNumber;

    contentLayer?: ContentLayer;
    timePeriod?: SingleTimePeriod;
    previousTimePeriod?: SingleTimePeriod;
    dataSources?: DataSources;
    imagesEnabled?: boolean;
    videosEnabled?: boolean;
    muteVideosPresentations?: boolean;
    globalTranslationLanguage?: string;
    dashboardId?: string;
    importComplete?: boolean;
    importConfig?: any;
    views?: number;
    header?: HeaderModel;
    compareMode?: boolean;
}

export interface StoryboardLight extends ShareableComponent, AccessControlledComponent {
    id?: string;
    name?: string;
    layout: Layout;
    firstScene?: SceneLight;
    scenes: SceneLight[];
    activeVersion: StoryboardVersionLight;
    userUpdated?: number;
    created?: number;
    watermark?: Watermark;
    pageNumber?: PageNumber;
    boardType?: string;
    header?: HeaderModel;
    tags?: string[];
    internalTags?: string[];
}

export const StoryboardLightPublishedFields = `
    id
    name
    layout {
        ${LayoutFields}
    }
    userUpdated
    created
    createdByUserId
    sharedWithUserIds
    sharedWithGroupIds
    restricted
    ${AccessControlledFields}
    boardType
    tags
    internalTags
    ${DataSourcesSnippet}
    locations {
        ${CommonDocFields}
        activeStoryboardId
    }
    firstScene {
        ${SceneLightFields}
    }
    scenes {
        ${SceneLightFields}
    }
    activeVersion {
        ${StoryboardVersionLightFieldsOnlyFirstScene}
    }
    sceneCount
    `;

export interface StoryboardVersionLight extends StoryboardLight, Version {
    published?: boolean;
    savedByUser?: User;
    archived?: boolean;
}

export interface StoryboardVersion extends Storyboard, Version {
    published?: boolean;
    sceneCount?: number;
    firstScene?: SceneVersion;
    scenes?: SceneVersion[];
    savedByUser?: User;
    archived?: boolean;
    sceneIds?: number[];
    enabledSceneIds?: number[];
}

const tagsTest = ['France', 'USA', 'UK', 'China', 'Peru', 'Columbia', 'Iceland', 'Las Vegas'];

export class StoryboardImpl extends AccessControlImpl implements Storyboard {
    // constructor() {
    //     super();
    //     this.setTags();
    // }

    // @action
    // private setTags() {
    //     this.tags = tagsTest.filter(() => {
    //         return (Math.round(Math.random()) < 0.5);
    //     });
    // }

    @observable layout?: Layout;
    @observable scenes: Scene[];
    @observable firstScene?: Scene;
    @observable sceneCount?: number;
    @observable sceneDuration: number;
    @observable createdByUserId: number;
    @observable sharedWithUserIds: number[];
    @observable sharedWithGroupIds: string[];
    @observable restricted: boolean;
    @observable versionId?: string;
    @observable publishedVersionId?: string;
    @observable lastPublished?: number;
    @observable versions: StoryboardVersion[];
    @observable activeVersion?: StoryboardVersion;
    @observable previousVersion?: StoryboardVersion;
    @observable locations?: Location[];
    @observable theme?: ThemeImpl;
    @observable boardType?: string;
    @observable tags?: string[];
    @observable internalTags?: string[];
    @observable watermark?: Watermark;
    @observable pageNumber?: PageNumber;
    @observable timePeriod?: SingleTimePeriod;
    @observable previousTimePeriod?: SingleTimePeriod;
    @observable dataSources?: DataSources;
    @observable imagesEnabled: boolean;
    @observable videosEnabled: boolean;
    @observable muteVideosPresentations?: boolean;
    @observable globalTranslationLanguage?: string;
    @observable header: HeaderModel;
    @observable contentLayer: ContentLayer;
    @observable compareMode?: boolean;
}

export class StoryboardVersionImpl extends StoryboardImpl implements StoryboardVersion {
    @observable masterId?: string;
    @observable immutable?: boolean;
    @observable savedByUserId?: number;
    @observable saved?: number;
    @observable published?: boolean;
    @observable archived?: boolean;
    @observable scenes: SceneVersion[];
    @observable firstScene: SceneVersion;
    @observable treeDirty: boolean;
    @observable sceneCount?: number;
    @observable savedByUser?: User;
    @observable theme?: ThemeImpl;
    @observable header: HeaderModel;
    @observable contentLayer: ContentLayer;
}

export const ContentOverridesSnippet = `
    imagesEnabled
    videosEnabled
    muteVideosPresentations
`;

export const AlertsFields = `
    enabled
    sources {
        postsRequests
    }
`;

export const StoryboardFields = `
    ${CommonDocFields}
    ${VersionCapableFields}
    sceneDuration
    createdByUserId
    sharedWithUserIds
    sharedWithGroupIds
    restricted
    publishedVersionId
    ${AccessControlledFields}
    boardType
    dashboardId
    importComplete
    timePeriod {
        ${SingleTimePeriodFields}
    }
    contentLayer {
        ${ContentLayerFields}
    }
    previousTimePeriod {
        ${SingleTimePeriodFields}
    }
    activeVersion {
        ${DataSourcesSnippet}
        ${ContentOverridesSnippet}
        globalTranslationLanguage
        ${StoryboardVersionFields}
        ${PageNumberSnippet}
        ${HeaderSnippet}
    }
    previousVersion {
        ${StoryboardVersionLightFieldsOnlyFirstScene}
    }
    scenes {
        ${SceneFields}
    }
    layout {
        ${LayoutFields}
    }
    locations {
        ${CommonDocFields}
        activeStoryboardId
        address {
            address1
            address2
            floor
            room
            city
            state
            zipcode
            country
        }
    }
    ${ThemeSnippet}
    ${DataSourcesSnippet}
    ${ContentOverridesSnippet}
    globalTranslationLanguage
    compareMode
    `;

export const DashboardImportConfigFields = `
    importConfig {
        timezone
        reportName
        contentConfig {
            exportModule
            dashboardId
            filters {
                field
                filterType
                values
                details
            }
            timeFilter {
                timeRange
                sinceTime
                untilTime
            }
            previousTimeFilter {
                timeRange
                sinceTime
                untilTime
            }
            additionalInfoByWidgetId
        }
        distributionConfig {
            userIds
        }
    }
`;

export const WatermarkSnippet = `
    watermark {
        panelIndex
        watermarkRendered
        imageUrl
     }
`;

export const StoryboardActiveVersionFirstSceneFields = `
    ${CommonDocFields}
    ${VersionCapableFields}
    layout {
        ${LayoutFields}
    }
    boardType
    activeVersion {
        ${StoryboardVersionLightFieldsOnlyFirstScene}
        ${PageNumberSnippet}
        ${HeaderSnippet}
    }
    tags
    internalTags
    userUpdated
    created
    updated
    createdByUserId
    sharedWithUserIds
    sharedWithGroupIds
    restricted
    dashboardId
    importComplete
    locations {
        ${CommonDocFields}
        activeStoryboardId
    }
    views
`;
