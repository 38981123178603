import React from 'react';
import { PostLocation } from '@sprinklr/stories/post/Post';

export function getLocationString(location: PostLocation): string {
    if (location.additional?.city && location.additional?.state_code) {
        // check to see if state_code is a number
        if (Number.isInteger(parseInt(location.additional?.state_code, 10))) {
            return `${location.additional?.city}, ${location.additional.code}`;
        }
        return `${location.additional.city}, ${location.additional.state_code}`;
    } else if (location.additional?.state && location.additional?.code) {
        return `${location.additional.state}, ${location.additional.code}`;
    } else {
        return location.text;
    }
}

export function hasValidLocation(location: PostLocation): boolean {
    if (!location) {
        return false;
    }
    return location?.additional?.code !== 'UN';
}

export const PostsLocation = ({ location }: { location: PostLocation }) =>
    hasValidLocation(location) && (
        <div className='post_location' data-id='post-location'>
            {getLocationString(location)}
        </div>
    );

export function getTimeStampLocationLockup(
    timestamp: JSX.Element,
    locationInfo: JSX.Element,
    classes?: string
) {
    if (!!timestamp && !!locationInfo) {
        return (
            <div className={`flex vertical ${classes ?? ''}`} key='timestamp + locationInfo'>
                {timestamp}
                {locationInfo}
            </div>
        );
    } else if (!!timestamp && !locationInfo) {
        return timestamp;
    } else if (!timestamp && !!locationInfo) {
        return locationInfo;
    }
    return null;
}
