import moment from 'moment';
import {
    AnalyticsFilter,
    AnalyticsTimeInterval,
} from '@sprinklr/stories/analytics/AnalyticsRequest';
import i18n from 'i18next';
import { TIME_PERIOD_PICKER_OPTIONS } from './constants';

type TimeMarker = moment.Moment | Date | string | number;

export type TimePeriodWeekStart =
    | 'SUNDAY'
    | 'MONDAY'
    | 'TUESDAY'
    | 'WEDNESDAY'
    | 'THURSDAY'
    | 'FRIDAY'
    | 'SATURDAY';

export type TimePeriodKey =
    | 'this_week'
    | 'this_month'
    | 'this_quarter'
    | 'this_year'
    | 'yesterday'
    | 'day_before_yesterday'
    | 'today'
    | 'last_week'
    | 'second_last_week'
    | 'last_month'
    | 'second_last_month'
    | 'last_quarter'
    | 'last_year'
    | 'second_last_year'
    | 'next_week'
    | 'week_to_date'
    | 'month_to_date'
    | 'quarter_to_date'
    | 'year_to_date'
    | 'last_60_minutes'
    | 'last_2_to_1_hours'
    | 'last_12_hours'
    | 'last_24_to_12_hours'
    | 'last_24_hours'
    | 'last_48_to_24_hours'
    | 'last_7_days'
    | 'last_14_to_8_days'
    | 'last_28_days'
    | 'last_56_to_29_days'
    | 'last_30_days'
    | 'last_60_to_31_days'
    | 'last_60_days'
    | 'last_120_to_61_days'
    | 'last_90_days'
    | 'last_180_to_91_days'
    | 'last_120_days'
    | 'last_240_to_121_days'
    | 'last_180_days'
    | 'last_360_to_181_days'
    | 'last_365_days'
    | 'last_730_to_366_days'
    | 'last_x_months'
    | 'last_x_weeks'
    | 'last_x_hours'
    | 'last_x_minutes'
    | 'all_time'
    | 'custom'
    | 'custom_duration'
    | 'custom_to_date'
    | 'x_days_ago'
    | 'x_weeks_ago'
    | 'x_months_ago'
    | 'x_quarters_ago';

export type TimePeriodInterval = 'week' | 'month' | 'quarter' | 'year' | 'day' | 'days' | 'hour';

export interface PersistedTimePeriod {
    timePeriod: TimePeriodKey;
    duration?: number;
    startDate?: string;
    endDate?: string;
    interval?: TimePeriodInterval;
    roundDates?: boolean;
    xValue?: number;
    days?: number;
    timeZone?: string;
    rollingStart?: string;
    rollingEnd?: string;
    name?: string;
    filters?: AnalyticsFilter[];
    weekStart?: TimePeriodWeekStart;
}
export default class TimePeriod {
    static ranges: TimePeriodKey[] = [
        'this_week',
        'this_month',
        'this_quarter',
        'this_year',
        'yesterday',
        'today',
        'last_week',
        'next_week',
        'last_month',
        'last_quarter',
        'last_year',
        'week_to_date',
        'month_to_date',
        'quarter_to_date',
        'year_to_date',
        'last_60_minutes',
        'last_24_hours',
        'last_12_hours',
        'last_7_days',
        'last_28_days',
        'last_30_days',
        'last_60_days',
        'last_90_days',
        'last_120_days',
        'last_180_days',
        'last_365_days',
        'last_x_months',
        'last_x_weeks',
        'last_x_hours',
        'last_x_minutes',
        'all_time',
        'custom',
        'custom_duration',
        'custom_to_date',
        'x_days_ago',
        'x_weeks_ago',
        'x_months_ago',
        'x_quarters_ago',
    ];

    timePeriod: TimePeriodKey = null;
    key: TimePeriodKey = null;
    now: moment.Moment = null;
    duration: number = null;
    startDate: moment.Moment = null;
    endDate: moment.Moment = null;
    interval: TimePeriodInterval = null;
    days: number = null;
    xValue: number = null;
    roundDates: boolean = null;
    wholePeriods: boolean;
    timeZone: string = null;
    rollingStart: moment.Moment = null;
    rollingEnd: moment.Moment = null;
    name = null;
    filters: AnalyticsFilter[] = null;
    weekStart: TimePeriodWeekStart = null;

    constructor(
        timePeriod: TimePeriodKey,
        now: moment.Moment,
        duration: number,
        startDate: moment.Moment,
        endDate: moment.Moment,
        interval: TimePeriodInterval,
        xValue: number,
        roundDates: boolean,
        wholePeriods: boolean,
        timeZone: string,
        rollingStart: moment.Moment,
        rollingEnd: moment.Moment,
        name: string,
        filters: AnalyticsFilter[],
        weekStart: TimePeriodWeekStart
    ) {
        this.timePeriod = timePeriod;
        this.now = now;
        this.duration = duration;
        this.startDate = startDate;
        this.endDate = endDate;
        this.interval = interval;

        this.days = xValue; // Weird holdover.
        this.xValue = xValue;

        this.roundDates = roundDates;
        this.wholePeriods = wholePeriods;
        this.timeZone = timeZone;
        this.rollingStart = rollingStart;
        this.rollingEnd = rollingEnd;
        this.name = name;
        this.filters = filters;
        this.weekStart = weekStart;
    }

    isValid(): boolean {
        return TimePeriod.ranges.indexOf(this.timePeriod) > -1;
    }

    setPeriod(timePeriod: TimePeriodKey): void {
        if (TimePeriod.ranges.indexOf(timePeriod) === -1) {
            throw new Error(timePeriod + ' is not a valid date range.');
        }
        this.timePeriod = timePeriod;
    }

    /**
     * The xValue is only meaningful for certain timePeriod keys. Outside those it should be ignored.
     */
    useXValue() {
        if (!this.xValue || this.xValue < 1) {
            return false;
        }

        switch (this.timePeriod) {
            case 'last_x_months':
            case 'last_x_weeks':
            case 'last_x_hours':
            case 'last_x_minutes':
                return true;
            default:
                return false;
        }
    }

    setXValue(xValue) {
        if (!xValue || xValue < 1) {
            xValue = 1;
        }
        this.xValue = xValue;
        this.days = xValue;
    }

    setTimeZone(timeZone) {
        this.timeZone = timeZone;
    }

    setDuration(duration: number) {
        this.duration = duration;
    }

    setStartDate(startDate: TimeMarker) {
        if (this.timePeriod !== 'custom' && this.timePeriod !== 'custom_to_date') {
            throw new Error('Cannot set start date for time period "' + this.timePeriod + '"');
        }

        if (moment.isMoment(startDate)) {
            this.startDate = startDate;
        } else {
            this.startDate = moment(startDate);
        }
    }

    setEndDate(endDate: TimeMarker) {
        if (this.timePeriod !== 'custom') {
            throw new Error('Cannot set end date for time period "' + this.timePeriod + '"');
        }

        if (moment.isMoment(endDate)) {
            this.endDate = endDate;
        } else {
            this.endDate = moment(endDate);
        }
    }

    setRollingStart(startDate: TimeMarker) {
        if (this.timePeriod !== 'custom') {
            throw new Error('Cannot set rolling start date on non-custom time period.');
        }

        if (moment.isMoment(startDate)) {
            this.rollingStart = startDate;
        } else {
            this.rollingStart = moment(startDate);
        }
    }

    setRollingEnd(endDate: TimeMarker) {
        if (this.timePeriod !== 'custom') {
            throw new Error('Cannot set rolling end date on non-custom time period.');
        }

        if (moment.isMoment(endDate)) {
            this.rollingEnd = endDate;
        } else {
            this.rollingEnd = moment(endDate);
        }
    }

    setStartTime(time: TimeMarker) {
        let startMoment: moment.Moment;

        if (typeof time === 'string') {
            startMoment = moment(time as string, 'HH:mm:ss');
        } else if (typeof time === 'number') {
            startMoment = moment(time);
        } else if (time instanceof Date) {
            startMoment = moment(time);
        } else if (!time) {
            startMoment = moment().startOf('day');
        }

        if (!this.startDate) {
            this.startDate = moment();
        }

        this.startDate
            .hour(startMoment.hour())
            .minute(startMoment.minute())
            .second(startMoment.second())
            .millisecond(0);
    }

    setEndTime(time: moment.Moment | Date | string | number) {
        let endMoment: moment.Moment;

        if (typeof time === 'string') {
            endMoment = moment(time as string, 'HH:mm:ss');
        } else if (typeof time === 'number') {
            endMoment = moment(time);
        } else if (time instanceof Date) {
            endMoment = moment(time);
        } else if (!time) {
            endMoment = moment().endOf('day');
        }

        if (!this.endDate) {
            this.endDate = moment();
        }

        this.startDate
            .hour(endMoment.hour())
            .minute(endMoment.minute())
            .second(endMoment.second())
            .millisecond(999);
    }

    setName(name: string) {
        this.name = name;
    }

    setFilters(filters: AnalyticsFilter[]) {
        this.filters = filters;
    }

    setWeekStart(weekStart: TimePeriodWeekStart) {
        this.weekStart = weekStart;
    }

    toJSON(): PersistedTimePeriod {
        return {
            timePeriod: this.timePeriod,
            startDate: this.startDate ? this.startDate.format('YYYY-MM-DD HH:mm:ss') : null,
            endDate: this.endDate ? this.endDate.format('YYYY-MM-DD HH:mm:ss') : null,
            interval: this.interval,
            days: this.days,
            xValue: this.xValue,
            roundDates: this.roundDates,
            timeZone: this.timeZone,
            rollingStart: this.rollingStart
                ? this.rollingStart.format('YYYY-MM-DD HH:mm:ss')
                : null,
            rollingEnd: this.rollingEnd ? this.rollingEnd.format('YYYY-MM-DD HH:mm:ss') : null,
            name: this.name,
            filters: this.filters,
            weekStart: this.weekStart,
        };
    }

    displayName(): string {
        if (this.timePeriod === 'custom') {
            return (
                i18n.t('Custom Date Range') +
                ' ' +
                this.startDate.format('YYYY-MM-DD HH:mm:ss') +
                ' - ' +
                this.endDate.format('YYYY-MM-DD HH:mm:ss')
            );
        }

        const found = TIME_PERIOD_PICKER_OPTIONS.find(val => val.id === this.timePeriod);
        return found?.name;
    }

    defaultInterval(): AnalyticsTimeInterval {
        switch (this.timePeriod) {
            case 'last_x_months':
            case 'all_time':
                return '1M';

            case 'this_month':
            case 'last_month':
            case 'month_to_date':
            case 'this_quarter':
            case 'last_quarter':
            case 'quarter_to_date':
            case 'this_year':
            case 'last_year':
            case 'year_to_date':
            case 'last_7_days':
            case 'last_28_days':
            case 'last_30_days':
            case 'last_60_days':
            case 'last_90_days':
            case 'last_120_days':
            case 'last_180_days':
            case 'last_365_days':
                return '1d';

            case 'last_x_weeks':
                return '1w';

            case 'last_week':
            case 'next_week':
            case 'this_week':
            case 'week_to_date':
            case 'last_24_hours':
            case 'last_12_hours':
            case 'last_x_hours':
                return '1h';

            case 'yesterday':
            case 'today':
            case 'last_60_minutes':
            case 'last_x_minutes':
                return '1m';

            default:
            case 'custom':
            case 'custom_duration':
            case 'custom_to_date':
                const days: number = this.days;
                if (days > 13) {
                    return '1d';
                } else if (days > 1) {
                    return '1h';
                } else {
                    return '1m';
                }
        }
    }

    static getEndOf(time: moment.Moment, interval: string): moment.Moment {
        if (time && interval?.length) {
            let endOf;

            // Grab last part of interval.  Could be "12d", for example
            interval = interval.substring(interval.length - 1);

            switch (interval) {
                case 'm':
                    endOf = 'minute';
                    break;
                case 'h':
                    endOf = 'hour';
                    break;
                case 'd':
                    endOf = 'day';
                    break;
                case 'w':
                    endOf = 'week';
                    break;
                case 'M':
                    endOf = 'month';
                    break;
                case 'q':
                    endOf = 'quarter';
                    break;
                case 'y':
                    endOf = 'year';
                    break;
            }

            if (endOf) {
                time = time.clone().endOf(endOf);
            }
        }

        return time;
    }
}
