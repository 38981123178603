import { colors } from 'components/_UI/designSystem';

const { blues, greys } = colors;
export const customTheme = {
    light: {
        layoutPicker: {
            default: {
                background: greys.body,
                icon: greys.anchor,
            },
            hover: {
                background: blues.persian,
                icon: greys.white,
            },
            active: {
                background: blues.persian,
                icon: greys.white,
            },
        },
    },
    dark: {
        layoutPicker: {
            default: {
                background: greys.night,
                icon: greys.lead,
            },
            hover: {
                background: blues.sky,
                icon: greys.white,
            },
            active: {
                background: blues.sky,
                icon: greys.white,
            },
        },
    },
};
