/**
 * Encapuslates functionality around geo data
 *
 * Created by dboyd on 4/25/20
 */
import { RegionType } from './types';
import DataSet from '@sprinklr/stories/analytics/DataSet';
import { GeoData } from './GeoData';

type GeoItemSlice = {
    value: Date;
    data: GeoData;
};

export class GeoDataSlices {
    private overallMin: number;
    private overallMax: number;

    constructor(public items: GeoItemSlice[]) {}

    static create(dataSet: DataSet, regionType: RegionType): GeoDataSlices {
        // check for dims - if there aren't any, dataSet.toXY() throws an error
        if (!dataSet || !dataSet.dimensions || dataSet.dimensions.length < 1) {
            return new GeoDataSlices([]);
        }

        dataSet = dataSet.zeroKill();
        const dataSetSlices = dataSet.groupBy(dataSet.dimensions[1]);

        // sort by date
        dataSetSlices.sort((a, b) => a.value - b.value);

        const items = dataSetSlices.map(({ value, data }) => ({
            value,
            data: GeoData.create(data, regionType),
        }));

        return new GeoDataSlices(items);
    }

    get length(): number {
        return this.items.length;
    }

    getMinMax(): { overallMin: number; overallMax: number } {
        if (this.overallMin === undefined) {
            const minMaxes = this.items.map(item => item.data.getMinMax());
            const minValues = minMaxes.map(value => value.overallMin);
            const maxValues = minMaxes.map(value => value.overallMax);
            this.overallMin = Math.min(...minValues);
            this.overallMax = Math.max(...maxValues);
        }

        return { overallMin: this.overallMin, overallMax: this.overallMax };
    }
}
