import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';

export interface Version extends CommonDoc {
    masterId?: string;
    versionName?: string;
    treeDirty?: boolean;
    saved?: number;
    savedByUserId?: number;
    immutable?: boolean;
}

export const VersionFields = `
versionId immutable treeDirty masterId
`;
