import { observable } from 'mobx';
export interface User {
    id: number;
    firstName?: string;
    lastName?: string;
    emailAddress?: string;
    profileImageUrl?: string;
    passwordLoginDisabled?: boolean;
    visibleId?: string;
}

export class UserImpl implements User {
    @observable id: number;
    @observable firstName: string;
    @observable lastName: string;
    @observable emailAddress: string;
    @observable profileImageUrl: string;
    @observable passwordLoginDisabled: boolean;
    @observable visibleId: string;

    static readonly graphQLFields = `
        id
        firstName
        lastName
        emailAddress
        profileImageUrl
        passwordLoginDisabled
        visibleId
    `;
}

export const DEFAULT_AVATAR_URL =
    'https://sprcdn-assets.sprinklr.com/1/profile-a74f6bfa-784f-416a-9e68-40d36d4910de-176564324.png';
