import { action, observable } from 'mobx';
import { BackgroundImage, Ext, ThemeInterface } from '@sprinklr/stories/widget/Widget';
import { BrandColors } from '@sprinklr/display-builder/stores/PanelTemplatesStore/PanelTemplatesTheme/constants';

export class ExtImpl implements Ext {
    @observable padding = 40;
    @observable showGuides = false;
    @observable colorGrid = 'rgb(55, 55, 65)';
    @observable showGridLayout = false;
    @observable snapToGrid = false;
    @observable gutter = 20;
    @observable margin = {
        enabled: false,
        top: null,
        right: null,
        bottom: null,
        left: null,
    };
    @observable grid = {
        gap: 20,
        columns: 12,
        rows: 6,
    };
}

export class LegacyThemeImpl implements ThemeInterface {
    @observable colorPrimary?: string = '';
    @observable colorSecondary?: string = BrandColors.red;
    @observable colorBackground?: string = '';
    @observable colorNegative?: string = '';
    @observable colorPostive?: string = '';
    @observable colorFont?: string;
    @observable fontFamilyPrimary?: string = '';
    @observable fontFamilySecondary?: string = '';
    @observable fontSizePrimary?: string = '';
    @observable fontSizeSecondary?: string;
    @observable backgroundImage?: BackgroundImage = { url: '' };
    @observable ext?: Ext;

    @action
    setValues(opts?: any): this {
        // console.log('opts', opts);
        // console.log('this', this);
        if (opts) {
            const ext: any = opts.ext;
            delete opts.ext;

            Object.assign(this as any, opts);

            // map over the ext to make sure the keys are observable
            Object.assign(this.ext, ext);
        }
        return this;
    }
}
