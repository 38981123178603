import { action, observable } from 'mobx';
import { TextShadowOptions, WidgetLabelInterface } from '@sprinklr/stories/widget/Widget';
import { WIDGET_LABEL_SIZE } from 'components/Widget/WidgetLabel/constants';
import { TEXT_SHADOW_DEFAULT } from '../constants';

export class WidgetLabelImpl implements WidgetLabelInterface {
    @observable titleText = 'Header';
    @observable enabled = false;
    @observable size: number = WIDGET_LABEL_SIZE.primary;
    @observable textShadow: TextShadowOptions = TEXT_SHADOW_DEFAULT;

    @action
    setValues(opts?: any): this {
        if (opts) {
            if (opts.enabled === null) {
                opts.enabled = this.enabled;
            }
            if (opts.titleText === null) {
                opts.titleText = this.titleText;
            }
            if (opts.size === null) {
                opts.size = WIDGET_LABEL_SIZE.primary;
            }
            if (opts.textShadow === null) {
                opts.textShadow = TEXT_SHADOW_DEFAULT;
            }
            Object.assign(this as any, opts);
        }
        return this;
    }
}
