import { CommonDocFields, CommonDocImpl } from '../CommonDoc/CommonDoc';
import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';
import { Screen } from '../Screen/Screen';
import { observable } from 'mobx';
/**
 * Created by dboyd on 11/19/19.
 */
export interface LayoutCustom extends CommonDoc {
    screens: Screen[];
    layoutType: string;
    deleted: boolean;
}

export const LayoutCustomFields = `
${CommonDocFields}
    screens {
        x y h w
    }
    layoutType
    deleted
`;

export class LayoutCustomImpl extends CommonDocImpl implements LayoutCustom {
    @observable screens: Screen[];
    @observable layoutType: string;
    @observable deleted: boolean;
}
