import { Callback, DisplayEventType, RemoteCallback } from './types';
import {
    SceneChangePayload,
    StoryboardChangePayload,
} from '@sprinklr/display-builder/containers/Remote/Remote';

export default class DisplayPubSub {
    listeners: Partial<Record<DisplayEventType, (Callback | RemoteCallback)[]>> = {};

    getListeners = (eventType: DisplayEventType) => {
        return this.listeners[eventType] ?? [];
    };

    hasListenersByType = (eventType: DisplayEventType) => {
        return !!(this.listeners[eventType] ?? []).length;
    };

    hasListeners = () => {
        return Object.keys(this.listeners)?.some(this.hasListenersByType);
    };

    on = (eventType: DisplayEventType, callback: Callback | RemoteCallback) => {
        if (!this.listeners[eventType]) {
            this.listeners[eventType] = [];
        }

        this.listeners[eventType].push(callback);
    };

    off = (eventType: DisplayEventType, callback: Callback | RemoteCallback) => {
        this.listeners[eventType]?.forEach((listener, index) => {
            if (listener === callback) {
                this.listeners[eventType].splice(index, 1);
            }
        });
    };

    emit = (eventType: string, payload: any) => {
        this.listeners[eventType]?.forEach(listener => {
            try {
                listener?.(payload);
            } catch (e) {
                console.error(e);
            }
        });
    };

    onClose = (callback: Callback) => {
        this.on('close', callback);
    };

    onLoad = (callback: Callback) => {
        this.on('load', callback);
    };

    onBack = (callback: Callback) => {
        this.on('back', callback);
    };

    // Events
    changeStoryboard = (payload: StoryboardChangePayload) => {
        this.emit('changeStoryboard', payload);
    };

    changeScene = (payload: SceneChangePayload) => {
        this.emit('changeScene', payload);
    };

    prev = () => {
        this.emit('prev', null);
    };

    next = () => {
        this.emit('next', null);
    };

    pause = () => {
        this.emit('pause', null);
    };

    play = () => {
        this.emit('play', null);
    };
}
