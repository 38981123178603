import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import jp from './locales/ja_JP.json';
import en from './locales/en_US.json';

const resources = {
    en: { translation: en },
    ja: { translation: jp },
};

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init(
        {
            debug: false,
            resources,
            fallbackLng: 'en', // use en if detected lng is not available

            saveMissing: true, // send not translated keys to endpoint

            keySeparator: false, // we do not use keys in form messages.welcome
            nsSeparator: false,
            interpolation: {
                escapeValue: false, // react already safes from xss
                prefix: '${',
                suffix: '}',
            },
            detection: {
                order: ['querystring', 'htmlTag', 'localStorage'],
                lookupQuerystring: 'lang',
                lookupLocalStorage: 'i18nextLng',
            },
        },
        err => {
            // console.error(err);
        }
    );

export default i18n;
