/* tslint:disable:no-empty */
import { VideoPlayer, VideoPlayerOptions } from './Video';
import { VideoBase } from './VideoBase';

let INVALID_VIDEO_URLS = new Set();

export class VideoJs extends VideoBase implements VideoPlayer {
    private static loading: Promise<any>;
    private shutdown: boolean;

    constructor(muted = false) {
        super(muted);
        this.shutdown = false;
    }

    start(container: any, options: VideoPlayerOptions, callbackStopped: () => boolean) {
        if (this.shutdown) {
            // once stop has been called, consider this instance shut down and ignore start calls.
            // console.log('stop already called, and start being called!');
            return;
        }
        (options as any).muted = this.muted;

        this.placeholder = this.findVideoPlaceholder(container);
        if (this.placeholder) {
            this.loadPlayer().then(() => {
                if (this.shutdown || INVALID_VIDEO_URLS.has(options.url)) {
                    // in case the promise is resolving after a call to stop, abort the rest of the
                    // startup as it leaves videojs in a potentially invalid state with a component
                    // that's not attached to the DOM, and stop might not get called again, leading
                    // to significant leaks of not just videojs stuff, but a load of react stuff as
                    // well due to fiber references, event handlers, DOM nodes, etc.
                    // console.log('stop already called, and start.loadPlayer promise resolving!!');
                    return;
                }
                const videojs = (window as any).videojs;

                let wrapper = document.createElement('div');

                // Note: hwz=on is funky attribute required for BrightSign player to work right
                wrapper.innerHTML = `<video class="video-js vjs-default-skin post_video" frameborder="0" hwz="on">
                    <source src="${options.url}" type="video/mp4" />
                    <p className="vjs-no-js">
                        To view this video please enable JavaScript, and consider upgrading to a web browser that
                        <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
                    </p>
                </video>`;

                container.insertBefore(wrapper.firstChild, container.childNodes[0]);

                container.firstChild.onerror = () => {
                    if (INVALID_VIDEO_URLS.size > 512) {
                        INVALID_VIDEO_URLS = new Set();
                    }
                    INVALID_VIDEO_URLS.add(options.url);
                };

                if (!options.hideMarkerAfterPlay) {
                    this.placeholder.style.display = 'none';
                }

                wrapper = null;

                // videojs 7.0.5 appears to leak ResizeManagers and event handlers, the following line is
                // required to avoid a serious memory leak! If upgrading to a newer version, take some time
                // to profile the memory use with frequently-rotated videos.
                // see https://github.com/videojs/video.js/issues/5339
                (options as any).resizeManager = false;

                this.player = videojs(
                    container.firstChild,
                    Object.assign({}, options, { loop: false })
                );
                this.player.ready(() => {
                    this.player.play();

                    this.player.one('playing', (event: any) => {
                        if (options.hideMarkerAfterPlay && this.placeholder) {
                            // Hide the placeholder
                            this.placeholder.style.display = 'none';
                        }
                    });

                    this.player.on('ended', (event: any) => {
                        if (!callbackStopped || callbackStopped()) {
                            this.player.play();
                        }
                    });

                    this.player.one('error', (event: any) => {
                        // Hide error block for
                        //   https://sprinklr.atlassian.net/browse/DISPLAY-1467
                        const errorMessage = container.querySelector('.vjs-error-display');
                        if (errorMessage) {
                            errorMessage.style.display = 'none';
                        }
                    });
                });
            });
        }
    }

    // Stop and destroy the video player instance
    stop(): void {
        // don't inhibit repeat calls - no problem running this again, just set a flag to ensure
        // async start calls don't proceed once stop is called.
        this.shutdown = true;

        // Show our marker div again
        if (this.placeholder) {
            this.placeholder.style.display = '';
            this.placeholder = null;
        }

        if (this.player) {
            const videojs = (window as any).videojs;
            const id = this.player.id();

            if (!this.player.paused()) {
                this.player.pause();
            }

            this.player.off('playing');
            this.player.off('ended');
            this.player.off('error');

            // Cleanup and remove the video DOM elements
            // Note: This throws exceptions sometimes
            try {
                this.player.dispose();

                // VideoJS keeps these ids in the map so map keeps growing.
                // So clean out disposed players.
                const players = videojs.getPlayers();
                if (players) {
                    delete players[id];
                }
            } catch (e) {
                console.log('videojs dispose error', e);
            }

            this.player = null;
        }
    }

    // Pause the video
    pause(): void {
        // Show our marker div
        if (this.placeholder) {
            this.placeholder.style.display = '';
        }

        if (this.player) {
            this.player.pause();
        }
    }

    // Play the video
    play(): void {
        if (this.player) {
            this.player.play();
        }

        // Hide the marker div
        if (this.placeholder) {
            this.placeholder.style.display = 'none';
        }
    }

    // Load the VideoJs player API dynamically to cut down on initial overall app size
    private loadPlayer(): Promise<any> {
        if (VideoJs.loading) {
            return VideoJs.loading;
        }

        VideoJs.loading = new Promise<void>((resolve, reject) => {
            function load() {
                const tag = document.createElement('script');

                // If changing versions, be sure to profile for memory leaks. our use case makes us particularly
                // vulnerable to resources leaked by Player.dispose()
                tag.src = '//vjs.zencdn.net/7.0.5/video.min.js';
                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag?.parentNode.insertBefore(tag, firstScriptTag);

                wait();
            }

            let waitTime = 4000;
            const timeSlice = 50;

            // Wait for up to 4 seconds for the library to load
            function wait() {
                if (waitTime > 0) {
                    setTimeout(() => {
                        waitTime -= timeSlice;

                        const videojs = (window as any).videojs;
                        if (videojs) {
                            resolve();
                        } else {
                            wait();
                        }
                    }, timeSlice);
                } else {
                    reject();
                }
            }

            load();
        });

        return VideoJs.loading;
    }
}
