import { CommonDocImpl } from 'models/CommonDoc/CommonDoc';
import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';
import { observable } from 'mobx';

export interface HmacKey {
    id: string;
    secret: string;
}

export interface SharedSecretKey extends SharedSecretKeyInput {
    id: string;
    base64Id?: string;
    lastUsed?: number;
    lastUsedByIpAddress?: string;
}

export interface SharedSecretKeyInput extends CommonDoc {
    active?: boolean;
    expires?: number;
}

export class SharedSecretKeyImpl extends CommonDocImpl implements SharedSecretKey {
    @observable base64Id: string;
    @observable active: boolean;
    @observable expires: number;
    @observable lastUsed: number;
    @observable lastUsedByIpAddress: string;
}

export const SharedSecretKeyFields = `
        id base64Id
        name description created
        active
        lastUsed
        lastUsedByIpAddress
`;
