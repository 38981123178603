import { overwriteMerge } from 'utils/ArrayUtils/ArrayUtils';
import { CommonDocFields, CommonDocImpl } from '../CommonDoc/CommonDoc';
import { BrandColors } from '../../stores/PanelTemplatesStore/PanelTemplatesTheme/constants';
import { action, observable, toJS } from 'mobx';
import merge from 'deepmerge';
import { scrubber } from 'utils/GenerateStyles/GenerateStyles';

import {
    Theme,
    ThemeItem,
    Themed,
    Typography,
    Background,
    BackgroundPosition,
    BackgroundRepeat,
    BackgroundSize,
    ColorBlend,
    ColorOverride,
    ColorPalette,
    ColorPaletteType,
    Font,
    FontCase,
    FontStyle,
    FontWeight,
    Monochrome,
    Ranked,
} from '@sprinklr/stories/theme/Theme';

export {
    Theme,
    ThemeItem,
    Themed,
    Typography,
    Background,
    BackgroundPosition,
    BackgroundRepeat,
    BackgroundSize,
    ColorBlend,
    ColorOverride,
    ColorPalette,
    ColorPaletteType,
    Font,
    FontCase,
    FontStyle,
    FontWeight,
    Monochrome,
    Ranked,
} from '@sprinklr/stories/theme/Theme';

export const FontFields = `
    family
    url
    color
    size
    sizeLiteral
    samId
    name
    description
    subFamily
    weight
    style
    textTransform
`;

export const TypographyFields = `
    color size
    sizeLiteral
    primary {
        ${FontFields}
    }
    secondary {
        ${FontFields}
    }
`;

export const BackgroundFields = `
    url size repeat color position
`;

export const ColorPaletteFields = `
    type solid
    monochrome {
        startColor endOffset
    }
    colorBlend {
        startColor endColor
    }
    ranked {
        colors contrast
    }
    overrides
`;

export const ThemeFields = `
        typography {
            ${TypographyFields}
        }
        background {
            ${BackgroundFields}
        }
        colorPalette {
            ${ColorPaletteFields}
        }
`;

export const ThemeFragment = `
   fragment themeFrag on Theme {
     ${ThemeFields}
   }
`;

export const ChildThemeFragment = `
   fragment themeFrag on Theme {
     ${ThemeFields}
     sourceId
   }
`;
export const ThemeSnippet = `
    theme {
        ...themeFrag
    }
`;

export const ThemeItemFields = `
    ${CommonDocFields}
    ${ThemeFields}
    clientDefault
    presentationsDefault
`;

export class FontImpl implements Font {
    @observable family = '';
    @observable url?: string = '';
    @observable color?: string = '';
    @observable size?: number = null;
    @observable samId?: string; // id from Sprinklr Core SAM
    @observable name?: string;
    @observable description?: string;
    @observable subFamily?: string;
    @observable weight?: FontWeight;
    @observable style?: FontStyle;
    @observable textTransform?: FontCase = null;
}

export class TypographyImpl implements Typography {
    @observable color?: string = '';
    @observable size?: number = null;
    @observable primary?: FontImpl;
    @observable secondary?: FontImpl;
}

export class BackgroundImpl implements Background {
    @observable url?: string = '';
    @observable size?: BackgroundSize = '';
    @observable repeat?: BackgroundRepeat = '';
    @observable color?: string = '';
    @observable position?: BackgroundPosition = '';
}

export class ColorBlendImpl implements ColorBlend {
    @observable startColor?: string = '';
    @observable endColor?: string = '';
}

export class MonochromeImpl implements Monochrome {
    @observable startColor?: string = '';
    @observable endOffset?: number = null;
}

export class RankedImpl implements Ranked {
    @observable colors?: string[] = [];
    @observable contrast?: number = null;
}

export class ColorPaletteImpl implements ColorPalette {
    @observable type?: ColorPaletteType = '';
    @observable monochrome?: MonochromeImpl;
    @observable colorBlend?: ColorBlendImpl;
    @observable solid = '';
    @observable ranked: RankedImpl;
    @observable overrides: ColorOverride[] = [];
    @action
    setValues(opts?: any): ColorPalette {
        if (opts) {
            // must use toJS for any objects with observables or else merge will treat Arrays as Objects
            return merge.all([toJS(getEmptyColorPalette()), scrubber({ ...opts })], {
                arrayMerge: overwriteMerge,
            });
        }
        return getEmptyColorPalette();
    }
}

export class ThemeImpl implements Theme {
    @observable typography?: TypographyImpl;
    @observable background?: BackgroundImpl;
    @observable colorPalette?: ColorPaletteImpl;
    @observable sourceId?: string;
    @action
    setValues(opts?: any): Theme {
        if (opts) {
            // must use toJS for any objects with observables or else merge will treat Arrays as Objects
            return merge.all([toJS(getEmptyTheme()), scrubber({ ...opts })], {
                arrayMerge: overwriteMerge,
            });
        }
        return getEmptyTheme();
    }
}

export class ThemeItemImpl extends CommonDocImpl implements Theme {
    @observable typography?: TypographyImpl;
    @observable background?: BackgroundImpl;
    @observable colorPalette?: ColorPaletteImpl;
    @observable clientDefault?: boolean;
    @observable presentationsDefault?: boolean;
}

export const themeDefaultValues: Theme = {
    typography: {
        primary: {
            family: 'OpenSansBold',
            url: '',
            textTransform: 'inherit',
        },
        secondary: {
            family: 'OpenSansRegular',
            url: '',
            textTransform: 'inherit',
        },
        color: '#fff',
        size: null,
    },
    background: {
        url: '',
        color: BrandColors.grey,
    },
    colorPalette: {
        type: 'monochrome',
        monochrome: {
            startColor: BrandColors.blue,
            endOffset: 50,
        },
        colorBlend: {
            startColor: BrandColors.teal,
            endColor: BrandColors.purple,
        },
        solid: BrandColors.darkBlue,
        ranked: {
            colors: [BrandColors.blue, BrandColors.lightBlue, BrandColors.darkBlue],
            contrast: 0,
        },
        overrides: [
            {
                label: 'Negative',
                color: BrandColors.red,
            },
        ],
    },
};

export const getEmptyColorPalette = () => {
    const colorPalette = new ColorPaletteImpl();
    colorPalette.monochrome = new MonochromeImpl();
    colorPalette.colorBlend = new ColorBlendImpl();
    colorPalette.ranked = new RankedImpl();

    return colorPalette;
};

export const getEmptyTheme = () => {
    const theme = new ThemeImpl();
    theme.typography = new TypographyImpl();
    theme.typography.primary = new FontImpl();
    theme.typography.secondary = new FontImpl();
    theme.colorPalette = new ColorPaletteImpl();
    theme.colorPalette.colorBlend = new ColorBlendImpl();
    theme.colorPalette.monochrome = new MonochromeImpl();
    theme.colorPalette.ranked = new RankedImpl();
    theme.background = new BackgroundImpl();
    return theme;
};

export const emptyThemeItem: ThemeItem = {
    name: '',
    clientDefault: false,
    presentationsDefault: false,
    sourceId: null,
    typography: {
        color: '',
        size: null,
        primary: {
            family: '',
            url: '',
            color: '',
            size: null,
            samId: null,
            name: null,
            description: null,
            subFamily: null,
            weight: null,
            style: null,
            textTransform: null,
        },
        secondary: {
            family: '',
            url: '',
            color: '',
            size: null,
            samId: null,
            name: null,
            description: null,
            subFamily: null,
            weight: null,
            style: null,
            textTransform: null,
        },
    },
    background: {
        url: null,
        size: null,
        repeat: null,
        color: '',
        position: null,
    },
    colorPalette: {
        type: '',
        solid: '',
        monochrome: {
            startColor: '',
            endOffset: null,
        },
        colorBlend: {
            startColor: '',
            endColor: '',
        },
        ranked: {
            colors: null,
            contrast: null,
        },
    },
};

export const builtInThemes: ThemeItem[] = [
    {
        name: 'Sprinklr Default (Dark)',
        id: 'sprinklr default dark',
        clientDefault: true,
        presentationsDefault: false,
        typography: {
            color: 'rgb(255, 255, 255)',
            size: null,
            primary: {
                family: 'Museo Sans Rounded',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            secondary: {
                family: 'Museo Sans Rounded',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            sizeLiteral: null,
        },
        background: {
            url: null,
            size: null,
            repeat: null,
            color: 'rgb(35, 35, 45)',
            position: null,
        },
        colorPalette: {
            type: 'colorBlend',
            solid: 'rgba(41, 184, 220, 0.53)',
            monochrome: { startColor: 'rgb(25, 174, 211)', endOffset: 50 },
            colorBlend: { startColor: 'rgb(100, 100, 220)', endColor: 'rgb(255, 96, 96)' },
            ranked: {
                colors: ['rgb(25, 174, 211)', 'rgb(100, 100, 220)', 'rgb(255, 96, 96)'],
                contrast: 0,
            },
        },
    },
    {
        name: 'Sprinklr Default (Light)',
        id: 'sprinklr default light',
        clientDefault: false,
        presentationsDefault: true,
        typography: {
            color: 'rgba(0, 0, 0, 1)',
            size: null,
            primary: {
                family: 'Museo Sans Rounded',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            secondary: {
                family: 'Museo Sans Rounded',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            sizeLiteral: null,
        },
        background: {
            url: null,
            size: null,
            repeat: null,
            color: 'rgba(255, 255, 255, 1)',
            position: null,
        },
        colorPalette: {
            type: 'ranked',
            solid: '#005fc8',
            monochrome: { startColor: '#0c7edd', endOffset: 50 },
            colorBlend: { startColor: '#20b796', endColor: '#3c3c78' },
            ranked: {
                colors: [
                    'rgba(32, 183, 150, 1)',
                    'rgba(255, 210, 0, 1)',
                    'rgba(140, 67, 255, 1)',
                    'rgba(60, 180, 210, 1)',
                    'rgba(255, 148, 33, 1)',
                    'rgba(0, 95, 200, 1)',
                    'rgba(255, 96, 96, 1)',
                    'rgba(12, 126, 221, 1)',
                ],
                contrast: 0,
            },
            overrides: [
                {
                    label: 'Positive',
                    color: '#20b796',
                },
                {
                    label: 'Negative',
                    color: '#ff6060',
                },
                {
                    label: 'Neutral',
                    color: '#bbb',
                },
            ],
        },
    },
    {
        name: 'PNW',
        id: 'PNW',
        clientDefault: false,
        presentationsDefault: false,
        typography: {
            color: 'rgb(255, 255, 255)',
            size: null,
            primary: {
                family: 'SourceSansPro Regular',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            secondary: {
                family: 'SourceSansPro Regular',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            sizeLiteral: null,
        },
        background: {
            url:
                'https://sprcdn-assets.sprinklr.com/349/eberhard-grossgasteiger-389784-eb2ae228-3c4c-4ae4-baac-c534be002385-1976405667.jpg?dimensions/5472/3648',
            size: 'cover',
            repeat: null,
            color: 'rgb(8, 76, 97)',
            position: null,
        },
        colorPalette: {
            type: 'solid',
            solid: 'rgba(41, 184, 220, 0.53)',
            monochrome: { startColor: 'rgb(25, 174, 211)', endOffset: 50 },
            colorBlend: { startColor: 'rgb(255, 210, 0)', endColor: 'rgb(24, 146, 137)' },
            ranked: {
                colors: ['rgb(25, 174, 211)', 'rgb(255, 210, 0)', 'rgb(24, 146, 137)'],
                contrast: 0,
            },
        },
    },
    {
        name: 'racecar',
        id: 'racecar',
        clientDefault: false,
        presentationsDefault: false,
        typography: {
            color: 'rgb(52, 61, 61)',
            size: null,
            primary: {
                family: 'Orkney Regular',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            secondary: {
                family: 'Orkney Regular',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            sizeLiteral: null,
        },
        background: {
            url:
                'https://sprcdn-assets.sprinklr.com/349/bharath-suresh-388435-unsplash-58aaa52c-c4f1-4560-8f75-0bef083e7946-438590537.jpg?dimensions/3456/4608',
            size: 'cover',
            repeat: 'no-repeat',
            color: 'rgb(19, 19, 16)',
            position: null,
        },
        colorPalette: {
            type: 'colorBlend',
            solid: 'rgba(24, 146, 137, 0.73)',
            monochrome: { startColor: 'rgb(255, 96, 96)', endOffset: 50 },
            colorBlend: { startColor: 'rgb(255, 225, 0)', endColor: 'rgb(50, 185, 175)' },
            ranked: {
                colors: ['rgb(255, 96, 96)', 'rgb(255, 225, 0)', 'rgb(50, 185, 175)'],
                contrast: 0,
            },
        },
    },
    {
        name: 'Black Nite',
        id: 'Black_Nite',
        clientDefault: false,
        presentationsDefault: false,
        typography: {
            color: 'rgb(255, 255, 255)',
            size: null,
            primary: {
                family: 'Simplifica',
                url:
                    'https://sprcdn-assets.sprinklr.com/349/SIMPLIFICA_Typeface-6030d990-83e7-4af4-a0aa-7f65af8f3795-623975064.ttf',
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            secondary: {
                family: 'RobotoCondensed Light',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            sizeLiteral: null,
        },
        background: {
            url:
                'https://sprcdn-assets.sprinklr.com/349/dark_dotted2__2X-2eedbae6-d7b3-443f-be47-ea306ca8e1f1-927220257.png?dimensions/14/14',
            size: '2%',
            repeat: null,
            color: 'rgb(25, 25, 33)',
            position: null,
        },
        colorPalette: {
            type: 'monochrome',
            solid: 'rgba(72, 72, 107, 0.55)',
            monochrome: { startColor: 'rgb(64, 64, 66)', endOffset: 50 },
            colorBlend: { startColor: 'rgb(68, 68, 75)', endColor: 'rgb(26, 26, 29)' },
            ranked: {
                colors: ['rgb(64, 64, 66)', 'rgb(68, 68, 75)', 'rgb(26, 26, 29)'],
                contrast: 0,
            },
        },
    },
    {
        name: 'Cupcake',
        id: 'Cupcake',
        clientDefault: false,
        presentationsDefault: false,
        typography: {
            color: 'rgb(255, 255, 255)',
            size: null,
            primary: {
                family: 'Ovo Regular',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            secondary: {
                family: 'Ovo Regular',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            sizeLiteral: null,
        },
        background: {
            url: null,
            size: null,
            repeat: null,
            color: 'rgb(102, 106, 134)',
            position: null,
        },
        colorPalette: {
            type: 'colorBlend',
            solid: 'rgba(72, 72, 107, 0.55)',
            monochrome: { startColor: 'rgb(234, 181, 37)', endOffset: 50 },
            colorBlend: { startColor: 'rgb(149, 184, 209)', endColor: 'rgb(237, 175, 184)' },
            ranked: {
                colors: ['rgb(234, 181, 37)', 'rgb(149, 184, 209)', 'rgb(237, 175, 184)'],
                contrast: 0,
            },
        },
    },
    {
        name: 'Golden',
        id: 'Golden',
        clientDefault: false,
        presentationsDefault: false,
        typography: {
            color: 'rgb(229, 229, 229)',
            size: null,
            primary: {
                family: 'Lora Bold',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            secondary: {
                family: 'Lora Regular',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            sizeLiteral: null,
        },
        background: {
            url: null,
            size: null,
            repeat: null,
            color: 'rgb(18, 23, 32)',
            position: null,
        },
        colorPalette: {
            type: 'monochrome',
            solid: 'rgba(72, 72, 107, 0.55)',
            monochrome: { startColor: 'rgb(234, 181, 37)', endOffset: 50 },
            colorBlend: { startColor: 'rgb(68, 68, 75)', endColor: 'rgb(26, 26, 29)' },
            ranked: {
                colors: ['rgb(234, 181, 37)', 'rgb(68, 68, 75)', 'rgb(26, 26, 29)'],
                contrast: 0,
            },
        },
    },
    {
        name: 'Campfire',
        id: 'Campfire',
        clientDefault: false,
        presentationsDefault: false,
        typography: {
            color: 'rgb(255, 255, 255)',
            size: null,
            primary: {
                family: 'Bernier Shade',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            secondary: {
                family: 'RobotoCondensed Regular',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            sizeLiteral: null,
        },
        background: {
            url: null,
            size: null,
            repeat: null,
            color: 'rgb(8, 76, 97)',
            position: null,
        },
        colorPalette: {
            type: 'colorBlend',
            solid: 'rgba(72, 72, 107, 0.55)',
            monochrome: { startColor: 'rgb(219, 58, 52)', endOffset: 50 },
            colorBlend: { startColor: 'rgb(219, 58, 52)', endColor: 'rgb(255, 200, 87)' },
            ranked: {
                colors: ['rgb(219, 58, 52)', 'rgb(219, 58, 52)', 'rgb(255, 200, 87)'],
                contrast: 0,
            },
        },
    },
    {
        name: 'Retro',
        id: 'Retro',
        clientDefault: false,
        presentationsDefault: false,
        typography: {
            color: 'rgb(247, 225, 91)',
            size: null,
            primary: {
                family: 'Bourbon Grotesque',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            secondary: {
                family: 'Bourbon Grotesque',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            sizeLiteral: null,
        },
        background: {
            url:
                'https://sprcdn-assets.sprinklr.com/349/dark-matter-3c3aafd4-1df7-402d-9910-39e79d4633b0-919619635.png?dimensions/7/7',
            size: '2%',
            repeat: null,
            color: 'rgb(67, 87, 173)',
            position: null,
        },
        colorPalette: {
            type: 'colorBlend',
            solid: 'rgba(72, 72, 107, 0.55)',
            monochrome: { startColor: 'rgb(100, 100, 220)', endOffset: 50 },
            colorBlend: { startColor: 'rgb(255, 148, 33)', endColor: 'rgb(143, 30, 62)' },
            ranked: {
                colors: ['rgb(100, 100, 220)', 'rgb(255, 148, 33)', 'rgb(143, 30, 62)'],
                contrast: 0,
            },
        },
    },
    {
        name: 'Popsicle',
        id: 'Popsicle',
        clientDefault: false,
        presentationsDefault: false,
        typography: {
            color: 'rgb(255, 255, 255)',
            size: null,
            primary: {
                family: 'Rubik Bold',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            secondary: {
                family: 'Rubik Regular',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            sizeLiteral: null,
        },
        background: {
            url: null,
            size: null,
            repeat: null,
            color: 'rgb(238, 66, 102)',
            position: null,
        },
        colorPalette: {
            type: 'solid',
            solid: 'rgb(180, 48, 76)',
            monochrome: { startColor: 'rgb(0, 95, 200)', endOffset: 23 },
            colorBlend: { startColor: 'rgb(255, 210, 0)', endColor: 'rgb(217, 181, 17)' },
            ranked: {
                colors: ['rgb(0, 95, 200)', 'rgb(255, 210, 0)', 'rgb(217, 181, 17)'],
                contrast: 0,
            },
        },
    },
    {
        name: 'Summer',
        id: 'Summer',
        clientDefault: false,
        presentationsDefault: false,
        typography: {
            color: 'rgb(252, 252, 252)',
            size: null,
            primary: {
                family: 'Arvo Regular',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            secondary: {
                family: 'Arvo Regular',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            sizeLiteral: null,
        },
        background: {
            url: null,
            size: null,
            repeat: null,
            color: 'rgb(60, 180, 210)',
            position: null,
        },
        colorPalette: {
            type: 'colorBlend',
            solid: 'rgba(72, 72, 107, 0.55)',
            monochrome: { startColor: 'rgb(80, 80, 88)', endOffset: 50 },
            colorBlend: { startColor: 'rgb(255, 210, 0)', endColor: 'rgb(255, 148, 33)' },
            ranked: {
                colors: ['rgb(80, 80, 88)', 'rgb(255, 210, 0)', 'rgb(255, 148, 33)'],
                contrast: 0,
            },
        },
    },
    {
        name: 'Modern',
        id: 'Modern',
        clientDefault: false,
        presentationsDefault: false,
        typography: {
            color: 'rgb(61, 60, 59)',
            size: null,
            primary: {
                family: 'Dense',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            secondary: {
                family: 'Dense',
                url: null,
                color: null,
                size: null,
                samId: null,
                name: null,
                description: null,
                subFamily: null,
                weight: null,
                style: null,
                sizeLiteral: null,
                textTransform: null,
            },
            sizeLiteral: null,
        },
        background: {
            url:
                'https://sprcdn-assets.sprinklr.com/349/grid_noise__2X-1d432b3b-f33e-427f-bc36-e678caa5b118-1297347197.png?dimensions/196/196',
            size: '22%',
            repeat: null,
            color: 'rgb(255, 255, 255)',
            position: null,
        },
        colorPalette: {
            type: 'colorBlend',
            solid: 'rgba(173, 173, 198, 0.55)',
            monochrome: { startColor: 'rgb(231, 231, 234)', endOffset: 50 },
            colorBlend: { startColor: 'rgb(202, 202, 207)', endColor: 'rgb(145, 145, 150)' },
            ranked: {
                colors: ['rgb(231, 231, 234)', 'rgb(202, 202, 207)', 'rgb(145, 145, 150)'],
                contrast: 0,
            },
        },
    },
];
