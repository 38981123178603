import { CommonDocImpl } from 'models/CommonDoc/CommonDoc';
import { observable } from 'mobx';
import { SharedSecretKey } from 'models/SharedSecretKey/SharedSecretKey';

export interface ShareableComponent {
    createdByUserId?: number;
    sharedWithUserIds?: number[];
    sharedWithGroupIds?: string[];
    restricted?: boolean;
}

export type AccessControlledComponent = {
    ipRestricted?: boolean;
    ipWhitelist?: string[];
    lastSecurityChange?: number;
    loginRequired?: boolean;
    externalSsoLoginRequired?: boolean;
    hmacSignatureRequired?: boolean;
    openIdConnectRequired?: boolean;
    passwordRequired?: boolean;
    passwordLocked?: boolean;
    hmacKeys?: SharedSecretKey[];
};

export const AccessControlledFields = `
    loginRequired
    externalSsoLoginRequired
    passwordRequired
    passwordLocked
    ipRestricted
    ipWhitelist
`;

export class AccessControlImpl extends CommonDocImpl {
    @observable ipRestricted?: boolean;
    @observable ipWhitelist?: string[] = [];
    @observable loginRequired?: boolean;
    @observable externalSsoLoginRequired?: boolean;
    @observable hmacSignatureRequired?: boolean;
    @observable openIdConnectRequired?: boolean;
    @observable passwordRequired?: boolean;
    @observable passwordLocked?: boolean;
}
