import { observable } from 'mobx';
import { Watermark } from './Watermark';

export class WatermarkImpl implements Watermark {
    static readonly graphQLFields: string = `
        watermarkRendered
        panelIndex
    `;
    @observable watermarkRendered?: boolean;
    @observable panelIndex?: number;
    @observable imageUrl?: string;

    constructor({
        watermarkRendered,
        panelIndex,
        imageUrl,
    }: {
        watermarkRendered?: boolean;
        panelIndex?: number;
        imageUrl?: string;
    }) {}
}
