import { CommonDocFields, CommonDocImpl } from '../CommonDoc/CommonDoc';
import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';
import { Panel, PanelFields, PanelLight, PanelLightFields } from '../Panel/Panel';
import { LayoutConstrained } from '../LayoutConstrained/LayoutConstrained';
import { VersionCapable, VersionCapableFields } from '../VersionCapable/VersionCapable';
import { Theme, Themed, ThemeImpl, ThemeSnippet } from '../Theme/Theme';
import { Layout } from '../Layout/Layout';
import { observable } from 'mobx';
import { HeaderModel } from 'components/storyboard/StoryboardHeaderFooter/Header/types';
import { PageNumber } from 'components/storyboard/StoryboardHeaderFooter/PageNumber/types';
import { HeaderSnippet } from 'components/storyboard/StoryboardHeaderFooter/Header/options';
import { PageNumberSnippet } from 'components/storyboard/StoryboardHeaderFooter/PageNumber/options';

/**
 * Created by dstelter on 10/3/16.
 */
export interface Scene extends Themed, VersionCapable, CommonDoc, LayoutConstrained {
    panels?: Panel[];
    storyboardId?: string;
    theme?: Theme;
    duration?: number;
    disabled?: boolean;
    sceneTransition?: SceneTransition;
    header?: HeaderModel;
    pageNumber?: PageNumber;
}

// for graphQl
export const SceneTransitionSnippet = `
    sceneTransition {
        type
        easing
        duration
        enabled
    }
`;

export const SceneFields = `
    ${CommonDocFields}
    ${VersionCapableFields}
    storyboardId
    panels {
        ${PanelFields}
    }
    duration
    disabled
    ${ThemeSnippet}
    ${SceneTransitionSnippet}
    ${HeaderSnippet}
    ${PageNumberSnippet}

`;

export interface SceneLight {
    id?: string;
    panels?: PanelLight[];
    userUpdated?: number;
    duration?: number;
    disabled?: boolean;
    header?: HeaderModel;
    pageNumber?: PageNumber;
}

export const SceneLightFields = `
    id
    duration
    disabled
    panels {
        ${PanelLightFields}
    }
    userUpdated
    ${HeaderSnippet}
    ${PageNumberSnippet}

`;

export class SceneImpl extends CommonDocImpl implements Scene {
    @observable panels: Panel[];
    @observable layout: Layout;
    @observable versionId?: string;
    @observable storyboardId?: string;
    @observable theme?: ThemeImpl;
    @observable duration?: number;
    @observable disabled?: boolean;
    @observable sceneTransition?: SceneTransition;
    @observable header: HeaderModel;
    @observable pageNumber: PageNumber;
}

export type SceneTransitionEasing =
    | 'easeBack'
    | 'easeBackIn'
    | 'easeBackInOut'
    | 'easeBackOut'
    | 'easeBounce'
    | 'easeBounceIn'
    | 'easeBounceInOut'
    | 'easeBounceOut'
    | 'easeCircle'
    | 'easeCircleIn'
    | 'easeCircleInOut'
    | 'easeCircleOut'
    | 'easeCubic'
    | 'easeCubicIn'
    | 'easeCubicInOut'
    | 'easeCubicOut'
    | 'easeElastic'
    | 'easeElasticIn'
    | 'easeElasticInOut'
    | 'easeElasticOut'
    | 'easeExp'
    | 'easeExpIn'
    | 'easeExpInOut'
    | 'easeExpOut'
    | 'easeLinear'
    | 'easePoly'
    | 'easePolyIn'
    | 'easePolyInOut'
    | 'easePolyOut'
    | 'easeQuad'
    | 'easeQuadIn'
    | 'easeQuadInOut'
    | 'easeQuadOut'
    | 'easeSin'
    | 'easeSinIn'
    | 'easeSinInOut'
    | 'easeSinOut';
export type SceneTransitionType =
    | 'fade'
    | 'slide_down'
    | 'slide_down_fade'
    | 'slide_over'
    | 'slide_over_fade'
    | 'scale_fade';

export type SceneTransition = {
    enabled: boolean;
    duration?: number;
    delay?: number;
    easing: SceneTransitionEasing;
    type?: SceneTransitionType;
};
export class SceneTransitionImpl implements SceneTransition {
    @observable type?: SceneTransitionType;
    @observable easing: SceneTransitionEasing;
    @observable delay?: number;
    @observable enabled: boolean;
    @observable duration: number;
}
