export const DefaultPosition = Object.freeze({
    grid: {
        enabled: true,
        columns: {
            start: 0,
            end: 12,
        },
        rows: {
            start: 0,
            end: 6,
        },
    },
});

export const DefaultWidgetPosition = {
    top: '0px',
    left: '0px',
    height: '0px',
    width: '0px',
    grid: {
        enabled: true,
        columns: {
            start: 2,
            end: 10,
        },
        rows: {
            start: 1,
            end: 5,
        },
    },
};

export const BrandColors = {
    lightBlue: '#3CB4D2',
    blue: '#0c7edd',
    darkBlue: '#005fc8',
    violet: '#6464dc',
    purple: '#3c3c78',
    orange: '#ff9421',
    yellow: '#ffd200',
    red: '#ff6060',
    teal: '#20b796',
    negative: '#FF5050',
    grey: '#23232d',
};

export const BrandColorsArray = [
    BrandColors.lightBlue,
    BrandColors.blue,
    BrandColors.darkBlue,
    BrandColors.violet,
    BrandColors.purple,
    BrandColors.orange,
    BrandColors.yellow,
    BrandColors.red,
    BrandColors.teal,
];
