// TODO JT this handles Arabic but we'll need to have a better solution for other languages
export const EN_DAY_OF_WEEK_MONTH_OF_YEAR_VS_AR = {
    MONDAY: 'الاثنين',
    TUESDAY: 'الثلاثاء',
    WEDNESDAY: 'الأربعاء',
    THURSDAY: 'الخميس',
    FRIDAY: 'الجمعة',
    SATURDAY: 'السبت',
    SUNDAY: 'الأحد',
    JANUARY: 'يناير',
    FEBRUARY: 'فبراير',
    MARCH: 'مارس',
    APRIL: 'أبريل',
    MAY: 'مايو',
    JUNE: 'يونيو',
    JULY: 'يوليو',
    AUGUST: 'أغسطس',
    SEPTEMBER: 'سبتمبر',
    OCTOBER: 'أوكتوبر',
    NOVEMBER: 'نوفمبر',
    DECEMBER: 'ديسمبر',
};
