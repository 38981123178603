import { CommonDoc } from '@sprinklr/stories/common/CommonDoc';
import { CommonDocImpl } from 'models/CommonDoc/CommonDoc';
import { observable } from 'mobx';

export type DeviceLoadType = 'LOGO' | 'COLOR';

export interface Device extends CommonDoc {
    locationId?: string;
    config?: DeviceConfig;
}

export interface DeviceConfig {
    loadType?: DeviceLoadType;
    loadColor?: string;
    screenId?: number;
}

class DeviceConfigImpl implements DeviceConfig {
    @observable loadType: 'LOGO';
    @observable screenId: 1;
    @observable loadColor: null;
}

export class DeviceImpl extends CommonDocImpl implements Device {
    @observable locationId?: string;
    @observable config = new DeviceConfigImpl();
}
