import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import fetch from 'node-fetch';
import config from '@sprinklr/display-builder/config';
import omitDeep from 'omit-deep';

// const cache = new InMemoryCache({
//     typePolicies: {
//         Query: {
//             fields: {
//                 storyboards: {
//                     read(existing, { args: { page, size } }) {
//                         // A read function should always return undefined if existing is
//                         // undefined. Returning undefined signals that the field is
//                         // missing from the cache, which instructs Apollo Client to
//                         // fetch its value from your GraphQL server.
//                         return existing && existing.slice((page + 1) * size, (page + 1) * size);
//                     },
//                     // Don't cache separate results based on
//                     // any of this field's arguments.
//                     keyArgs: false,
//                     // Concatenate the incoming list items with
//                     // the existing list items.
//                     merge(existing = [], incoming) {
//                         console.log('incoming', incoming);
//                         return [...existing, ...incoming];
//                     },
//                 },
//             },
//         },
//     },
// });

export const apolloClient = new ApolloClient({
    link: new HttpLink({ uri: `${config.apiRoot}graphql/`, fetch: fetch as any }),
    cache: new InMemoryCache(),
    credentials: 'include',
    // defaultOptions: {
    //     watchQuery: {
    //         nextFetchPolicy: 'cache-and-network',
    //     },
    // },
});

export function removeTypeName<ObjectType>(obj: ObjectType): ObjectType {
    return omitDeep(obj, '__typename');
}
