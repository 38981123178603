export const THUMBNAIL_SERVICE_HOST = 'thumb.sprinklr.com';
export const THUMBNAIL_SERVICE_URI = `https://${THUMBNAIL_SERVICE_HOST}/`;

const FEATURE_WEBP_ALPHA = 'feature_webp_alpha';

export function thumbnailUrl(src: string, maxWidth = 1920, maxHeight = 1080) {
    if (typeof src !== 'string') {
        return src;
    }

    // If it's blank or already using the thumbnailer service, just return early.
    if (!src || src.indexOf(THUMBNAIL_SERVICE_HOST) !== -1) {
        return src;
    }

    // Ensure we have a viable image file extension for the thumbnailer, otherwise we risk clobbering
    // transparency on non-webp compatible browsers like Safari, some IE, some Edge, etc. as the thumb
    // service defaults to JPEG if there's no extension present and the browser doesn't advertise
    // webp compatibility via Accept header.

    const lastDot = src.lastIndexOf('.');

    const extension = -1 === lastDot ? null : src.substr(lastDot + 1).toLowerCase();

    switch (extension) {
        // Non-transparency supporting formats are fine, nothing to clobber.
        case 'jpg':
        case 'jpeg':
            break;

        // these all possibly have transparency and could get wrecked in non-webp browsers
        case 'bmp':
        case 'png':
        case 'apng':
        case 'gif':
        case 'webp':
        default:
            // includes weirdos & no extension at all
            if (window[`spr_${FEATURE_WEBP_ALPHA}`]) {
                break;
            }

            if ((window as any).sprNoCheckBrowserWebpSupport) {
                return src;
            }

            try {
                if (!nullOrigin() && sessionStorage?.getItem(FEATURE_WEBP_ALPHA) === 'true') {
                    break;
                } else {
                    if (!nullOrigin() && !sessionStorage?.getItem(FEATURE_WEBP_ALPHA)) {
                        // do async feature detection - next call to thumbnail function will benefit
                        setTimeout(checkWebpAlphaSupport);
                    }
                    return src;
                }
            } catch (e) {
                console.log('problem with webp detection, will not thumbnail', e);
                (window as any).sprNoCheckBrowserWebpSupport = true;
                return src;
            }
    }

    // make sure we don't apply to any built-in relative assets, e.g. "/public/assets/template-editorial-1.jpg"
    // also filter garbage - if it's a bogus URL don't waste the thumbnailer's resources on it.
    try {
        // To safely parse a relative path we have to provide a base URL.
        const url = new URL(src, 'http://localhost/');
        if (url.hostname === 'localhost') {
            return src;
        }
    } catch (e) {
        // note this try/catch is mostly paranoia - new URL(src, 'http://base.com') form of the constructor seems to
        // tolerate pretty much any garbage for the first param and URI encodes it as necessary, making it a relative
        // URL for the provided base.
        console.log(`Cannot parse URL ${src}, will not pass to thumbnail service. Details:`, e);
        return src;
    }

    return `${THUMBNAIL_SERVICE_URI}?d=${maxWidth}x${maxHeight}&url=${encodeURIComponent(src)}`;
}

/**
 * Will return a URL to the resized image, provided it is loadable by HTMLImageElement. If it fails the preflight check
 * the original src URL is returned.
 *
 * TODO: could probably benefit from a failed-URL cache, success should be already effectively cached by the browser.
 *
 * @param src url of full-size image
 * @param maxWidth
 * @param maxHeight
 */
export async function thumbnailUrlWithFallback(
    src: string,
    maxWidth = 1920,
    maxHeight = 1080
): Promise<string> {
    const thumbUrl = thumbnailUrl(src, maxWidth, maxHeight);

    if (thumbUrl === src) {
        // thumbnailUrl() might return unaltered URL for many reasons - pass that on as-is.
        return Promise.resolve(src);
    }

    return new Promise<string>(resolve => {
        const img = new Image();

        img.onload = () => {
            resolve(thumbUrl);
        };
        img.onerror = () => {
            console.warn('failed preflight on resized image, will return src', { thumbUrl, src });
            resolve(src);
        };

        img.src = thumbUrl;
    });
}

// If window.self.origin === "null" we won't be able to access HTML5 storage like sessionStorage.
function nullOrigin(): boolean {
    return window.self.origin === 'null';
}

export function checkWebpAlphaSupport() {
    check_webp_feature('alpha', (feature, supported) => {
        window[`spr_${FEATURE_WEBP_ALPHA}`] = supported;

        if (!nullOrigin()) {
            sessionStorage.setItem(FEATURE_WEBP_ALPHA, supported ? 'true' : 'false');
        }
    });
}

// Adapted from https://developers.google.com/speed/webp/faq this is the way to do webp feature
// detection. Note this includes an implicit check for data url support as well. Shouldn't be an
// issue but bears mentioning.
// check_webp_feature:
//   'feature' can be one of 'lossy', 'lossless', 'alpha' or 'animation'.
//   'callback(feature, result)' will be passed back the detection result (in an asynchronous way!)
function check_webp_feature(feature, callback) {
    const kTestImages = {
        lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
        lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
        alpha:
            'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
        animation:
            'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
    };
    const img = new Image();
    img.onload = () => {
        const result = img.width > 0 && img.height > 0;
        callback(feature, result);
    };
    img.onerror = () => {
        callback(feature, false);
    };
    img.src = 'data:image/webp;base64,' + kTestImages[feature];
}
