import { observable } from 'mobx';
import { FontCase, FontStyle, FontWeight } from 'models/Theme/Theme';

export class WidgetFontImpl {
    @observable samId?: string; // id from Sprinklr Core SAM
    @observable name?: string;
    @observable description?: string;
    @observable family?: string;
    @observable subFamily?: string;
    @observable weight?: FontWeight;
    @observable style?: FontStyle;
    @observable url?: string;
    @observable textTransform?: FontCase;

    static readonly graphQLFields: string = `
                samId
                name
                description
                family
                subFamily
                weight
                style
                url
    `;
}
