import {
    DigitalAsset, FontFamilyAsset,
    PostAsset,
    ShareConfig,
    ShareLevel,
    SocialMediaAsset,
} from './SocialMediaAsset';
import { observable } from 'mobx';

export class DigitalAssetImpl implements DigitalAsset {
    @observable postAccountId?: number;
    @observable mediaUrl: string;
    @observable originalMediaUrl?: string;
    @observable mediaMimeType?: string;
    @observable previewUrl?: string;
    @observable thumbnailUrl?: string;

    @observable thumbnailList?: Array<string> = []; // URLs; on type VIDEO, not in docs
    @observable firstFrame?: string; // URL, on type VIDEO
    @observable duration?: number; // on type VIDEO

    @observable imageHeightUnit?: string;
    @observable imageWidthUnit?: string;
    @observable previewImageHeightUnit?: string;
    @observable previewImageWidthUnit?: string;
    @observable imageHeight?: number;
    @observable imageWidth?: number;
    @observable aspectRatio?: number;
    @observable sizeInBytes?: number;
    @observable previewImageHeight?: number;
    @observable previewImageWidth?: number;
    @observable thumbnailHeight?: number;
    @observable thumbnailWidth?: number;

    static readonly graphQLFields: string = `
        postAccountId
        mediaMimeType
        mediaUrl
        originalMediaUrl
        thumbnailUrl
        previewUrl
        thumbnailList
        firstFrame
        duration
        aspectRatio
        imageHeightUnit
        imageWidthUnit
        previewImageHeightUnit
        previewImageWidthUnit
        imageHeight
        imageWidth
        aspectRatio
        sizeInBytes
        previewImageHeight
        previewImageWidth
        thumbnailHeight
        thumbnailWidth
    `;
}

export class PostAssetImpl implements PostAsset {}

export class ShareConfigImpl implements ShareConfig {
    @observable shareLevel?: ShareLevel;
    @observable sharedWithIds?: number[];

    static readonly graphQLFields: string = `
        shareLevel
        sharedWithIds
    `;
}

export class FontFamilyAssetImpl implements FontFamilyAsset {
    @observable fontList?: any[];

    static readonly graphQLFields: string = `
        fontList {
            id
            fontStyle
            fontWeight
            sourceType
            source {
                name
                url
            }
        }
    `;
}

export class SocialMediaAssetImpl implements SocialMediaAsset {
    @observable id: string;
    @observable name: string;
    @observable description: string;
    @observable assetStatus: string;
    @observable assetType: string;
    @observable expiryTime: number;
    @observable availableAfterTime: number;
    @observable ownerClientId: number;
    @observable ownerUserId: number;
    @observable assetSource: string;
    @observable autoImported: boolean;
    @observable restricted: boolean;
    @observable deleted: boolean;
    @observable createdTime: number;
    @observable updatedTime: number;
    @observable locked: boolean;
    @observable digitalAsset?: DigitalAsset;
    @observable postAsset?: PostAsset;

    static readonly graphQLFields: string = `
        id
        name
        description
        assetStatus
        assetType
        expiryTime
        availableAfterTime
        ownerClientId
        ownerUserId
        assetSource
        autoImported
        restricted
        deleted
        createdTime
        updatedTime
        locked
        digitalAsset { ${DigitalAssetImpl.graphQLFields} }
        secureDigitalAsset { ${DigitalAssetImpl.graphQLFields} }
        shareConfigs { ${ShareConfigImpl.graphQLFields} }
        fontFamilyAsset { ${FontFamilyAssetImpl.graphQLFields} }
    `;
}
