/**
 * Given a {@param dimension} and a collection of {@param points}, find the mean placement of the points within
 * the dimension, expressed as a decimal proportion of the dimension.
 *
 * Stated differently, average position of points Pn on a line proportional to a fixed point D on the line.
 *
 * e.g. 0---pAp---D averagePlacement(D=10, p=[4,6]) => A=5/10 = 0.5
 *
 * If a falsey dimension or points collection is provided, or points is empty, undefined is returned.
 *
 */
export function meanPlacement(dimension: number, points: number[]) {
    if (!dimension || !points || points.length < 1) {
        return undefined;
    }

    return points.reduce((p, c) => p + c) / points.length / dimension;
}
