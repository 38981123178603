import { DisplayType } from '../Location/Location';
import { User } from '../User/User';
import { observable } from 'mobx';

export interface Partner {
    id?: number;
    displayCounts?: DisplayCount[];
    users?: User[];
}

export class PartnerImpl implements Partner {
    @observable id: number;
    @observable displayCounts: DisplayCount[] = [];
}

export interface DisplayCount {
    type?: DisplayType;
    count?: number;
}

export interface PartnerDisplay {
    type: DisplayType;
    limit: number;
    available: number;
}
