// these 2 scripts need to be first loaded since
// they contain the polyfills for older browsers
import 'core-js/stable';
import 'core-js/features/url';
import 'core-js/features/url-search-params';
import 'regenerator-runtime/runtime';
import 'mobx-react/batchingForReactDom';

// vendor
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import _merge from 'lodash/merge';
import { Provider } from 'mobx-react';

// components
import App from './containers/App/App';

// types
import config, { ApplicationMode } from './config';

// services
// import serviceContext from './serviceContext';
import { ThemeProvider, ColorScheme, ColorMode } from 'components/_UI/designSystem';
import SpacewebProvider from '@sprinklr/spaceweb/spacewebProvider';

// spacekit
import { IconContext, colorSchemes } from 'components/spaceKit';
import light from '@sprinklr/spaceweb-themes/space/light';
import dark from '@sprinklr/spaceweb-themes/space/dark';
import icons from './components/_UI/designSystem/icons';
import { customTheme } from './theme/theme';

// utils
import GlobalStyle from './utils/Storybook/GlobalStyle';
import { axiosInstance } from './serviceContext/axios';
import { authService } from './serviceContext/authService';
import './utils/localStorage';
import './i18n';

// constants / assets
import versionConfig from './version.json';
import './assets/apple-touch-icon.png';
import './assets/favicon-32x32.png';
import './assets/favicon-16x16.png';
import './scss/main.scss';
import DisplayPubSub from 'utils/displayPubSub/DisplayPubSub';

const HOSTNAME_EXCLUSIONS = ['localhost', 'local.sprinklr.com', 'display-prod3.sprinklr.com'];
const ENV_EXCLUSIONS = ['presentations-qa4', 'gallery-qa4', 'qa4'];
const mode: ApplicationMode = config.applicationMode;
const shouldLogException =
    config.developmentMode === false &&
    !HOSTNAME_EXCLUSIONS.includes(window?.location?.hostname) &&
    !ENV_EXCLUSIONS.includes(process.env.TARGET_ENV) &&
    !window?.location?.pathname?.includes('/render/panel');

if (shouldLogException) {
    Sentry.init({
        dsn: 'https://78c7a2ebf9fd4cc78f7b196beaa712b7@o590478.ingest.sentry.io/5740128',
        release: `${mode}@${versionConfig?.version}`,
        environment: process.env.TARGET_ENV,
        integrations: [new Integrations.BrowserTracing()],
        ignoreErrors: [
            // from https://stackoverflow.com/questions/63020810/what-is-best-way-in-javascript-to-stop-throwing-resizeobserver-loop-limit-excee
            'ResizeObserver loop limit exceeded',

            // MetaInfoService - explicitly thrown error
            'No reports found for undefined', // https://sentry.io/organizations/sprinklr-4d/issues/3717562421/?project=5740128&query=is%3Aunresolved+No+reports+found+for+undefined&referrer=issue-stream&statsPeriod=14d

            // @dnd-kit
            'Object.defineProperties called on non-object', // https://sentry.io/organizations/sprinklr-4d/issues/3680806293/?project=5740128&query=is%3Aunresolved&referrer=issue-stream&sort=user&statsPeriod=14d

            // video.js
            "Cannot read property 'pause' of undefined", // https://sentry.io/organizations/sprinklr-4d/issues/3677105865/?project=5740128&query=is%3Aunresolved+Cannot+read+property+%27pause%27+of+undefined&referrer=issue-stream&sort=user&statsPeriod=14d

            // axios
            'Request failed with status code 500', // https://sentry.io/organizations/sprinklr-4d/issues/2967639056/?project=5740128&query=is%3Aunresolved+Request+failed+with+status+code+500&referrer=issue-stream&statsPeriod=14d
            'Network Error', // https://sentry.io/organizations/sprinklr-4d/issues/3672510986/?project=5740128&query=is%3Aunresolved+Network+Error&referrer=issue-stream&statsPeriod=14d
            'timeout exceeded', // https://sentry.io/organizations/sprinklr-4d/issues/3052141029/?project=5740128&query=is%3Aunresolved+timeout+exceeded&referrer=issue-stream&sort=user&statsPeriod=14d

            // Discussion here about these errors https://prod-gitlab.sprinklr.com/display/spr-display-builder/-/merge_requests/1991#note_3054660
            // Perhaps we DO want to look at the root cause of these at some point, for now we determined it was okay to ignore them
            // Samsung SMART-TV - cause unknown
            'Non-Error promise rejection captured with value: undefined', // https://sentry.io/organizations/sprinklr-4d/issues/2423876577/?project=5740128&query=is%3Aunresolved&referrer=issue-stream&sort=user&statsPeriod=14d

            // Similar to this: https://stackoverflow.com/questions/71837472/non-error-promise-rejection-captured-with-value-undefined
            'Non-Error promise rejection captured with keys: [object has no keys]', // https://sentry.io/organizations/sprinklr-4d/issues/2423471574/?project=5740128&query=is%3Aunresolved&referrer=issue-stream&sort=user&statsPeriod=14d

            // Access Denied - Auth failure?
            'Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type', // https://sentry.io/organizations/sprinklr-4d/issues/2381173183/?project=5740128&query=is%3Aunresolved&referrer=issue-stream&sort=user&statsPeriod=14d
        ],
        // beforeSend(event) {
        //     // We can also use this as an filtering mechanism if we conditionally return null
        //     // this prevents the event from being sent to sentry
        //     // https://docs.sentry.io/platforms/javascript/configuration/filtering/#event-hints
        //     return event;
        // },
        tracesSampleRate: 0.4, // turned this down one tick - revisit soon if our transaction rate stabilizes
    });

    // Add locale tag
    const userLocale = navigator?.languages?.length ? navigator.languages[0] : navigator?.language;
    if (userLocale) {
        Sentry.setTag('locale', userLocale);
    }

    // Add "time.zone" tag
    const tz = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone;
    if (tz) {
        Sentry.setTag('time.zone', tz);
    }

    // add "application.mode" tag
    Sentry.setTag('application.mode', mode);
}

const colorScheme: ColorMode =
    (!config.sandboxed && (localStorage.getItem('colorScheme') as ColorMode)) || 'dark';
const colorMode: ColorMode = mode === 'EMBED' || mode === 'PRESENTATIONS' ? 'light' : colorScheme;
const activeTheme = colorSchemes[colorMode];

const mergedTheme: ColorScheme = _merge({}, activeTheme, customTheme[colorMode]);
const spaceWebTheme = { dark, light };

// add display pub sub to window for iframe communication
(window as any).sprDisplay = new DisplayPubSub();

const render = () => {
    ReactDOM.render(
        <Provider
            authService={authService}
            axios={axiosInstance}
            config={config} /* {...serviceContext} */
        >
            <SpacewebProvider direction='ltr' theme={spaceWebTheme[colorMode]}>
                <ThemeProvider theme={mergedTheme}>
                    <IconContext.Provider value={icons}>
                        <Router>
                            <>
                                <GlobalStyle theme={mergedTheme} />
                                <App />
                            </>
                        </Router>
                    </IconContext.Provider>
                </ThemeProvider>
            </SpacewebProvider>
        </Provider>,
        document.getElementById('root')
    );
};

if (
    'fetch' in window &&
    'Intl' in window &&
    'URL' in window &&
    'Map' in window &&
    'forEach' in NodeList.prototype &&
    'startsWith' in String.prototype &&
    'endsWith' in String.prototype &&
    'includes' in String.prototype &&
    'includes' in Array.prototype &&
    'assign' in Object &&
    'entries' in Object &&
    'keys' in Object
) {
    render();
} else {
    import('./polyfills').then(render);
}
