import { observable } from 'mobx';

export type PublishStatus = 'SUCCESS' | 'FAILURE';

export interface PanelPublishResult {
    panelId: string;
    status?: PublishStatus;
    errorMessage?: string;
    duration?: number;
}

export class PanelPublishResultImpl implements PanelPublishResult {
    @observable panelId: string;
    @observable status?: PublishStatus;
    @observable errorMessage?: string;
    @observable duration?: number;
}

export const PanelPublishResultFields = `
  panelId
  status
  errorMessage
  duration
`;
